import { InjectionToken } from '@angular/core';

export interface RouteConfig {
    profileTypes: string;
    profiles: string;
    connectors: string;
    plinthBlocks: string;
    customers: string;
    products: string;
    orders: string;
    wizard: string;
    cylinderWizard: string;
    orderSummary: string;
    users: string;
    rabbets: string;
    rabbetDetails: string;
    drillPatterns: string;
    assemblyOverview: string;
    cylinderAssemblyOverview: string;
    approveAssemblyOverview: string;
    fetchMaterialOverview: string;
    fetchMaterialOrderHeaderOverview: string;
    sectionTypes: string;
    articles: string;
    stockPlinthBlocks: string;
    afasList: string;
    xmlCoupling: string;
    trymaOverview: string;
}

export const getRouteConfig = (): RouteConfig =>
    ({
        profileTypes: '/profiletypes',
        profiles: '/profiles',
        connectors: '/connectors',
        plinthBlocks: '/plinthblocks',
        customers: '/customers',
        products: '/products',
        orders: '/orders',
        wizard: '/wizard',
        cylinderWizard: '/cylinder-wizard',
        orderSummary: '/summary',
        users: '/users',
        rabbets: '/rabbets',
        rabbetDetails: '/rabbetdetails',
        drillPatterns: '/drillpatterns',
        assemblyOverview: 'orderoperations/assemblyoverview',
        cylinderAssemblyOverview: 'orderoperations/cylinderassemblyoverview',
        approveAssemblyOverview: 'orderoperations/approveassemblyoverview',
        sectionTypes: '/sectiontypes',
        articles: '/articles',
        stockPlinthBlocks: '/stockplinthblocks',
        afasList: '/afas/list',
        fetchMaterialOverview: '/orderoperations/fetchmaterial',
        fetchMaterialOrderHeaderOverview: '/orderheaderoperations/fetchmaterial',
        xmlCoupling: '/xmlcouplings',
        trymaOverview: '/trymaOverview'
    });

export const ROUTE_CONFIG = new InjectionToken<RouteConfig>('route.config');
