import { Action } from '@ngrx/store';
import { Error } from '@app/core/models/Error';
import { PlinthBlock } from '@app/plinth-blocks/models/plinthBlock';
import { ProductWizardOutputData } from '@app/product-wizard/models/product-wizard-output-data';
import { CustomerProduct } from '@app/customers/models/customer-product';

export enum SelectedProductActionTypes {
  LoadCustomerProduct = '[Product Wizard] Load Customer Product',
  LoadCustomerProductFailure = '[Product Wizard] Load Customer Product Failure',
  LoadCustomerProductSuccess = '[Product Wizard] Load Customer Product Success',  
  LoadStartPlinthBlock = '[Product Wizard] Load Start PlinthBlock',
  LoadStartPlinthBlockFailure = '[Product Wizard] Load Start PlinthBlock Failure',
  LoadStartPlinthBlockSuccess = '[Product Wizard] Load Start PlinthBlock Success',
  LoadEndPlinthBlock = '[Product Wizard] Load End PlinthBlock',
  LoadEndPlinthBlockFailure = '[Product Wizard] Load End PlinthBlock Failure',
  LoadEndPlinthBlockSuccess = '[Product Wizard] Load End PlinthBlock Success',  
}

export class LoadCustomerProduct implements Action {
  readonly type = SelectedProductActionTypes.LoadCustomerProduct;
  constructor(public payload: string) {}
}

export class LoadCustomerProductFailure implements Action {
  readonly type = SelectedProductActionTypes.LoadCustomerProductFailure;
  constructor(public payload: Error[]) {}
}

export class LoadCustomerProductSuccess implements Action {
  readonly type = SelectedProductActionTypes.LoadCustomerProductSuccess;

  constructor(public payload: CustomerProduct) {}
}

export class LoadStartPlinthBlock implements Action {
  readonly type = SelectedProductActionTypes.LoadStartPlinthBlock;
  constructor(public payload: string) {}
}

export class LoadStartPlinthBlockSuccess implements Action {
  readonly type = SelectedProductActionTypes.LoadStartPlinthBlockSuccess;

  constructor(public payload: PlinthBlock) {}
}

export class LoadStartPlinthBlockFailure implements Action {
  readonly type = SelectedProductActionTypes.LoadStartPlinthBlockFailure;

  constructor(public payload: Error[]) {}
}

export class LoadEndPlinthBlock implements Action {
  readonly type = SelectedProductActionTypes.LoadEndPlinthBlock;
  constructor(public payload: string) {}
}

export class LoadEndPlinthBlockSuccess implements Action {
  readonly type = SelectedProductActionTypes.LoadEndPlinthBlockSuccess;

  constructor(public payload: PlinthBlock) {}
}

export class LoadEndPlinthBlockFailure implements Action {
  readonly type = SelectedProductActionTypes.LoadEndPlinthBlockFailure;

  constructor(public payload: Error[]) {}
}

export type SelectedProductActionsUnion =
  | LoadCustomerProduct
  | LoadCustomerProductFailure
  | LoadCustomerProductSuccess  
  | LoadStartPlinthBlock
  | LoadStartPlinthBlockSuccess
  | LoadStartPlinthBlockFailure
  | LoadEndPlinthBlock
  | LoadEndPlinthBlockSuccess
  | LoadEndPlinthBlockFailure ;
