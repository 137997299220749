import { Action } from '@ngrx/store';
import { SnackBar } from '@app/core/models/SnackBar';

export enum LayoutActionTypes {
  OpenErrorSnackBar = '[Layout] Open Error SnackBar',
  CloseErrorSnackBar = '[Layout] Close Error SnackBar',
  OpenSuccessSnackBar = '[Layout] Open Success SnackBar',
  CloseSuccessSnackBar = '[Layout] Close Success SnackBar',
  OpenInfoSnackBar = '[Layout] Open Info SnackBar',
  CloseInfoSnackBar = '[Layout] Close Info SnackBar',
  DownloadHelp = '[Layout] Download Help',
  StickerPrintFailed = '[Layout] Sticker Print Failed',
  StickerPrintFailedConfirmationDismiss = '[Layout] Sticker Print Failed Confirmation Dismiss',
}

export class OpenErrorSnackBar implements Action {
  readonly type = LayoutActionTypes.OpenErrorSnackBar;

  constructor(public payload: SnackBar) {}
}

export class CloseErrorSnackBar implements Action {
  readonly type = LayoutActionTypes.CloseErrorSnackBar;
}

export class OpenSuccessSnackBar implements Action {
  readonly type = LayoutActionTypes.OpenSuccessSnackBar;

  constructor(public payload: SnackBar) {}
}

export class CloseSuccessSnackBar implements Action {
  readonly type = LayoutActionTypes.CloseSuccessSnackBar;
}

export class OpenInfoSnackBar implements Action {
  readonly type = LayoutActionTypes.OpenInfoSnackBar;

  constructor(public payload: SnackBar) {}
}

export class CloseInfoSnackBar implements Action {
  readonly type = LayoutActionTypes.CloseInfoSnackBar;
}

export class DownloadHelp implements Action {
  readonly type = LayoutActionTypes.DownloadHelp;
}

export class StickerPrintFailed implements Action {
  readonly type = LayoutActionTypes.StickerPrintFailed;

  constructor(public payload: string[]) {}
}

export class StickerPrintFailedConfirmationDismiss implements Action {
  readonly type = LayoutActionTypes.StickerPrintFailedConfirmationDismiss;
}

export type LayoutActionsUnion =
OpenErrorSnackBar
| CloseErrorSnackBar
| OpenSuccessSnackBar
| CloseSuccessSnackBar
| OpenInfoSnackBar
| CloseInfoSnackBar
| DownloadHelp
| StickerPrintFailed
| StickerPrintFailedConfirmationDismiss;
