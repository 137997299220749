/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/login-form.component.ngfactory";
import * as i2 from "../components/login-form.component";
import * as i3 from "@angular/common";
import * as i4 from "./styled-authorization-page.component.ngfactory";
import * as i5 from "./styled-authorization-page.component";
import * as i6 from "./login-page.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
var styles_LoginPageComponent = [];
var RenderType_LoginPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
function View_LoginPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "vt-login-form", [], null, [[null, "submitted"], [null, "downloadHelp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("downloadHelp" === en)) {
        var pd_1 = (_co.onDownloadHelp() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LoginFormComponent_0, i1.RenderType_LoginFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginFormComponent, [], { pending: [0, "pending"], errorMessage: [1, "errorMessage"] }, { submitted: "submitted", downloadHelp: "downloadHelp" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.pending$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.error$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LoginPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i4.View_StyledAuthorizationPageComponent_0, i4.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i5.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["loginForm", 2]], null, 0, null, View_LoginPageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i0.ɵdid(1, 180224, null, 0, i6.LoginPageComponent, [i7.Store, i8.ActivatedRoute], null, null)], null, null); }
var LoginPageComponentNgFactory = i0.ɵccf("vt-login-page", i6.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
