/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/information.component.ngfactory";
import * as i2 from "../components/information.component";
import * as i3 from "./styled-authorization-page.component.ngfactory";
import * as i4 from "./styled-authorization-page.component";
import * as i5 from "./information-page.component";
var styles_InformationPageComponent = [];
var RenderType_InformationPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InformationPageComponent, data: {} });
export { RenderType_InformationPageComponent as RenderType_InformationPageComponent };
function View_InformationPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-information", [], null, null, null, i1.View_InformationComponent_0, i1.RenderType_InformationComponent)), i0.ɵdid(1, 114688, null, 0, i2.InformationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_InformationPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i3.View_StyledAuthorizationPageComponent_0, i3.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i4.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["information", 2]], null, 0, null, View_InformationPageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InformationPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-information-page", [], null, null, null, View_InformationPageComponent_0, RenderType_InformationPageComponent)), i0.ɵdid(1, 114688, null, 0, i5.InformationPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationPageComponentNgFactory = i0.ɵccf("vt-information-page", i5.InformationPageComponent, View_InformationPageComponent_Host_0, {}, {}, []);
export { InformationPageComponentNgFactory as InformationPageComponentNgFactory };
