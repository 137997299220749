import { Component } from "@angular/core";

@Component({
  selector: "vt-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  version: string;

  constructor() {
    this.version = this.getVersionNumber();
  }

  getVersionNumber(): string {
    return localStorage.getItem("VERSION_NUMBER") || "";
  }
}
