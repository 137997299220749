var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Store } from '@ngrx/store';
import { AppConfigService } from '@app/core/services/app-config-service';
import { LayoutActions } from '@app/core/actions';
var PrintService = /** @class */ (function () {
    /**
     *Creates an instance of PrintService.
     * @param {Store<fromOrderOperation.State>} store
     * @memberof PrintService
     */
    function PrintService(store) {
        this.store = store;
        /**
         * Abort flag if printing fails
         *
         * @type {boolean}
         * @memberof PrintService
         */
        this.abort = false;
    }
    /**
     * Print sticker to zebra printer
     *
     * @param {string[]} stickers
     * @memberof PrintService
     */
    PrintService.prototype.printStickersOnDefaultDevice = function (stickers) {
        return __awaiter(this, void 0, void 0, function () {
            var _loop_1, this_1, _i, stickers_1, stickerString;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.abort = false;
                        _loop_1 = function (stickerString) {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this_1.printDelay(Number.parseInt(AppConfigService.config.App.PrintDelay)).then(function () { return __awaiter(_this, void 0, void 0, function () {
                                            var _this = this;
                                            return __generator(this, function (_a) {
                                                if (!this.abort) {
                                                    BrowserPrint.getDefaultDevice('printer', function (device) {
                                                        try {
                                                            if (!_this.abort) {
                                                                device.send(stickerString, function (response) { }, function (error_response) {
                                                                    if (!_this.abort) {
                                                                        _this.store.dispatch(new LayoutActions.StickerPrintFailed(stickers));
                                                                        _this.abort = true;
                                                                    }
                                                                });
                                                            }
                                                        }
                                                        catch (_a) { }
                                                    }, function (error_response) {
                                                        if (!_this.abort) {
                                                            _this.store.dispatch(new LayoutActions.StickerPrintFailed(stickers));
                                                            _this.abort = true;
                                                        }
                                                    });
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, stickers_1 = stickers;
                        _a.label = 1;
                    case 1:
                        if (!(_i < stickers_1.length)) return [3 /*break*/, 4];
                        stickerString = stickers_1[_i];
                        return [5 /*yield**/, _loop_1(stickerString)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Delay printer, This way we keep the order printing outcome equals to the income order
     *
     * @param {number} ms
     * @memberof PrintService
     */
    PrintService.prototype.printDelay = function (ms) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, ms); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PrintService;
}());
export { PrintService };
