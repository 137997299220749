import { DomSanitizer } from '@angular/platform-browser';
var BASE64_PNG_TAG = 'data:image/png;base64,';
var BASE64_SVG_TAG = 'data:image/svg+xml;base64,';
var BASE64_XML_TAG = 'data:text/xml;base64,';
var Base64ImageDisplayComponent = /** @class */ (function () {
    function Base64ImageDisplayComponent(_sanitizer) {
        this._sanitizer = _sanitizer;
    }
    Object.defineProperty(Base64ImageDisplayComponent.prototype, "image", {
        get: function () {
            return this._sanitizer.bypassSecurityTrustResourceUrl(addBase64Tag(stripBase64Tag(this.imageSource)));
        },
        enumerable: true,
        configurable: true
    });
    return Base64ImageDisplayComponent;
}());
export { Base64ImageDisplayComponent };
export function stripBase64Tag(value) {
    if (value && value.startsWith(BASE64_SVG_TAG)) {
        return value.replace(BASE64_SVG_TAG, '');
    }
    return value ? value.replace(BASE64_PNG_TAG, '') : '';
}
export function checkBase64Tag(value) {
    return value.startsWith(BASE64_PNG_TAG) || value.startsWith(BASE64_SVG_TAG) || value.startsWith(BASE64_XML_TAG);
}
export function addBase64Tag(value) {
    if (value.startsWith('PHN2') || value.startsWith('PD94')) {
        return BASE64_SVG_TAG + value;
    }
    return BASE64_PNG_TAG + value;
}
