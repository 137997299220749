var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { MatDialog } from '@angular/material';
import { LayoutActions } from '@app/core/actions';
import { RePrintDialogComponent } from '@app/core/components';
import { getRouteConfig } from '@app/core/models/routeConfig';
import { OrderOperationApiActions } from '@app/order-operations/actions';
import { WizardPageActions } from '@app/product-wizard/actions';
import { UsersService } from '@app/users/services/users.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map, mergeMap } from 'rxjs/operators';
var LayoutEffects = /** @class */ (function () {
    function LayoutEffects(actions$, usersService, dialog) {
        var _this = this;
        this.actions$ = actions$;
        this.usersService = usersService;
        this.dialog = dialog;
        /**
         * Get and display manual pdf
         */
        this.downloadHelp$ = this.actions$.pipe(ofType(LayoutActions.LayoutActionTypes.DownloadHelp), mergeMap(function () {
            return _this.usersService.help().pipe(map(function (response) {
                try {
                    window.navigator.msSaveOrOpenBlob(response, "Handleiding bestelsysteem.pdf");
                }
                catch (_a) {
                    var fileURL = URL.createObjectURL(response);
                    window.open(fileURL, '_blank');
                }
            }));
        }));
        /**
         * Show rety print message dialog
         */
        this.retryPrint$ = this.actions$.pipe(ofType(LayoutActions.LayoutActionTypes.StickerPrintFailed), map(function (action) { return action.payload; }), exhaustMap(function (printList) {
            var dialogRef = _this.dialog.open(RePrintDialogComponent, {
                data: {
                    printResult: printList
                },
            });
            return dialogRef.afterClosed();
        }), map(function (result) { return result
            ? new OrderOperationApiActions.FinalizeSuccess(result)
            : new LayoutActions.StickerPrintFailedConfirmationDismiss(); }));
        /**
         * Send out actions based on navigation events
         */
        this.ObserveNavigationEvents$ = this.actions$.pipe(ofType(ROUTER_NAVIGATION), map(function (action) { return action.payload; }), filter(function (x) { return x.event.url.includes(getRouteConfig().wizard); }), map(function (x) { return new WizardPageActions.NavigatedToWizard(); }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LayoutEffects.prototype, "downloadHelp$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LayoutEffects.prototype, "retryPrint$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LayoutEffects.prototype, "ObserveNavigationEvents$", void 0);
    return LayoutEffects;
}());
export { LayoutEffects };
