/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styled-authorization-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./styled-authorization-page.component";
var styles_StyledAuthorizationPageComponent = [i0.styles];
var RenderType_StyledAuthorizationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StyledAuthorizationPageComponent, data: {} });
export { RenderType_StyledAuthorizationPageComponent as RenderType_StyledAuthorizationPageComponent };
export function View_StyledAuthorizationPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "styling-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "section", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "top-colored"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "section", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "middle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(7, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "middle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "middle-right-colored"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "section", [["class", "bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "bottom-colored"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_StyledAuthorizationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, View_StyledAuthorizationPageComponent_0, RenderType_StyledAuthorizationPageComponent)), i1.ɵdid(1, 114688, null, 0, i3.StyledAuthorizationPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StyledAuthorizationPageComponentNgFactory = i1.ɵccf("vt-styled-authorization-page", i3.StyledAuthorizationPageComponent, View_StyledAuthorizationPageComponent_Host_0, { content: "content" }, {}, []);
export { StyledAuthorizationPageComponentNgFactory as StyledAuthorizationPageComponentNgFactory };
