var _a, _b;
export var TrymaMachines;
(function (TrymaMachines) {
    TrymaMachines[TrymaMachines["NoTryma"] = null] = "NoTryma";
    TrymaMachines[TrymaMachines["TrymaOne"] = 1] = "TrymaOne";
    TrymaMachines[TrymaMachines["TrymaTwo"] = 2] = "TrymaTwo";
})(TrymaMachines || (TrymaMachines = {}));
export var trymaMachines = (_a = {},
    _a[TrymaMachines.NoTryma] = 'Gaat niet over Tryma',
    _a[TrymaMachines.TrymaOne] = 'Tryma machine 1',
    _a[TrymaMachines.TrymaTwo] = 'Tryma machine 2',
    _a);
export var trymaMachinesShort = (_b = {},
    _b[TrymaMachines.NoTryma] = 'Geen Tryma',
    _b[TrymaMachines.TrymaOne] = 'Machine 1',
    _b[TrymaMachines.TrymaTwo] = 'Machine 2',
    _b);
export var trymaMachineOptions = [
    { key: TrymaMachines.NoTryma, value: trymaMachines[TrymaMachines.NoTryma] },
    { key: TrymaMachines.TrymaOne, value: trymaMachines[TrymaMachines.TrymaOne] },
    { key: TrymaMachines.TrymaTwo, value: trymaMachines[TrymaMachines.TrymaTwo] }
];
