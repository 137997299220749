<mat-card class="vt-tab-card full-width">
  <form mat-card-content [formGroup]="form">
    <vt-error [errors]="errors"></vt-error>
    <div class="detail-row">
      <div class="detail-col">
        <fieldset formGroupName="Customer">
          <legend class="legend">Bedrijfsgegevens</legend>
          <div class="form-group">
            <label class="vt-form-label">Bedrijfsnaam *</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Bedrijfsnaam *" formControlName="Name" />
              <mat-error *ngIf="form.get('Customer').get('Name').hasError('required')"> Bedrijfsnaam is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Adres * / Huisnr. *</label>
            <mat-form-field class="medium">
              <input type="text" matInput placeholder="Adres *" formControlName="Street" />
              <mat-error *ngIf="form.get('Customer').get('Street').hasError('required')"> Adres is verplicht </mat-error>              
            </mat-form-field>
            <mat-form-field class="small">
              <input type="number" matInput placeholder="Huisnr. *" min="1" formControlName="StreetNumber" />
              <mat-error *ngIf="form.get('Customer').get('StreetNumber').hasError('required')"> Huisnr. is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Toevoeging</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Toevoeging" formControlName="StreetNumberAddition" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Postcode *</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Postcode *" formControlName="PostalCode" />
              <mat-error *ngIf="form.get('Customer').get('PostalCode').hasError('required')"> Postcode is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Plaats *</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Plaats *" formControlName="City" />
              <mat-error *ngIf="form.get('Customer').get('City').hasError('required')"> Plaats is verplicht </mat-error>
            </mat-form-field>
          </div>
        </fieldset>
      </div>
      <div class="detail-col">
        <fieldset formGroupName="User">
          <legend class="legend">Uw gegevens</legend>
          <div class="form-group">
            <label class="vt-form-label">Voornaam *</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Voornaam *" formControlName="FirstName" />
              <mat-error *ngIf="form.get('User').get('FirstName').hasError('required')"> Voornaam is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Tussenv. / Achternaam *</label>
            <mat-form-field class="small">
              <input type="text" matInput placeholder="Tussenv." formControlName="LastNamePrefix" />
            </mat-form-field>
            <mat-form-field class="medium">
              <input type="text" matInput placeholder="Achternaam *" formControlName="LastName" />
              <mat-error *ngIf="form.get('User').get('LastName').hasError('required')"> Achternaam is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Telefoonnummer</label>
            <mat-form-field>
              <input type="text" matInput placeholder="Telefoonnummer" formControlName="PhoneNumber" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">E-mailadres *</label>
            <mat-form-field>
              <input type="email" matInput placeholder="E-mailadres *" formControlName="Email" />
              <mat-error *ngIf="form.get('User').get('Email').hasError('required')"> E-mailadres is verplicht </mat-error>
              <mat-error *ngIf="form.get('User').get('Email').hasError('email')"> Ongeldig e-mailadres </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Herhaal e-mailadres *</label>
            <mat-form-field>
              <input type="email" matInput placeholder="Herhaal e-mailadres *" formControlName="EmailRepeat" />
              <mat-error *ngIf="form.get('User').get('EmailRepeat').hasError('required')"> Herhaal e-mailadres is verplicht </mat-error>
              <mat-error *ngIf="form.get('User').get('EmailRepeat').hasError('email')"> Ongeldig e-mailadres </mat-error>
              <mat-error *ngIf="form.get('User').get('EmailRepeat').hasError('mismatch')"> e-mailadressen komen niet overeen </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Wachtwoord *</label>
            <mat-form-field>
              <input type="password" matInput placeholder="Wachtwoord *" formControlName="Password" />
              <mat-error *ngIf="form.get('User').get('Password').hasError('required')"> Wachtwoord is verplicht </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label class="vt-form-label">Herhaal wachtwoord *</label>
            <mat-form-field>
              <input type="password" matInput placeholder="Herhaal wachtwoord *" formControlName="PasswordRepeat" />
              <mat-error *ngIf="form.get('User').get('PasswordRepeat').hasError('required')"> Herhaal wachtwoord is verplicht </mat-error>
              <mat-error *ngIf="form.get('User').get('PasswordRepeat').hasError('mismatch')"> Wachtwoorden komen niet overeen </mat-error>
            </mat-form-field>
          </div>
        </fieldset>
      </div>
    </div>
    <mat-card-actions class="footer-actions">
      <button mat-flat-button color="primary" [routerLink]="['/login']">Annuleren</button>
      <button mat-flat-button color="accent" (click)="submit()" mat-button>Doorgaan</button>
    </mat-card-actions>
  </form>
</mat-card>
