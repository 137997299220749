/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/reset-password.component.ngfactory";
import * as i2 from "../components/reset-password.component";
import * as i3 from "@angular/router";
import * as i4 from "./styled-authorization-page.component.ngfactory";
import * as i5 from "./styled-authorization-page.component";
import * as i6 from "./reset-password-page.component";
import * as i7 from "@ngrx/store";
var styles_ResetPasswordPageComponent = [];
var RenderType_ResetPasswordPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResetPasswordPageComponent, data: {} });
export { RenderType_ResetPasswordPageComponent as RenderType_ResetPasswordPageComponent };
function View_ResetPasswordPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-reset-password-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ResetPasswordFormComponent_0, i1.RenderType_ResetPasswordFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResetPasswordFormComponent, [i3.ActivatedRoute], null, { submitted: "submitted" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ResetPasswordPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i4.View_StyledAuthorizationPageComponent_0, i4.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i5.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["resetPasswordForm", 2]], null, 0, null, View_ResetPasswordPageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ResetPasswordPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-reset-password-page", [], null, null, null, View_ResetPasswordPageComponent_0, RenderType_ResetPasswordPageComponent)), i0.ɵdid(1, 49152, null, 0, i6.ResetPasswordPageComponent, [i7.Store], null, null)], null, null); }
var ResetPasswordPageComponentNgFactory = i0.ɵccf("vt-reset-password-page", i6.ResetPasswordPageComponent, View_ResetPasswordPageComponent_Host_0, {}, {}, []);
export { ResetPasswordPageComponentNgFactory as ResetPasswordPageComponentNgFactory };
