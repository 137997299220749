import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/auth/guards/auth-guard.service';
import { ClaimGuard } from '@app/auth/guards/claim-guard.service';
import { NotFoundPageComponent } from '@app/core/containers/not-found-page.component';

const plinthBlockClaims = ['PlinthBlock.Read'];
const connectorClaims = ['Connector.Read'];
const profileTypeClaims = ['ProfileType.Read'];
const profileClaims = ['Profile.Read'];
const sectionTypeClaims = ['SectionType.Read'];
const customerClaims = ['Customer.Read'];
const productClaims = ['Product.Read'];
const residualLengthClaims = ['ResidualLength.Read'];
const userProfileClaims = ['UserProfile.Read'];
const orderClaims = ['Order.Read'];
const planningClaims = ['Planning.Read'];
const rabbetClaims = ['Rabbet.Read'];
const rabbetDetailClaims = ['RabbetDetail.Read'];
const drillPatternClaims = ['DrillPattern.Read'];
const reportClaims = ['Order.Read'];
const productTypesClaims = ['ProductType.Read'];
const batchesClaims = ['Batch.Read'];
const orderCalculationsClaims = ['OrderCalculations.Read'];
const articlesClaims = ['Article.Read'];
const afasClaims = ['Afas.Read'];
const stockPlinthBlockClaims = ['StockPlinthBlock.Read'];
const xmlDetailClaims = ['XmlDetail.Read'];
const operationMachineClaims = ['OperationMachines.Read'];

export const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'userprofile',
        loadChildren: '@app/user-profiles/user-profiles.module#UserProfilesModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: userProfileClaims
        }
    },
    { path: 'auth', loadChildren: '@app/auth/auth.module#AuthenticationModule' },
    {
        path: 'users',
        loadChildren: '@app/users/users.module#UsersModule',
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'plinthblocks',
        loadChildren: '@app/plinth-blocks/plinth-blocks.module#PlinthBlocksModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: plinthBlockClaims
        }
    },
    {
        path: 'connectors',
        loadChildren: '@app/connectors/connectors.module#ConnectorsModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: connectorClaims
        }
    },
    {
        path: 'profiletypes',
        loadChildren: '@app/profile-types/profile-types.module#ProfileTypesModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: profileTypeClaims
        }
    },
    {
        path: 'sectiontypes',
        loadChildren: '@app/section-types/section-types.module#SectionTypesModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: sectionTypeClaims
        }
    },
    {
        path: 'reports',
        loadChildren: '@app/reports/reports.module#ReportsModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: reportClaims
        }
    },
    {
        path: 'profiles',
        loadChildren: '@app/profiles/profiles.module#ProfilesModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: profileClaims
        }
    },
    {
        path: 'customers',
        loadChildren: '@app/customers/customers.module#CustomersModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: customerClaims
        }
    },
    {
        path: 'products',
        loadChildren: '@app/products/products.module#ProductsModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: productClaims
        }
    },
    {
        path: 'residuallengths',
        loadChildren: '@app/residual-lengths/residual-lengths.module#ResidualLengthsModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: residualLengthClaims
        }
    },
    {
        path: 'wizard',
        loadChildren: '@app/product-wizard/product-wizard.module#ProductWizardModule',
    },
    {
        path: 'cylinder-wizard',
        loadChildren: '@app/cylinder-wizard/cylinder-wizard.module#CylinderWizardModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'orders',
        loadChildren: '@app/orders/orders.module#OrdersModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'planning',
        loadChildren: '@app/planning/planning.module#PlanningModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: planningClaims
        }
    },
    {
        path: 'orderoperations',
        loadChildren: '@app/order-operations/order-operations.module#OrderOperationsModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'orderheaderoperations',
        loadChildren: '@app/order-header-operations/order-header-operations.module#OrderHeaderOperationsModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'rabbets',
        loadChildren: '@app/rabbet/rabbets.module#RabbetsModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: rabbetClaims
        }
    },
    {
        path: 'rabbetdetails',
        loadChildren: '@app/rabbet-details/rabbet-details.module#RabbetDetailModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: rabbetDetailClaims
        }
    },
    {
        path: 'drillpatterns',
        loadChildren: '@app/drill-patterns/drill-patterns.module#DrillPatternsModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: drillPatternClaims
        }
    },
    {
        path: 'stockplinthblocks',
        loadChildren: '@app/stock-plinth-blocks/stock-plinth-block.module#StockPlinthBlocksModule',
        canActivate: [AuthenticationGuard],
        data: {
          requiredClaims: stockPlinthBlockClaims
        }
    },
    {
        path: 'producttypes',
        loadChildren: '@app/product-types/product-type.module#ProductTypeModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: productTypesClaims
        }
    },
    {
        path: 'batches',
        loadChildren: '@app/batches/batches.module#BatchesModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: batchesClaims
        }
    },
    {
        path: 'ordercalculations',
        loadChildren: '@app/order-calculations/order-calculations.module#OrderCalculationsModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderCalculationsClaims
        }
    },
    {
        path: 'articles',
        loadChildren: '@app/articles/articles.module#ArticlesModule',
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: articlesClaims
        }
    },
    {
        path: 'afas',
        loadChildren: '@app/afas/afas.module#AfasModule',
        canActivate: [AuthenticationGuard],
        data: {
          requiredClaims: afasClaims
        }
    },
    {
        path: 'xmlcouplings',
        loadChildren: '@app/xml-coupling/xml-coupling.module#XmlCouplingsModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: xmlDetailClaims
        }
    },
    {
        path: 'operationmachines',
        loadChildren: '@app/operation-machines/operation-machines.module#OperationMachinesModule',
        canActivate: [AuthenticationGuard],
        data: {
            requiredClaims: operationMachineClaims
        }
    },
    { path: '**', component: NotFoundPageComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
