import { Action } from '@ngrx/store';

export enum AuthenticationActionTypes {
  Logout = '[Authentication] Logout',
  LogoutConfirmation = '[Authentication] Logout Confirmation',
  LogoutConfirmationDismiss = '[Authentication] Logout Confirmation Dismiss',
}

export class Logout implements Action {
  readonly type = AuthenticationActionTypes.Logout;
}

export class LogoutConfirmation implements Action {
  readonly type = AuthenticationActionTypes.LogoutConfirmation;
}

export class LogoutConfirmationDismiss implements Action {
  readonly type = AuthenticationActionTypes.LogoutConfirmationDismiss;
}

export type AuthenticationActionsUnion =
  | Logout
  | LogoutConfirmation
  | LogoutConfirmationDismiss;
