import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UsersService = /** @class */ (function () {
    function UsersService(http) {
        this.http = http;
        this.endpoints = {
            get: 'user',
            overview: 'user/list',
            create: 'user/create',
            update: 'user/update',
            count: 'user/count',
            generateToken: 'user/generateToken',
            delete: 'user',
            help: 'help',
        };
    }
    /**
    * Retrieves an array of profiles using the provided filter.
    * @param {Filter} filter The filter.
    */
    UsersService.prototype.filter = function (filter) {
        return this.http.post(this.endpoints.get, filter).pipe(map(function (profiles) { return profiles || []; }));
    };
    /**
    * Retrieves an array of profiles using the provided filter.
    * @param {Filter} filter The filter.
    */
    UsersService.prototype.getOverview = function (filter) {
        return this.http.post(this.endpoints.overview, filter).pipe(map(function (profiles) { return profiles || []; }));
    };
    /**
     * Retrieves the total count of users using the provided filter.
     * @param {Filter} filter The filter.
     */
    UsersService.prototype.count = function (filter) {
        return this.http.post(this.endpoints.count, filter).pipe(map(function (count) { return count || 0; }));
    };
    /**
     * Retrieves the user using the specified identifier.
     * @param {string} id The identifier.
     */
    UsersService.prototype.getById = function (id) {
        return this.http.get(this.endpoints.get + "/" + id);
    };
    /**
     * Adds the provided user.
     * @param {User} user The user.
     */
    UsersService.prototype.add = function (user) {
        return this.http.post("" + this.endpoints.create, user);
    };
    /**
     * Updates the provided User.
     * @param {User} profile The Profile.
     */
    UsersService.prototype.update = function (user) {
        return this.http.post(this.endpoints.update, user);
    };
    /**
     * Deletes the provided user.
     * @param {string} id The identifier.
     */
    UsersService.prototype.delete = function (id) {
        return this.http.delete(this.endpoints.delete + "/" + id);
    };
    UsersService.prototype.generateToken = function (id) {
        return this.http.get(this.endpoints.generateToken + "/" + id, { responseType: "text" });
    };
    /**
     * Get Help file
     */
    UsersService.prototype.help = function () {
        return this.http.get(this.endpoints.get + "/" + this.endpoints.help, { responseType: "blob" });
    };
    UsersService.ngInjectableDef = i0.defineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.inject(i1.HttpClient)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
