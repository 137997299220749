<vt-header-actions mainTitle="Registreren">
  <p>Let op: Het registratie formulier is ter registratie van nieuwe klanten.</p>  
</vt-header-actions>

<mat-tab-group class="vt-tab-group" [selectedIndex]="1">
  <mat-tab>
    <ng-template mat-tab-label> <span>Registreren</span> </ng-template>
    <vt-register-user-form (submitted)="onSubmit($event)" [errors]="errors$ | async"></vt-register-user-form>
  </mat-tab>
</mat-tab-group>
