import { Action } from '@ngrx/store';
import { OrderHeader } from '@app/product-wizard/models/order-header';
import { OrderSection } from '@app/product-wizard/models/order-section';
import { OrderDetail } from '@app/product-wizard/models/order-detail';

export enum ProductOrderActionTypes {
  SubmitOrderHeader = '[Product Order] Submit Order Header',
  SubmitOrderSection = '[Product Order] Submit Order Section',
  SubmitOrderDetail = '[Product Order] Submit Order Detail',
  ProductWizardCompleted = '[Product Order] Product Wizard Completed',
}

export class SubmitOrderHeader implements Action {
  readonly type = ProductOrderActionTypes.SubmitOrderHeader;

  constructor(public payload: OrderHeader) {}
}

export class SubmitOrderSection implements Action {
  readonly type = ProductOrderActionTypes.SubmitOrderSection;

  constructor(public payload: OrderSection) {}
}

export class SubmitOrderDetail implements Action {
  readonly type = ProductOrderActionTypes.SubmitOrderDetail;

  constructor(public payload: OrderDetail) {}
}

export class ProductWizardCompleted implements Action {
  readonly type = ProductOrderActionTypes.ProductWizardCompleted;
}

export type ProductOrderActionsUnion =
  | SubmitOrderHeader
  | SubmitOrderSection
  | SubmitOrderDetail
  | ProductWizardCompleted;
