import { NgModule } from '@angular/core';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MatPaginatorIntlNL } from '@app/material/i18n/material-paginator.intl';

const modules = [
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSidenavModule,
  MatListModule,
  MatExpansionModule,
  MatIconModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatOptionModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatGridListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatTabsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlNL}]
})
export class MaterialModule {}
