import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { LoginResponse } from '@app/auth/models/loginResponse';
import { User } from '@app/users/models/user';
import { Credentials } from '@app/auth/models/credentials';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '@app/auth/services/token.service';
import { map, catchError } from 'rxjs/operators';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';
import { TwoFactorRequest } from '../models/twoFactorRequest';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly http: HttpClient, private readonly tokenService: TokenService) {}

  private readonly endpoints = {
    register: 'user/register',
    forgotPassword: 'account/forgotpassword',
    loginCheck: 'account/authenticate',
    twoFactorLogin: 'account/twofactor',
    getUser: 'user/getcurrentuser',
    resetPassword: 'account/resetpassword',
    sendTwoFactorActivationCode: 'account/activationCode',
    recoverTwoFactor: 'account/recover'
  };

  register(registerUser: RegisterUser) {
    return this.http.post<RegisterUser>(`${this.endpoints.register}`, registerUser);
  }

  forgotPasswordRequest(forgotPasswordRequest: ForgotPasswordRequest) {
    return this.http.post<ForgotPasswordRequest>(`${this.endpoints.forgotPassword}`, forgotPasswordRequest);
  }

  resetPasswordRequest(resetPasswordRequest: ResetPasswordRequest) {
    return this.http.post<boolean>(`${this.endpoints.resetPassword}`, resetPasswordRequest);
  }

  login(credentials: Credentials): Observable<LoginResponse> {
    //Always clear the old token before requesting a new one
    if (this.tokenService.hasToken()) {
      this.tokenService.removeToken();
    }

    return this.http.post(this.endpoints.loginCheck, credentials, { withCredentials: true }).pipe(
      map((response: any) => {
        if(!response.isTwoFactorEnabled && response.twoFactorQrCode == null) {
          this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims,
            response.features, response.isCustomer, response.versionNumber);
        }

        return response;
      }),
      catchError(() => {       
        return throwError('Ongeldig e-mailadres en/of wachtwoord.');
      })
    );
  }

  twoFactorLogin(twoFactorRequest: TwoFactorRequest): Observable<LoginResponse> {
    return this.http.post(this.endpoints.twoFactorLogin, twoFactorRequest, { withCredentials: true }).pipe(
      map((response: any) => {
        this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims,
          response.features, response.isCustomer, response.versionNumber);

        return response;
      }),
      catchError(() => {       
        return throwError('De authenticatie code is ongeldig.');
      })
    );
  }

  sendTwoFactorActivationCode(twoFactorRequest: TwoFactorRequest): Observable<any> {
    return this.http.post(this.endpoints.sendTwoFactorActivationCode, twoFactorRequest)
  }

  retrieveCurrentUser(): Observable<User> {
    return this.http.get<User>(this.endpoints.getUser).pipe(map(user => user));
  }

  recoverTwoFactor(code: string) : Observable<LoginResponse> {
    return this.http.post(this.endpoints.recoverTwoFactor, code, { withCredentials: true }).pipe(
      map((response: any) => {
        this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims,
          response.features, response.isCustomer, response.versionNumber);

        return response;
      }),
      catchError(() => {       
        return throwError('De herstel code is ongeldig.');
      }));
  }

  logout() {
    this.tokenService.removeToken();
    return of(true);
  }
}
