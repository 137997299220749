var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LayoutActions } from '@app/core/actions';
var initialState = {
    showErrorSnackBar: false,
    showSuccessSnackBar: false,
    showInfoSnackBar: false,
    errorSnackBar: { messages: [] },
    successSnackBar: { messages: [] },
    infoSnackBar: { messages: [] },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LayoutActions.LayoutActionTypes.OpenErrorSnackBar:
            return __assign({}, state, { showErrorSnackBar: true, errorSnackBar: __assign({}, action.payload, { config: { panelClass: 'error-snack-bar' } }) });
        case LayoutActions.LayoutActionTypes.CloseErrorSnackBar:
            return __assign({}, state, { showErrorSnackBar: false });
        case LayoutActions.LayoutActionTypes.OpenSuccessSnackBar:
            return __assign({}, state, { showSuccessSnackBar: true, successSnackBar: __assign({}, action.payload, { config: { panelClass: 'success-snack-bar' } }) });
        case LayoutActions.LayoutActionTypes.CloseSuccessSnackBar:
            return __assign({}, state, { showSuccessSnackBar: false });
        case LayoutActions.LayoutActionTypes.OpenInfoSnackBar:
            return __assign({}, state, { showInfoSnackBar: true, infoSnackBar: __assign({}, action.payload, { config: { panelClass: 'info-snack-bar' } }) });
        case LayoutActions.LayoutActionTypes.CloseInfoSnackBar:
            return __assign({}, state, { showInfoSnackBar: false });
        default:
            return state;
    }
}
export var getShowErrorSnackBar = function (state) { return state.showErrorSnackBar; };
export var getErrorSnackBar = function (state) { return state.errorSnackBar; };
export var getShowSuccessSnackBar = function (state) { return state.showSuccessSnackBar; };
export var getSuccessSnackBar = function (state) { return state.successSnackBar; };
export var getShowInfoSnackBar = function (state) { return state.showInfoSnackBar; };
export var getInfoSnackBar = function (state) { return state.infoSnackBar; };
