/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/two-factor-authentication/two-factor-form.component.ngfactory";
import * as i2 from "../../components/two-factor-authentication/two-factor-form.component";
import * as i3 from "@angular/common";
import * as i4 from "../styled-authorization-page.component.ngfactory";
import * as i5 from "../styled-authorization-page.component";
import * as i6 from "./two-factor-page.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
var styles_TwoFactorPageComponent = [];
var RenderType_TwoFactorPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TwoFactorPageComponent, data: {} });
export { RenderType_TwoFactorPageComponent as RenderType_TwoFactorPageComponent };
function View_TwoFactorPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "vt-two-factor-form", [], null, [[null, "submitted"], [null, "sendTwoFactorCode"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendTwoFactorCode" === en)) {
        var pd_1 = (_co.onSendTwoFactorCode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_TwoFactorFormComponent_0, i1.RenderType_TwoFactorFormComponent)), i0.ɵdid(1, 638976, null, 0, i2.TwoFactorFormComponent, [], { loginResponse: [0, "loginResponse"], errorMessage: [1, "errorMessage"] }, { submitted: "submitted", sendTwoFactorCode: "sendTwoFactorCode" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.loginResponse$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.error$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TwoFactorPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i4.View_StyledAuthorizationPageComponent_0, i4.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i5.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["twoFactorForm", 2]], null, 0, null, View_TwoFactorPageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TwoFactorPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-two-factor-page", [], null, null, null, View_TwoFactorPageComponent_0, RenderType_TwoFactorPageComponent)), i0.ɵdid(1, 245760, null, 0, i6.TwoFactorPageComponent, [i7.Store, i8.ActivatedRoute, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TwoFactorPageComponentNgFactory = i0.ɵccf("vt-two-factor-page", i6.TwoFactorPageComponent, View_TwoFactorPageComponent_Host_0, {}, {}, []);
export { TwoFactorPageComponentNgFactory as TwoFactorPageComponentNgFactory };
