import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { hasFeature } from '@app/core/functions/feature-flag.functions';

@Directive({ selector: '[hasNotFeature]' })
export class HasNotFeatureDirective {
  constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef) {}

  /**
   * Validate if feature flag is disabled
   * @returns {string} The feature.
   */
  @Input()
  set hasNotFeature(feature: string) {
    const condition = hasFeature(feature);    

    if (condition) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);     
    }
  }
}
