import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/reducers/auth.reducer';
import * as fromLayout from '@app/core/reducers/layout.reducer';
import * as fromLoginPage from '@app/auth/reducers/login-page.reducer';
import * as fromRegisterUserPage from '@app/auth/reducers/register-page.reducer';
import { AuthenticationApiActions } from '@app/auth/actions';

export interface AuthenticationState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  registerUserPage: fromRegisterUserPage.State;
}

export interface State extends fromRoot.State {
  authentication: AuthenticationState;
}

export const reducers: ActionReducerMap<AuthenticationState, AuthenticationApiActions.AuthenticationApiActionsUnion> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  registerUserPage: fromRegisterUserPage.reducer,
};

export const selectAuthenticationState = createFeatureSelector<State, AuthenticationState>('authentication');

export const selectAuthenticationStatusState = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.status
);
export const getUser = createSelector(
  selectAuthenticationStatusState,
  fromAuth.getUser
);
export const getLoggedIn = createSelector(
  getUser,
  user => !!user
);

export const selectLoginPageState = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.loginPage
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError
);

export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);

export const selectRegisterUserPageState = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.registerUserPage
);

export const getRegisterUserPageErrors = createSelector(
  selectRegisterUserPageState,
  fromRegisterUserPage.getErrors
);

export const selectLayoutState = createFeatureSelector<State, fromLayout.State>('layout');

export const getLoginResponse = createSelector(
  selectAuthenticationStatusState,
  fromAuth.getLoginResponse
);
export const getErrorSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getErrorSnackBar
);
export const getShowErrorSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getShowErrorSnackBar
);

export const getSuccessSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getSuccessSnackBar
);
export const getShowSuccessSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getShowSuccessSnackBar
);

export const getInfoSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getInfoSnackBar
)
export const getShowInfoSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getShowInfoSnackBar
)
