/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./re-print-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./re-print-dialog.component";
var styles_RePrintDialogComponent = [i0.styles];
var RenderType_RePrintDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RePrintDialogComponent, data: {} });
export { RenderType_RePrintDialogComponent as RenderType_RePrintDialogComponent };
export function View_RePrintDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Printer fout!"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Er is een fout opgetrijden tijdens het printer, controleer de printer. wilt u het printen nogmaals proberen?"])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(12, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Opnieuw"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Annuleren"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = true; _ck(_v, 12, 0, currVal_4); var currVal_8 = false; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 11).disabled || null); var currVal_2 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 12).ariaLabel || null); _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 15).disabled || null); var currVal_6 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 16).ariaLabel || null); _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_RePrintDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RePrintDialogComponent_0, RenderType_RePrintDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.RePrintDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], null, null); }
var RePrintDialogComponentNgFactory = i1.ɵccf("ng-component", i8.RePrintDialogComponent, View_RePrintDialogComponent_Host_0, {}, {}, []);
export { RePrintDialogComponentNgFactory as RePrintDialogComponentNgFactory };
