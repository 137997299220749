<span *ngIf="routerLink" class="menu-item" [routerLink]="routerLink" (click)="navigate.emit()" [routerLinkActive]="[routerLink ? 'active' : null]">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</span>

<span *ngIf="!routerLink" class="menu-item">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</span>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>