<div class="styling-container">
  
  <section class="top">
    <div class="top-colored"></div>
  </section>

  <section class="middle">
    <div class="middle-left"></div>

    <div class="content">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    
    <div class="middle-right">
      <div class="middle-right-colored"></div>
    </div>
  </section>

  <section class="bottom">
    <div class="bottom-colored"></div>
  </section>
</div>
