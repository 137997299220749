<h3 mat-dialog-title>{{dialogData.priority}} - {{ dialogData.operationName }}</h3>
<hr />
<mat-dialog-content>
    <label>
        Naar welke machine moet het werk van machine {{ dialogData.machineNumber }} verplaatst worden?
        <br />
        <br />
        Dit kan een langere tijd duren. A.u.b. niet het scherm sluiten.
    </label>
    <mat-form-field>
        <mat-select [value]="selectedMachine">
            <mat-option *ngFor="let machineNumber of dialogData.machines" [value]="machineNumber" (change)="onSelectChange(machineNumber)">Machine {{ machineNumber }}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]="-1">Annuleren</button>
  <button mat-flat-button class="confirm-button" [class.confirmButtonRed]="dialogData.confirmButtonRed" [mat-dialog-close]="selectedMachine">{{ dialogData.buttonText }}</button>
</mat-dialog-actions>
