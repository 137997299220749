<vt-styled-authorization-page [content]="recoveryCode"></vt-styled-authorization-page>

<ng-template #recoveryCode>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <h3>Herstel two factor authenticatie</h3>
        <p>
            <span>Voer de herstelcode in het onderstaande veld in om de two factor authenticatie te resetten.</span>
        </p>
        <mat-form-field>
            <input type="text" matInput placeholder="Herstel code" formControlName="recoveryCode" />
        </mat-form-field>

        <p *ngIf="error" class="login-error">{{ error }}</p>

        <div class="login-buttons">
            <button class="login-button" mat-stroked-button color="accent" type="submit" mat-button>
                Bevestigen
            </button>
            <a class="login-button" mat-flat-button color="primary" [routerLink]="['/login']">Annuleren</a>
        </div>
    </form>
</ng-template>
