import { Store } from '@ngrx/store';
declare var BrowserPrint: any;

import * as fromOrderOperation from '@app/order-operations/reducers';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/core/services/app-config-service';
import { LayoutActions } from '@app/core/actions';

@Injectable()
export class PrintService {

  /**
   *Creates an instance of PrintService.
   * @param {Store<fromOrderOperation.State>} store
   * @memberof PrintService
   */
  constructor(public readonly store: Store<fromOrderOperation.State>) {}

  /**
   * Abort flag if printing fails
   *
   * @type {boolean}
   * @memberof PrintService
   */
  abort: boolean = false;

  /**
   * Print sticker to zebra printer
   *
   * @param {string[]} stickers
   * @memberof PrintService
   */
  public async printStickersOnDefaultDevice(stickers: string[]) {
    this.abort = false;
    for (let stickerString of stickers) {
      await this.printDelay(Number.parseInt(AppConfigService.config.App.PrintDelay)).then(async () => {
        if (!this.abort) {
          BrowserPrint.getDefaultDevice(
            'printer',
            (device: any) => {
              try {
                if (!this.abort) {
                  device.send(stickerString, function(response: any) {}, (error_response: any) => {
                    if (!this.abort) {
                      this.store.dispatch(new LayoutActions.StickerPrintFailed(stickers));
                      this.abort = true;
                    }
                  });
                }
              } catch {}
            },
            (error_response: any) => {
              if (!this.abort) {
                this.store.dispatch(new LayoutActions.StickerPrintFailed(stickers));
                this.abort = true;
              }
            }
          );
        }
      });
    }
  }

  /**
   * Delay printer, This way we keep the order printing outcome equals to the income order
   *
   * @param {number} ms
   * @memberof PrintService
   */
  async printDelay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }
}
