import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vt-information-page',
  templateUrl: './information-page.component.html',
  styles: [],
})
export class InformationPageComponent implements OnInit {
  ngOnInit() {}
}
