import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromAuth from '@app/auth/reducers';
import { map } from "rxjs/operators";
import { LoginPageActions } from "../../actions";
import { TwoFactorRequest } from "../../models/twoFactorRequest";
import { LoginResponse } from "../../models/loginResponse";

@Component({
    selector: 'vt-two-factor-page',
    templateUrl: './two-factor-page.component.html'
})
export class TwoFactorPageComponent implements OnInit {
    actionsSubscription: Subscription;
    redirectURL: string | null;
    error$ = this.store.pipe(select(fromAuth.getLoginPageError));
    loginResponse$ = this.store.pipe(select(fromAuth.getLoginResponse));

    loginResponse: LoginResponse | null;

    constructor(private readonly store: Store<fromAuth.State>, route: ActivatedRoute, private readonly router: Router) {
        this.actionsSubscription = route.queryParams
            .pipe(
                map(params => {
                    this.redirectURL = params.redirectURL;
                })
            )
            .subscribe();

        this.loginResponse$.subscribe(loginResponse => {
            this.loginResponse = loginResponse
        });
    }

    ngOnInit(): void {
        if(this.loginResponse === null || this.loginResponse === undefined) {
            this.router.navigate(['/login']);
        }
    }

    ngOnDestroy() {
        if(this.actionsSubscription != null) {
            this.actionsSubscription.unsubscribe();
        }
    }

    onSubmit(twoFactorRequest: TwoFactorRequest) {
        this.store.dispatch(new LoginPageActions.TwoFactorLogin({ twoFactorRequest, redirectURL: this.redirectURL }));
    }

    onSendTwoFactorCode() {
        const twoFactorRequest: TwoFactorRequest = {
            userName: this.loginResponse.username,
            code: null,
            rememberMe: false
        }
        
        this.store.dispatch(new LoginPageActions.SendTwoFactorActivationCode(twoFactorRequest));
    }
}