var _a;
export var TwoFactorMethod;
(function (TwoFactorMethod) {
    TwoFactorMethod[TwoFactorMethod["App"] = 0] = "App";
    TwoFactorMethod[TwoFactorMethod["Mail"] = 1] = "Mail";
})(TwoFactorMethod || (TwoFactorMethod = {}));
export var twoFatorMethods = (_a = {},
    _a[TwoFactorMethod.App] = 'Authenticator app',
    _a[TwoFactorMethod.Mail] = 'Authenticatie mail',
    _a);
export var twoFactorMethodOptions = [
    { key: TwoFactorMethod.App, value: twoFatorMethods[TwoFactorMethod.App] },
    { key: TwoFactorMethod.Mail, value: twoFatorMethods[TwoFactorMethod.Mail] }
];
