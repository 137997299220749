var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatPaginatorIntl } from '@angular/material';
var MatPaginatorIntlNL = /** @class */ (function (_super) {
    __extends(MatPaginatorIntlNL, _super);
    function MatPaginatorIntlNL() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.itemsPerPageLabel = 'Resultaten per pagina';
        _this.nextPageLabel = 'Volgende pagina';
        _this.lastPageLabel = 'Laatste pagina';
        _this.firstPageLabel = 'Eerste pagina';
        _this.previousPageLabel = 'Vorige pagina';
        /**
         * Returns the label for the range of items within the current page and the length of the whole list.
         */
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return '0 van ' + length;
            }
            var maxLength = Math.max(length, 0);
            var startIndex = page * pageSize;
            var endIndex = startIndex < maxLength ? Math.min(startIndex + pageSize, maxLength) : startIndex + pageSize;
            return (startIndex + 1) + " - " + endIndex + " van " + maxLength;
        };
        return _this;
    }
    return MatPaginatorIntlNL;
}(MatPaginatorIntl));
export { MatPaginatorIntlNL };
