var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthenticationApiActions, AuthenticationActions } from '@app/auth/actions';
export var initialState = {
    user: null,
    loginResponse: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthenticationApiActions.AuthenticationApiActionTypes.LoginSuccess: {
            return __assign({}, state, { loginResponse: action.payload.loginResponse });
        }
        case AuthenticationActions.AuthenticationActionTypes.Logout: {
            localStorage.clear();
            return initialState;
        }
        case AuthenticationApiActions.AuthenticationApiActionTypes.RetrieveUserSuccess: {
            return __assign({}, state, { user: action.payload.user });
        }
        default: {
            return state;
        }
    }
}
export var getUser = function (state) { return state.user; };
export var getLoginResponse = function (state) { return state.loginResponse; };
