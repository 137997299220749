import { RequestOptions } from '@app/core/models/RequestOptions';

export class VTRequestOptions implements RequestOptions {
  headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  };
  observe = 'body';
  responseType = 'json';
  withCredentials = false;
}
