var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHandler } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { map, flatMap, mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { TokenService } from '@app/auth/services/token.service';
import { environment } from '@src/environments/environment';
import { VTRequestOptions } from '@app/core/services/http/vt.request.options';
import { AppConfigService } from '@app/core/services/app-config-service';
var HttpService = /** @class */ (function (_super) {
    __extends(HttpService, _super);
    /**
     * Initializes a new instance of the HttpService class.
     * @param {handler} HttpHandler The HttpHandler.
     * @param {VTRequestOptions} defaultOptions The default request options.
     * @param {TokenService} tokenService The token service.
     */
    function HttpService(handler, defaultOptions, tokenService) {
        var _this = _super.call(this, handler) || this;
        _this.defaultOptions = defaultOptions;
        _this.tokenService = tokenService;
        /**
         * The amount of XHR requests created.
         */
        _this.xhrCreations = 0;
        /**
         * The amount of XHR requests resolved.
         */
        _this.xhrResolutions = 0;
        return _this;
    }
    /**
     * Performs a request with `get` http method.
     * @param {string} url The url.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.get = function (url, options) {
        var _this = this;
        return this.executeRequest(function () {
            return _this.pipeResponse(_super.prototype.get.call(_this, _this.getFullUrl(url), _this.getRequestOptions(options)));
        }, options);
    };
    /**
     * Performs a request with `put` http method.
     * @param {string} url The url.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.put = function (url, body, options) {
        var _this = this;
        return this.executeRequest(function () {
            return _this.pipeResponse(_super.prototype.put.call(_this, _this.getFullUrl(url), _this.serialize(body), _this.getRequestOptions(options)));
        }, options);
    };
    /**
     * Performs a request with `post` http method.
     * @param {string} url The url.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.post = function (url, body, options) {
        var _this = this;
        if (body === void 0) { body = {}; }
        return this.executeRequest(function () {
            return _this.pipeResponse(_super.prototype.post.call(_this, (AppConfigService.config.App.Environment.toLowerCase() === 'lokaal' && options !== undefined && options.withCredentials) ? url : _this.getFullUrl(url), _this.serialize(body), _this.getRequestOptions(options)));
        }, options);
    };
    /**
     * Performs a request with `delete` http method.
     * @param {string} url The url.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.delete = function (url, options) {
        var _this = this;
        return this.executeRequest(function () {
            return _this.pipeResponse(_super.prototype.delete.call(_this, _this.getFullUrl(url), _this.getRequestOptions(options)));
        }, options);
    };
    /**
     * Applies the onSuccess, onError and onComplete methods to the response.
     * @param {Observable<T>} response The response.
     */
    HttpService.prototype.pipeResponse = function (response) {
        var _this = this;
        this.xhrCreations++;
        return response.pipe(catchError(this.onCatch), tap(function (res) { return _this.onSuccess(res); }, function (error) { return _this.onError(error); }), finalize(function () { return _this.onComplete(); }));
    };
    /**
     * Executes the request.
     * @param {Observable<Response>} request The request.
     * @param {RequestOptions} options The request options.
     */
    HttpService.prototype.executeRequest = function (request, options) {
        var _this = this;
        if (this.tokenService.hasToken() &&
            this.tokenService.shouldRefreshToken() &&
            AppConfigService.config) {
            return this.requestRefreshToken(options).pipe(tap(function (response) {
                _this.tokenRefreshPromise = null;
                _this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims, response.features, response.isCustomer, response.versionNumber);
            }), flatMap(function () { return request(); }), catchError(this.onCatch));
        }
        return request().pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * Request the refresh token.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.requestRefreshToken = function (options) {
        if (this.tokenRefreshPromise == null) {
            this.tokenRefreshPromise = this.pipeResponse(_super.prototype.post.call(this, this.getFullUrl(AppConfigService.config.Api.RefreshTokenUrl), this.tokenService.getTokenObject(), this.getRequestOptions(options))).toPromise();
        }
        return of(this.tokenRefreshPromise).pipe(mergeMap(function (promise) {
            return promise.then(function (value) { return value; });
        }));
    };
    /**
     * Gets the request options.
     * @param {RequestOptions|undefined} options The request options.
     */
    HttpService.prototype.getRequestOptions = function (options) {
        return this.setAuthorizationHeader(__assign({}, this.defaultOptions, options));
    };
    /**
     * Sets the authorization header.
     * @param {RequestOptions} options The request options.
     */
    HttpService.prototype.setAuthorizationHeader = function (options) {
        return __assign({}, options, {
            headers: __assign({}, options.headers, {
                Authorization: "Bearer " + this.tokenService.getAccessToken(),
            }),
        });
    };
    /**
     * Perform actions when the request is successful.
     * @param {Response} response The response.
     */
    HttpService.prototype.onSuccess = function (response) { };
    /**
     * Perform actions when the request has errored.
     * @param {Response} response The error response.
     */
    HttpService.prototype.onError = function (response) {
        console.error('Request error => ', response);
    };
    /**
     * Perform actions when the request has errored.
     * @param {any} error The error.
     * @param {Observable<any>} caught The error observable.
     */
    HttpService.prototype.onCatch = function (error, caught) {
        return throwError(error);
    };
    /**
     * Perform actions when the request is completed.
     */
    HttpService.prototype.onComplete = function () {
        this.xhrResolutions++;
    };
    /**
     * Serialize the provided data.
     * @param {any} data The provided data.
     */
    HttpService.prototype.serialize = function (data) {
        return JSON.stringify(data);
    };
    /**
     * Get the full url.
     * @param {string} url The request url.
     */
    HttpService.prototype.getFullUrl = function (url) {
        return this.isExternalEndpoint(url)
            ? url
            : AppConfigService.config.Api.BaseUrl + "/" + url;
    };
    /**
     * Whether or not the provided url is an external endpoint.
     * @param {string} url The request url
     */
    HttpService.prototype.isExternalEndpoint = function (url) {
        var settings = environment.externalEndpoints;
        return settings.fullMatch
            ? settings.urls.some(function (x) { return x === url; })
            : settings.urls.some(function (x) { return url.includes(x); });
    };
    return HttpService;
}(HttpClient));
export { HttpService };
