import { Action } from '@ngrx/store';
import { Filter } from '@app/core/models/Filters/Filter';
import { Product } from '@app/products/models/product';
import { ClusteredOrderOperation } from '@app/order-operations/models/order-operation/clustered-order-operation';
import { ProductionBatch } from '@app/reports/models/productionBatch';
import { ClusteredOrderOperationRequest } from '@app/order-operations/models/order-operation/clustered-order-operation-request';

export enum FindOrderPlinthBlocksActionTypes {
  LoadProducts = '[Find Order PlinthBlocks] Load Products',
  LoadProductsSuccess = '[Find Order PlinthBlocks] Load Products Success',
  LoadProductsFailure = '[Find Order PlinthBlocks] Load Products Failure',
  LoadOrderPlinthBlocks = '[Find Order PlinthBlocks] Load Order Plinth Blocks',
  CountOrderPlinthBlocks = '[Find Order Operations] Count Order Plinth Blocks',
  ExportOrderPlinthBlocks = '[Find Order Operations] Export Order Plinth Blocks',
  LoadClusteredOrderOperations = '[Find Order Operations] Load Clustered Order Operations',
  LoadClusteredOrderOperationsSuccess = '[Find Order Operations] Load Clustered Order Operations Success',
  LoadClusteredOrderOperationsFailure = '[Find Order Operations] Load Clustered Order Operations Failure',
  GetClusteredOrderOperationsCount = '[Find Order Operations] Load Clustered Order Operations Count',
  GetClusteredOrderOperationsCountSuccess = '[Find Order Operations] Get Clustered Order Operations Count Success',
  LoadPlinthBlockProductionBatches = '[Find Order Operations] Load Plinth Block Production Batches',
  LoadPlinthBlockProductionBatchesSuccess = '[Find Order Operations] Load Plinth Block Production Batches Success',
  LoadPlinthBlockProductionBatchesFailure = '[Find Order Operations] Load Plinth Block Production Batches Failure',
}

export class LoadOrderPlinthBlocks implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadOrderPlinthBlocks;

  constructor(public payload: Filter) {}
}

export class CountOrderPlinthBlocks implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.CountOrderPlinthBlocks;

  constructor(public payload: Filter) {}
}

export class LoadProducts implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadProducts;

  constructor(public payload: Filter) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadProductsSuccess;

  constructor(public payload: Product[]) {}
}

export class LoadProductsFailure implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadProductsFailure;

  constructor(public payload: Error[]) {}
}

export class LoadClusteredOrderOperations implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadClusteredOrderOperations;

  constructor(public payload: ClusteredOrderOperationRequest) {}
}

export class LoadPlinthBlockProductionBatches implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadPlinthBlockProductionBatches;
}

export class LoadClusteredOrderOperationsSuccess implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadClusteredOrderOperationsSuccess;

  constructor(public payload: ClusteredOrderOperation[]) {}
}

export class LoadClusteredOrderOperationsFailure implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadClusteredOrderOperationsFailure;

  constructor(public payload: Error[]) {}
}

export class GetClusteredOrderOperationsCount implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.GetClusteredOrderOperationsCount;

  constructor(public payload: ClusteredOrderOperationRequest) {}
}

export class GetClusteredOrderOperationsCountSuccess implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.GetClusteredOrderOperationsCountSuccess;

  constructor(public payload: number) {}
}

export class LoadPlinthBlockProductionBatchesSuccess implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadPlinthBlockProductionBatchesSuccess;

  constructor(public payload: ProductionBatch[]) {}
}

export class LoadPlinthBlockProductionBatchesFailure implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.LoadPlinthBlockProductionBatchesFailure;

  constructor(public payload: Error[]) {}
}

export class ExportOrderPlinthBlocks implements Action {
  readonly type = FindOrderPlinthBlocksActionTypes.ExportOrderPlinthBlocks;
}

export type FindOrderPlinthBlocksActionsUnion =
  | LoadOrderPlinthBlocks
  | CountOrderPlinthBlocks
  | LoadClusteredOrderOperations
  | LoadClusteredOrderOperationsSuccess
  | LoadClusteredOrderOperationsFailure
  | LoadPlinthBlockProductionBatches
  | LoadPlinthBlockProductionBatchesSuccess
  | LoadPlinthBlockProductionBatchesFailure
  | LoadProducts
  | LoadProductsSuccess
  | LoadProductsFailure
  | ExportOrderPlinthBlocks
  | GetClusteredOrderOperationsCount
  | GetClusteredOrderOperationsCountSuccess
  ;
