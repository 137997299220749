import { TemplateRef, ViewContainerRef } from '@angular/core';
import { hasFeature } from '@app/core/functions/feature-flag.functions';
var HasFeatureDirective = /** @class */ (function () {
    function HasFeatureDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(HasFeatureDirective.prototype, "hasFeature", {
        /**
         * Validate if feature flag is enabled
         * @returns {string} The feature.
         */
        set: function (feature) {
            var condition = hasFeature(feature);
            if (condition) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            else {
                this.viewContainer.clear();
            }
        },
        enumerable: true,
        configurable: true
    });
    return HasFeatureDirective;
}());
export { HasFeatureDirective };
