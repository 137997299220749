import { OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import * as fromAuth from '@app/auth/reducers';
import { LayoutActions } from '@app/core/actions';
import { mapAction, mapConfig } from '@app/core/models/SnackBar';
import { AppInsightService } from '@app/core/services/application-insights';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
var AppComponent = /** @class */ (function () {
    function AppComponent(snackBar, store, appInsightService, loaderService, router) {
        this.snackBar = snackBar;
        this.store = store;
        this.appInsightService = appInsightService;
        this.loaderService = loaderService;
        this.router = router;
        this.showLoader = new BehaviorSubject(false);
        this.coverHeader = new BehaviorSubject(false);
        this.infoSnackBar$ = this.store.select(fromAuth.getErrorSnackBar);
        this.showErrorSnackBar$ = this.store.select(fromAuth.getShowErrorSnackBar);
        this.successSnackBar$ = this.store.select(fromAuth.getSuccessSnackBar);
        this.showSuccessSnackBar$ = this.store.select(fromAuth.getShowSuccessSnackBar);
        this.infoSnackBar$ = this.store.select(fromAuth.getInfoSnackBar);
        this.showInfoSnackBar$ = this.store.select(fromAuth.getShowInfoSnackBar);
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errorSnackBarSubscription = this.showErrorSnackBar$.subscribe(function (show) {
            return show ? _this.openErrorSnackBar() : null;
        });
        this.successSnackBarSubscription = this.showSuccessSnackBar$.subscribe(function (show) {
            return show ? _this.openSuccessSnackBar() : null;
        });
        this.infoSnackBarSubscription = this.showInfoSnackBar$.subscribe(function (show) {
            return show ? _this.openInfoSnackBar() : null;
        });
        this.loaderSubscription = this.loaderService.isLoaderShown.subscribe(function (_a) {
            var showLoader = _a[0], coverHeader = _a[1];
            _this.coverHeader.next(coverHeader);
            _this.showLoader.next(showLoader);
        });
        this.router.events.subscribe(function (routerEvent) {
            if (routerEvent instanceof NavigationStart) {
                _this.loaderService.showLoader();
            }
            else if (routerEvent instanceof NavigationEnd ||
                routerEvent instanceof NavigationCancel ||
                routerEvent instanceof NavigationError) {
                _this.loaderService.hideLoader();
            }
        });
        this.appInsightService.logEvent('Portal.Client.Loaded');
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.errorSnackBarSubscription != null) {
            this.errorSnackBarSubscription.unsubscribe();
        }
        if (this.successSnackBarSubscription != null) {
            this.successSnackBarSubscription.unsubscribe();
        }
        if (this.infoSnackBarSubscription != null) {
            this.infoSnackBarSubscription.unsubscribe();
        }
        if (this.loaderSubscription != null) {
            this.loaderSubscription.unsubscribe();
        }
    };
    AppComponent.prototype.onOpenHelp = function () {
        this.store.dispatch(new LayoutActions.DownloadHelp());
    };
    AppComponent.prototype.openErrorSnackBar = function () {
        var _this = this;
        var errorMessage = '';
        this.errorSnackBar$.pipe(first()).subscribe(function (snackBar) {
            errorMessage = snackBar.messages.join('\n');
            _this.errorSnackBarReference = _this.snackBar.open(errorMessage, mapAction(snackBar.action), mapConfig(snackBar.config));
            _this.errorSnackBarReference
                .afterDismissed()
                .pipe(first())
                .subscribe(function () {
                _this.closeErrorSnackBar();
            });
        });
    };
    AppComponent.prototype.closeErrorSnackBar = function () {
        this.store.dispatch(new LayoutActions.CloseErrorSnackBar());
    };
    AppComponent.prototype.openSuccessSnackBar = function () {
        var _this = this;
        var successMessage = '';
        this.successSnackBar$.pipe(first()).subscribe(function (snackBar) {
            successMessage = snackBar.messages.join('\n');
            _this.successSnackBarReference = _this.snackBar.open(successMessage, mapAction(snackBar.action), mapConfig(snackBar.config));
            _this.successSnackBarReference
                .afterDismissed()
                .pipe(first())
                .subscribe(function () {
                _this.closeSuccessSnackBar();
            });
        });
    };
    AppComponent.prototype.closeSuccessSnackBar = function () {
        this.store.dispatch(new LayoutActions.CloseSuccessSnackBar());
    };
    AppComponent.prototype.openInfoSnackBar = function () {
        var _this = this;
        var infoMessage = '';
        this.infoSnackBar$.pipe(first()).subscribe(function (snackBar) {
            infoMessage = snackBar.messages.join('\n');
            _this.infoSnackBarReference = _this.snackBar.open(infoMessage, mapAction(snackBar.action), mapConfig(snackBar.config));
            _this.infoSnackBarReference
                .afterDismissed()
                .pipe(first())
                .subscribe(function () {
                _this.closeInfoSnackBar();
            });
        });
    };
    AppComponent.prototype.closeInfoSnackBar = function () {
        this.store.dispatch(new LayoutActions.CloseInfoSnackBar());
    };
    return AppComponent;
}());
export { AppComponent };
