import { Component } from '@angular/core';
import * as fromAuth from '@app/auth/reducers';
import { Store } from '@ngrx/store';
import { ForgotPasswordPageActions } from '@app/auth/actions';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';

@Component({
  selector: 'vt-reset-password-page',
  templateUrl: './reset-password-page.component.html',
})
export class ResetPasswordPageComponent {
  constructor(private readonly store: Store<fromAuth.State>) {}

  onSubmit(requestResetPassword: ResetPasswordRequest) {
    this.store.dispatch(new ForgotPasswordPageActions.SubmitResetPasswordRequest(requestResetPassword));
  }
}
