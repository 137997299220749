import { Input, Component } from '@angular/core';
import { Error } from '@app/core/models/Error';

@Component({
  selector: 'vt-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input() errors: Error[];

  constructor() {}

  get errorArray(){
      return this.errors;
  }
}
