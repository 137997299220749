import { NavigationEnd, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var RouterExtService = /** @class */ (function () {
    function RouterExtService(router) {
        var _this = this;
        this.router = router;
        this.previousOrderOverview = '/orders';
        this.previousUrl = "";
        this.currentUrl = "";
        this.currentUrl = this.router.url;
        router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (event.url == '/orders' || event.url == '/afas/list') {
                    _this.previousOrderOverview = event.url;
                }
                _this.previousUrl = _this.currentUrl;
                _this.currentUrl = event.url;
            }
            ;
        });
    }
    RouterExtService.prototype.getPreviousUrl = function () {
        return this.previousUrl;
    };
    RouterExtService.prototype.getPreviousOrderOverviewUrl = function () {
        return this.previousOrderOverview;
    };
    RouterExtService.ngInjectableDef = i0.defineInjectable({ factory: function RouterExtService_Factory() { return new RouterExtService(i0.inject(i1.Router)); }, token: RouterExtService, providedIn: "root" });
    return RouterExtService;
}());
export { RouterExtService };
