import { Action } from '@ngrx/store';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';

export enum RegisterForgotPasswordPageActionTypes {
  SubmitForgotPassword = '[Submit Forgot Password Request Page] Submit ForgotPasswordRequest',
  SubmitResetPassword = '[Submit Reset Password Page] Submit ResetPasswordRequest',
}

export class SubmitForgotPasswordRequest implements Action {
  readonly type = RegisterForgotPasswordPageActionTypes.SubmitForgotPassword;

  constructor(public payload: ForgotPasswordRequest ) {}
}

export class SubmitResetPasswordRequest implements Action {
  readonly type = RegisterForgotPasswordPageActionTypes.SubmitResetPassword;

  constructor(public payload: ResetPasswordRequest ) {}
}

export type ForgotPasswordPageActionsUnion = SubmitForgotPasswordRequest | SubmitResetPasswordRequest;

