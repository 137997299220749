import { KeyValuePair } from '@app/core/models/keyValuePair';

export enum TwoFactorMethod {
    App = 0,
    Mail = 1
}

export const twoFatorMethods: { [index: number]: string} = {
    [TwoFactorMethod.App]: 'Authenticator app',
    [TwoFactorMethod.Mail]: 'Authenticatie mail'
};

export const twoFactorMethodOptions: Array<KeyValuePair> = [
    { key: TwoFactorMethod.App, value: twoFatorMethods[TwoFactorMethod.App] },
    { key: TwoFactorMethod.Mail, value: twoFatorMethods[TwoFactorMethod.Mail] }
];
