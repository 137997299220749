import { Pipe, PipeTransform } from '@angular/core';
import { ProductTypeCount } from '@app/orders/models/product-type-count';

@Pipe({
  name: 'productTypeCountText'
})
export class ProductTypeCountTextPipe implements PipeTransform {
  transform(value: ProductTypeCount[] | null): string {
    if(value === null || value === undefined){
      return '';
    }
    return value.map(x => `${x.type}(${x.count})`).join(',');
  }
}