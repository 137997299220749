import { MatSnackBarConfig } from '@angular/material';

export interface SnackBar {
  messages: string[];
  action?: string;
  config?: MatSnackBarConfig;
}

export function mapConfig(config?: MatSnackBarConfig): any {
  const defaultConfig = {
    duration: 2000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
  };

  return { ...defaultConfig, ...config };
}

export function mapAction(action?: string): string {
  return action ? action : 'Sluiten';
}
