import { Component, Input } from '@angular/core';
import { OrderProductionStatus } from '@app/core/models/order-production-status';

@Component({
  selector: 'vt-order-production-status',
  templateUrl: './order-production-status.component.html',
  styleUrls: ['./order-production-status.component.scss']
})
export class OrderProductionStatusComponent {
  @Input() productionStatus: OrderProductionStatus;
  
  tooltip: string;

  getPercentage() {
    return Math.round((this.productionStatus.operationsDone / this.productionStatus.totalOperations) * 100);
  }
}
