<h3>Wachtwoord vergeten?</h3>
<form [formGroup]="form" *ngIf="!formSubmitted">
  <p>Vul hieronder je email-adres in.</p>
  <div>
    <mat-form-field>
      <input matInput type="text" placeholder="Email" formControlName="Email" />
    </mat-form-field>
  </div>
  <div class="password-reset-buttons">
    <button mat-flat-button color="primary" [routerLink]="['/login']">Annuleren</button>
    <button mat-stroked-button color="accent" type="button" (click)="submit()">Versturen</button>
  </div>
</form>
<div *ngIf="formSubmitted">
  <p>Er wordt een e-mail gestuurd naar het gekoppelde account om uw wachtwoord te resetten.</p>
  <mat-card-actions>
    <button mat-flat-button color="primary" [routerLink]="['/login']">Terug naar login</button>
  </mat-card-actions>
</div>
