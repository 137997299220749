<h3>Wachtwoord wijzigen</h3>
<form [formGroup]="form">
  <div class="detail-row">
    <div class="detail-col">
      <div class="form-group">
        <mat-form-field>
          <input matInput type="text" placeholder="E-mail *" formControlName="Email" />
        </mat-form-field>
      </div>
      <div class="form-group" formGroupName="Passwords">
        <mat-form-field>
          <input matInput type="password" placeholder="Nieuw wachtwoord *" formControlName="NewPassword" />
        </mat-form-field>
      </div>
      <div class="form-group" formGroupName="Passwords">
       <mat-form-field>
          <input type="password" matInput placeholder="Herhaal wachtwoord *" formControlName="NewPasswordRepeat" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="reset-password-buttons">
    <button mat-flat-button color="primary" [routerLink]="['/login']">Annuleren</button>
    <button mat-stroked-button color="accent" [disabled]="!form.valid" (click)="submit()">Wachtwoord wijzigen</button>
  </div>
</form>