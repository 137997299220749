import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { stripBase64Tag, checkBase64Tag } from '@app/core/components/base64-image-display/base64-image-display.component';

@Component({
  selector: 'vt-base64-image-upload',
  templateUrl: './base64-image-upload.component.html',
  styleUrls: ['./base64-image-upload.component.scss'],
})
export class Base64ImageUploadComponent implements OnInit {
  @Input() thumbnail: string;
  @Output() thumbnailChange = new EventEmitter<string>();

  @ViewChild('imgFileInput')
  thumbnailFileInput: ElementRef;

  thumbnail$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  form: FormGroup = new FormGroup({
    thumbnailFile: new FormControl(''),
  });

  ngOnInit(): void {
    this.fillForm();
  }

  ngOnUpdate(): void {
    this.fillForm();
  }

  fillForm(): void {
    if (!!this.thumbnail) {
      this.thumbnail$.next(this.thumbnail);
    }
  }

  changeThumbnail(fileInput: any) { 
    if (fileInput.target.files.length === 1) {
      const file: File = fileInput.target.files[0];
      const fileReader: FileReader = new FileReader();

      fileReader.onloadend = (e: any) => {
        const image = e.target.result;        
        if (image && checkBase64Tag(image.toString())) {
          this.updateThumbnail(stripBase64Tag(image.toString()));
        }
      };
      fileReader.readAsDataURL(file);
    }
  }

  deleteThumbnail() {
    this.thumbnailFileInput.nativeElement.value = '';
    this.updateThumbnail('');
  }

  updateThumbnail(newThumbnail: string) {   
    this.thumbnail = newThumbnail;
    this.thumbnail$.next(this.thumbnail);
    this.thumbnailChange.emit(this.thumbnail$.getValue());
  }
}
