import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LayoutActions } from '@app/core/actions';
import { RePrintDialogComponent } from '@app/core/components';
import { PrintDialogParameters } from '@app/core/models/print-dialog-parameters';
import { getRouteConfig } from '@app/core/models/routeConfig';
import { OrderOperationApiActions } from '@app/order-operations/actions';
import { WizardPageActions } from '@app/product-wizard/actions';
import { UsersService } from '@app/users/services/users.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly usersService: UsersService,
    private readonly dialog: MatDialog
  ) {}

  /**
   * Get and display manual pdf
   */
  @Effect({ dispatch: false })
  downloadHelp$ = this.actions$.pipe(
    ofType<LayoutActions.DownloadHelp>(LayoutActions.LayoutActionTypes.DownloadHelp),
    mergeMap(() =>
      this.usersService.help().pipe(
        map(response => {
          try {
            window.navigator.msSaveOrOpenBlob(response, `Handleiding bestelsysteem.pdf`);
          } catch {
            const fileURL = URL.createObjectURL(response);
            window.open(fileURL, '_blank');
          }
        })
      )
    )
  );

  /**
   * Show rety print message dialog
   */
  @Effect()
  retryPrint$ = this.actions$.pipe(
    ofType<LayoutActions.StickerPrintFailed>(LayoutActions.LayoutActionTypes.StickerPrintFailed),
    map(action => action.payload),
    exhaustMap((printList: string[]) => {
      const dialogRef = this.dialog.open<RePrintDialogComponent, PrintDialogParameters, string[]>(
        RePrintDialogComponent,
        {
          data: {
           printResult: printList
          },
        }
      );

      return dialogRef.afterClosed();
    }),
    map(result => result
      ? new OrderOperationApiActions.FinalizeSuccess(result)
      : new LayoutActions.StickerPrintFailedConfirmationDismiss())
  );

  /**
   * Send out actions based on navigation events
   */
  @Effect()
  ObserveNavigationEvents$: Observable<Action> = this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    map(action => action.payload),
    filter(x => x.event.url.includes(getRouteConfig().wizard)),
    map(x => new WizardPageActions.NavigatedToWizard())
  );
}
