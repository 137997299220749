/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recovery-code-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../styled-authorization-page.component.ngfactory";
import * as i9 from "../styled-authorization-page.component";
import * as i10 from "./recovery-code-page.component";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
var styles_RecoveryCodePageComponent = [i0.styles];
var RenderType_RecoveryCodePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecoveryCodePageComponent, data: {} });
export { RenderType_RecoveryCodePageComponent as RenderType_RecoveryCodePageComponent };
function View_RecoveryCodePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loginResponse.twoFactorRecoveryCode; _ck(_v, 1, 0, currVal_0); }); }
function View_RecoveryCodePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Herstel Code"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uw two factor authenticatie is succesvol ingesteld."])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sla onderstaande herstel code op om uw two factor authenticate te kunnen herstellen."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "recovery"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecoveryCodePageComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "login-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "login-button"], ["color", "accent"], ["mat-button", ""], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Doorgaan "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loginResponse != null); _ck(_v, 9, 0, currVal_0); var currVal_3 = "accent"; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 12).disabled || null); var currVal_2 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_1, currVal_2); }); }
export function View_RecoveryCodePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i8.View_StyledAuthorizationPageComponent_0, i8.RenderType_StyledAuthorizationPageComponent)), i1.ɵdid(1, 114688, null, 0, i9.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i1.ɵand(0, [["recoveryCode", 2]], null, 0, null, View_RecoveryCodePageComponent_1))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RecoveryCodePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-recovery-code-page", [], null, null, null, View_RecoveryCodePageComponent_0, RenderType_RecoveryCodePageComponent)), i1.ɵdid(1, 114688, null, 0, i10.RecoveryCodePageComponent, [i11.Store, i12.ActivatedRoute, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecoveryCodePageComponentNgFactory = i1.ɵccf("vt-recovery-code-page", i10.RecoveryCodePageComponent, View_RecoveryCodePageComponent_Host_0, {}, {}, []);
export { RecoveryCodePageComponentNgFactory as RecoveryCodePageComponentNgFactory };
