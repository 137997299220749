import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import * as fromAuth from '@app/auth/reducers';
import { LayoutActions } from '@app/core/actions';
import { mapAction, mapConfig, SnackBar } from '@app/core/models/SnackBar';
import { AppInsightService } from '@app/core/services/application-insights';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

declare global {
    interface Navigator {
      msSaveBlob(blob: any, defaultName?: string): boolean,
      msSaveOrOpenBlob(blob: any, defaultName?: string): boolean
    }
}

@Component({
  selector: 'vt-app',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  errorSnackBarSubscription: Subscription;
  errorSnackBarReference: MatSnackBarRef<any>;

  errorSnackBar$: Observable<SnackBar>;
  showErrorSnackBar$: Observable<boolean>;

  successSnackBarSubscription: Subscription;
  successSnackBarReference: MatSnackBarRef<any>;

  successSnackBar$: Observable<SnackBar>;
  showSuccessSnackBar$: Observable<boolean>;

  infoSnackBarSubscription: Subscription;
  infoSnackBarReference: MatSnackBarRef<any>;

  infoSnackBar$: Observable<SnackBar>;
  showInfoSnackBar$: Observable<boolean>;

  relevantNavigationEvents: RouterEvent[];

  loaderSubscription: Subscription;
  showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  coverHeader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public snackBar: MatSnackBar,
    private readonly store: Store<fromAuth.State>,
    private readonly appInsightService: AppInsightService,
    private readonly loaderService: LoaderService,
    private readonly router: Router
  ) {
    this.infoSnackBar$ = this.store.select(fromAuth.getErrorSnackBar);
    this.showErrorSnackBar$ = this.store.select(fromAuth.getShowErrorSnackBar);
    this.successSnackBar$ = this.store.select(fromAuth.getSuccessSnackBar);
    this.showSuccessSnackBar$ = this.store.select(fromAuth.getShowSuccessSnackBar);
    this.infoSnackBar$ = this.store.select(fromAuth.getInfoSnackBar);
    this.showInfoSnackBar$ = this.store.select(fromAuth.getShowInfoSnackBar);
  }

  ngOnInit() {
    this.errorSnackBarSubscription = this.showErrorSnackBar$.subscribe((show: boolean) =>
      show ? this.openErrorSnackBar() : null
    );
    this.successSnackBarSubscription = this.showSuccessSnackBar$.subscribe((show: boolean) =>
      show ? this.openSuccessSnackBar() : null
    );
    this.infoSnackBarSubscription = this.showInfoSnackBar$.subscribe((show: boolean) =>
      show ? this.openInfoSnackBar() : null
    );

    this.loaderSubscription = this.loaderService.isLoaderShown.subscribe(([showLoader, coverHeader]) => {
      this.coverHeader.next(coverHeader);
      this.showLoader.next(showLoader);
    });
    
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.loaderService.showLoader();
      } else if (
          routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel || 
          routerEvent instanceof NavigationError) {
        this.loaderService.hideLoader();
      }
    });

    this.appInsightService.logEvent('Portal.Client.Loaded');
  }

  ngOnDestroy() {
    if (this.errorSnackBarSubscription != null) {
      this.errorSnackBarSubscription.unsubscribe();
    }

    if (this.successSnackBarSubscription != null) {
      this.successSnackBarSubscription.unsubscribe();
    }

    if (this.infoSnackBarSubscription != null) {
      this.infoSnackBarSubscription.unsubscribe();
    }

    if (this.loaderSubscription != null) {
      this.loaderSubscription.unsubscribe();
    }
  }

  onOpenHelp() {
    this.store.dispatch(new LayoutActions.DownloadHelp());
  }

  private openErrorSnackBar() {
    let errorMessage = '';
    this.errorSnackBar$.pipe(first()).subscribe((snackBar: SnackBar) => {
      errorMessage = snackBar.messages.join('\n');
      this.errorSnackBarReference = this.snackBar.open(
        errorMessage,
        mapAction(snackBar.action),
        mapConfig(snackBar.config)
      );
      this.errorSnackBarReference
        .afterDismissed()
        .pipe(first())
        .subscribe(() => {
          this.closeErrorSnackBar();
        });
    });
  }

  private closeErrorSnackBar() {
   this.store.dispatch(new LayoutActions.CloseErrorSnackBar());
  }

  private openSuccessSnackBar() {
    let successMessage = '';
    this.successSnackBar$.pipe(first()).subscribe((snackBar: SnackBar) => {
      successMessage = snackBar.messages.join('\n');
      this.successSnackBarReference = this.snackBar.open(
        successMessage,
        mapAction(snackBar.action),
        mapConfig(snackBar.config)
      );
      this.successSnackBarReference
        .afterDismissed()
        .pipe(first())
        .subscribe(() => {
          this.closeSuccessSnackBar();
        });
    });
  }

  private closeSuccessSnackBar() {
    this.store.dispatch(new LayoutActions.CloseSuccessSnackBar());
  }

  private openInfoSnackBar(): void {
    let infoMessage = '';
    this.infoSnackBar$.pipe(first()).subscribe((snackBar: SnackBar) => {
      infoMessage = snackBar.messages.join('\n');
      this.infoSnackBarReference = this.snackBar.open(
        infoMessage,
        mapAction(snackBar.action),
        mapConfig(snackBar.config)
      );
      this.infoSnackBarReference
        .afterDismissed()
        .pipe(first())
        .subscribe(() => {
          this.closeInfoSnackBar();
        });
    });
  }

  private closeInfoSnackBar(): void {
    this.store.dispatch(new LayoutActions.CloseInfoSnackBar());
  }
}
