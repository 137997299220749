/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "./information.component";
var styles_InformationComponent = [i0.styles];
var RenderType_InformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationComponent, data: {} });
export { RenderType_InformationComponent as RenderType_InformationComponent };
export function View_InformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bedankt voor het registreren"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Venster Techniek neemt voor verdere informatie contact met u op."])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "login-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["color", "accent"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(7, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, 0, ["Login"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 6, 0, currVal_2); var currVal_3 = _ck(_v, 8, 0, "/login"); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).disabled || null); var currVal_1 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_InformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-information", [], null, null, null, View_InformationComponent_0, RenderType_InformationComponent)), i1.ɵdid(1, 114688, null, 0, i8.InformationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationComponentNgFactory = i1.ɵccf("vt-information", i8.InformationComponent, View_InformationComponent_Host_0, {}, {}, []);
export { InformationComponentNgFactory as InformationComponentNgFactory };
