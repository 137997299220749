import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { hasClaims, getClaims } from '@app/core/models/Claims/ClaimFunctions';
import { Claim } from '@app/core/models/Claims/Claim';

@Directive({ selector: '[hasClaims]' })
export class HasClaimsDirective {
  constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef) {}

  @Input()
  set hasClaims(claim : Claim) {
    const condition = hasClaims(getClaims(claim.claimType, claim.claimActions));
    if (!condition) {
      this.viewContainer.clear();
    } else if (condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
