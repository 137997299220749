import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import * as fromAuth from '@app/auth/reducers';
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { LoginResponse } from "@app/auth/models/loginResponse";

@Component({
    selector: 'vt-recovery-code-page',
    templateUrl: './recovery-code-page.component.html',
    styleUrls: ['./recovery-code-page.component.scss']
})
export class RecoveryCodePageComponent implements OnInit {
    recoveryCode: string;
    actionsSubscription: Subscription;
    redirectURL: string | null;
    loginResponse$ = this.store.pipe(select(fromAuth.getLoginResponse));
    loginResponse: LoginResponse;

    constructor(private readonly store: Store<fromAuth.State>, route: ActivatedRoute, private readonly router: Router) {
        this.actionsSubscription = route.queryParams
        .pipe(
            map(params => {
                this.redirectURL = params.redirectURL;
            })
        )
        .subscribe();

        this.loginResponse$.subscribe(loginResponse => {
            this.loginResponse = loginResponse;
        });
    }

    ngOnInit(): void {
        if(this.loginResponse === null || this.loginResponse === undefined) {
            this.router.navigate([this.redirectURL != null ? this.redirectURL : '/orders']);
        }
    }    

    submit() {
        this.router.navigate([this.redirectURL != null ? this.redirectURL : '/orders']);
    }
}