import { Action } from '@ngrx/store';
import { Error } from '@app/core/models/Error';
import { KeyValuePair } from '@app/core/models/keyValuePair';

export enum MitterPositionActionTypes {
  LoadMitterPositions = '[Mitter Positions] Load Mitter Positions',
  LoadMitterPositionsSuccess = '[Mitter Positions] Get Mitter Position Success',
  LoadMitterPositionsFailure = '[Mitter Positions] Get Mitter Position Failure',
}

export class LoadMitterPositions implements Action {
  readonly type = MitterPositionActionTypes.LoadMitterPositions;
}

/**
 * Load Mitter Positions Actions
 */
export class LoadMitterPositionsSuccess implements Action {
  readonly type = MitterPositionActionTypes.LoadMitterPositionsSuccess;

  constructor(public payload: KeyValuePair[]) {}
}

export class LoadMitterPositionsFailure implements Action {
  readonly type = MitterPositionActionTypes.LoadMitterPositionsFailure;
  
  constructor(public payload: Error[]) {}
}

export type MitterPositionActionsUnion =
  | LoadMitterPositions
  | LoadMitterPositionsSuccess
  | LoadMitterPositionsFailure;
