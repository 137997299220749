import { Component } from '@angular/core';
import * as fromAuth from '@app/auth/reducers';
import { Store } from '@ngrx/store';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ForgotPasswordPageActions } from '@app/auth/actions';

@Component({
  selector: 'vt-forgot-password-request-page',
  templateUrl: './forgot-password-request-page.component.html',
})
export class ForgotPasswordPageComponent {
  constructor(private readonly store: Store<fromAuth.State>) {}

  onSubmit(requestForgotPassword: ForgotPasswordRequest) {
    this.store.dispatch(new ForgotPasswordPageActions.SubmitForgotPasswordRequest(requestForgotPassword));
  }
}
