import { BehaviorSubject } from "rxjs";
import { AppConfigService } from '@app/core/services/app-config-service';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.isLoaderShown = new BehaviorSubject([false, false]);
    }
    LoaderService.prototype.showLoader = function (coverHeader) {
        if (coverHeader === void 0) { coverHeader = false; }
        if (!AppConfigService.config.App.ShowLoader) {
            return;
        }
        this.isLoaderShown.next([true, coverHeader]);
    };
    LoaderService.prototype.hideLoader = function () {
        this.isLoaderShown.next([false, false]);
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
