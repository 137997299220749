const FEATURES_NAME = 'FEATURES';

/**
 * Check if user has the required claims.
 * @param {string[]} requiredClaims The claims that are required.
 * @returns {boolean} User has required claims.
 */
export function hasFeature(requiredFeature: string): boolean {
  if (!requiredFeature) {
    return false;
  }

  return getFeaturesFromLocalStorage().some(c => c === requiredFeature) 
}

/**
 * Get features from local storage.
 * @returns {string[]} The claims.
 */
export function getFeaturesFromLocalStorage(): string[] {
  const features = localStorage.getItem(FEATURES_NAME);
  return !!features ? features.split(',') : [];
}

/**
 * Set feature in local storage.
 * @param {string[]} features The claims.
 */
export function setFeaturesInLocalStorage(features: string[]): void {
  localStorage.setItem(FEATURES_NAME, features.join(','));
}

/**
 * Remove features from local storage.
 */
export function removeFeaturesInLocalStorage(): void {
  localStorage.removeItem(FEATURES_NAME);
}
