import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TwoFactorRequest } from "../../models/twoFactorRequest";
import { LoginResponse } from "../../models/loginResponse";
import { TwoFactorMethod, twoFactorMethodOptions } from "../../models/enums/two-factor-method.enum";

@Component({
    selector: 'vt-two-factor-form',
    templateUrl: './two-factor-form.component.html',
    styleUrls: ['./two-factor-form.component.scss']
})
export class TwoFactorFormComponent implements OnInit, OnChanges {
    redirectURL: string | null;
    twoFactorMethodOptions = twoFactorMethodOptions;
    twoFactormethods = TwoFactorMethod;
    twoFactorMethod: TwoFactorMethod;
    twoFactorMailSent = false;
    placeholder = "Code";
    recoverUrl = "/recover";

    @Input() loginResponse: LoginResponse | null;
    @Input() errorMessage: string | null;
    @Output() submitted = new EventEmitter<TwoFactorRequest>();
    @Output() sendTwoFactorCode = new EventEmitter();

    form: FormGroup = new FormGroup({
        twoFactorMethod: new FormControl(null),
        code: new FormControl('', [Validators.required]),
        rememberMe: new FormControl(false)
    });

    ngOnInit(): void {
        this.form.valueChanges.subscribe(() => {
            this.twoFactorMethod = this.form.controls["twoFactorMethod"].value
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.errorMessage && this.errorMessage != null) {
            this.form.controls["code"].setValue('');
        }
    }

    sendActivationMail() {
        this.twoFactorMailSent = true;
        this.sendTwoFactorCode.emit();
    }

    submit() {
        if (this.form.valid) {
            const request =  new TwoFactorRequest();
            request.code = this.form.value.code;
            request.userName = this.loginResponse.username;
            request.rememberMe = this.form.value.rememberMe;
            this.submitted.emit(request);
        }
    }
}