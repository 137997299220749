/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/forgot-password-request.component.ngfactory";
import * as i2 from "../components/forgot-password-request.component";
import * as i3 from "./styled-authorization-page.component.ngfactory";
import * as i4 from "./styled-authorization-page.component";
import * as i5 from "./forgot-password-request-page.component";
import * as i6 from "@ngrx/store";
var styles_ForgotPasswordPageComponent = [];
var RenderType_ForgotPasswordPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordPageComponent, data: {} });
export { RenderType_ForgotPasswordPageComponent as RenderType_ForgotPasswordPageComponent };
function View_ForgotPasswordPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-forgot-password-request-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ForgotPasswordFormRequestComponent_0, i1.RenderType_ForgotPasswordFormRequestComponent)), i0.ɵdid(1, 114688, null, 0, i2.ForgotPasswordFormRequestComponent, [], null, { submitted: "submitted" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ForgotPasswordPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i3.View_StyledAuthorizationPageComponent_0, i3.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i4.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["passwordForm", 2]], null, 0, null, View_ForgotPasswordPageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ForgotPasswordPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-forgot-password-request-page", [], null, null, null, View_ForgotPasswordPageComponent_0, RenderType_ForgotPasswordPageComponent)), i0.ɵdid(1, 49152, null, 0, i5.ForgotPasswordPageComponent, [i6.Store], null, null)], null, null); }
var ForgotPasswordPageComponentNgFactory = i0.ɵccf("vt-forgot-password-request-page", i5.ForgotPasswordPageComponent, View_ForgotPasswordPageComponent_Host_0, {}, {}, []);
export { ForgotPasswordPageComponentNgFactory as ForgotPasswordPageComponentNgFactory };
