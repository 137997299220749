import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vt-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent {
  @Input() active = false
  @Input() routerLink: string | any[];
  @Output() navigate = new EventEmitter();
}
