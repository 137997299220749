import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  templateUrl: 'confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly dialogRef: MatDialogRef<ConfirmDeleteComponent>) {
    if (data === null) {
      this.data = true;
    }
  }

  close() {
    this.dialogRef.close(this.data);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
