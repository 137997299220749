import { JwtHelperService } from '@auth0/angular-jwt';
import { getClaimsFromLocalStorage, removeClaimsInLocalStorage, setClaimsInLocalStorage, } from '@app/core/models/Claims/ClaimFunctions';
import { setFeaturesInLocalStorage, removeFeaturesInLocalStorage } from '@app/core/functions/feature-flag.functions';
import { AppConfigService } from '@app/core/services/app-config-service';
import * as i0 from "@angular/core";
export var ACCESS_TOKEN_NAME = 'ACCESS_TOKEN';
export var REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
export var IS_CUSTOMER_NAME = 'IS_CUSTOMER';
export var VERSION_NUMBER = 'VERSION_NUMBER';
var TokenService = /** @class */ (function () {
    function TokenService() {
        this.jwtHelperService = new JwtHelperService();
    }
    TokenService.prototype.getTokenObject = function () {
        return {
            accessToken: this.getAccessToken(),
            refreshToken: this.getRefreshToken(),
            claims: getClaimsFromLocalStorage(),
        };
    };
    TokenService.prototype.getAccessToken = function () {
        var token = localStorage.getItem(ACCESS_TOKEN_NAME);
        return !!token ? token : '';
    };
    TokenService.prototype.getRefreshToken = function () {
        var refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
        return !!refreshToken ? refreshToken : '';
    };
    TokenService.prototype.getIsCustomer = function () {
        var isCustomer = localStorage.getItem(IS_CUSTOMER_NAME);
        return !!isCustomer ? JSON.parse(isCustomer) : null;
    };
    TokenService.prototype.setToken = function (token, refreshToken, claims, features, isCustomer, versionNumber) {
        localStorage.setItem(ACCESS_TOKEN_NAME, token);
        localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
        setClaimsInLocalStorage(claims);
        setFeaturesInLocalStorage(features);
        localStorage.setItem(IS_CUSTOMER_NAME, isCustomer.toString());
        localStorage.setItem(VERSION_NUMBER, versionNumber);
    };
    TokenService.prototype.removeToken = function () {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        removeClaimsInLocalStorage();
        removeFeaturesInLocalStorage();
        localStorage.removeItem(IS_CUSTOMER_NAME);
        localStorage.removeItem(VERSION_NUMBER);
    };
    TokenService.prototype.hasToken = function () {
        return !!localStorage.getItem(ACCESS_TOKEN_NAME) && !!localStorage.getItem(REFRESH_TOKEN_NAME);
    };
    TokenService.prototype.getTokenExpirationDate = function () {
        var expirationDate = this.jwtHelperService.getTokenExpirationDate(this.getAccessToken());
        return expirationDate ? expirationDate : new Date();
    };
    TokenService.prototype.isTokenExpired = function () {
        var accessToken = this.getAccessToken();
        var date = this.jwtHelperService.getTokenExpirationDate(accessToken);
        var isExpired = this.jwtHelperService.isTokenExpired(accessToken);
        return !date ? false : isExpired || date.getTime() < new Date().getTime();
    };
    TokenService.prototype.shouldRefreshToken = function () {
        var requestDifference = AppConfigService.config
            ? AppConfigService.config.Tokens.RequestDifference
            : 0;
        return (this.isTokenExpired() ||
            this.getTokenExpirationDate().getTime() - new Date().getTime() <= requestDifference);
    };
    TokenService.prototype.canExpediteDeliveryDate = function () {
        return !this.getIsCustomer();
    };
    TokenService.ngInjectableDef = i0.defineInjectable({ factory: function TokenService_Factory() { return new TokenService(); }, token: TokenService, providedIn: "root" });
    return TokenService;
}());
export { TokenService };
