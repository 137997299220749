/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./not-found-page.component";
var styles_NotFoundPageComponent = [i0.styles];
var RenderType_NotFoundPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundPageComponent, data: {} });
export { RenderType_NotFoundPageComponent as RenderType_NotFoundPageComponent };
export function View_NotFoundPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["404: Niet gevonden"])), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hey! We kunnen deze pagina niet vinden."])), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i3.MatCardActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["color", "primary"], ["mat-flat-button", ""], ["routerLink", "/"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(12, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Terug naar overzicht"]))], function (_ck, _v) { var currVal_3 = "/"; _ck(_v, 12, 0, currVal_3); var currVal_4 = "primary"; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 10).align === "end"); _ck(_v, 9, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 13).disabled || null); var currVal_2 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_1, currVal_2); }); }
export function View_NotFoundPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-not-found-page", [], null, null, null, View_NotFoundPageComponent_0, RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i10.NotFoundPageComponent, [], null, null)], null, null); }
var NotFoundPageComponentNgFactory = i1.ɵccf("vt-not-found-page", i10.NotFoundPageComponent, View_NotFoundPageComponent_Host_0, {}, {}, []);
export { NotFoundPageComponentNgFactory as NotFoundPageComponentNgFactory };
