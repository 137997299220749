import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshRequest } from '@app/auth/models/refreshrequest';
import {
  getClaimsFromLocalStorage,
  removeClaimsInLocalStorage,
  setClaimsInLocalStorage,
} from '@app/core/models/Claims/ClaimFunctions';
import { setFeaturesInLocalStorage, removeFeaturesInLocalStorage } from '@app/core/functions/feature-flag.functions';
import { AppConfigService } from '@app/core/services/app-config-service';

export const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN';
export const REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
export const IS_CUSTOMER_NAME = 'IS_CUSTOMER';
export const VERSION_NUMBER = 'VERSION_NUMBER';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  private readonly jwtHelperService = new JwtHelperService();

  getTokenObject(): RefreshRequest {
    return {
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefreshToken(),
      claims: getClaimsFromLocalStorage(),
    };
  }

  getAccessToken(): string {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    return !!token ? token : '';
  }

  getRefreshToken(): string {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
    return !!refreshToken ? refreshToken : '';
  }

  getIsCustomer(): boolean | undefined {
    const isCustomer = localStorage.getItem(IS_CUSTOMER_NAME);
    return !!isCustomer ? JSON.parse(isCustomer) : null;
  }

  setToken(token: string, refreshToken: string, claims: string[], features: string[], isCustomer: boolean, versionNumber: string): void {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
    setClaimsInLocalStorage(claims);
    setFeaturesInLocalStorage(features);
    localStorage.setItem(IS_CUSTOMER_NAME, isCustomer.toString());
    localStorage.setItem(VERSION_NUMBER, versionNumber);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    removeClaimsInLocalStorage();
    removeFeaturesInLocalStorage();
    localStorage.removeItem(IS_CUSTOMER_NAME);
    localStorage.removeItem(VERSION_NUMBER);
  }

  hasToken(): boolean {
    return !!localStorage.getItem(ACCESS_TOKEN_NAME) && !!localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  getTokenExpirationDate(): Date {
    const expirationDate = this.jwtHelperService.getTokenExpirationDate(this.getAccessToken());
    return expirationDate ? expirationDate : new Date();
  }

  isTokenExpired(): boolean {
    const accessToken = this.getAccessToken();
    const date = this.jwtHelperService.getTokenExpirationDate(accessToken);
    const isExpired = this.jwtHelperService.isTokenExpired(accessToken);
    return !date ? false : isExpired || date.getTime() < new Date().getTime();
  }

  shouldRefreshToken(): boolean {
    const requestDifference = AppConfigService.config
      ? AppConfigService.config.Tokens.RequestDifference
      : 0;

    return (
      this.isTokenExpired() ||
      this.getTokenExpirationDate().getTime() - new Date().getTime() <= requestDifference
    );
  }
  
  canExpediteDeliveryDate(): boolean {
    return !this.getIsCustomer();
}
}
