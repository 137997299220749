var ErrorComponent = /** @class */ (function () {
    function ErrorComponent() {
    }
    Object.defineProperty(ErrorComponent.prototype, "errorArray", {
        get: function () {
            return this.errors;
        },
        enumerable: true,
        configurable: true
    });
    return ErrorComponent;
}());
export { ErrorComponent };
