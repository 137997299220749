import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginResponse } from "@app/auth/models/loginResponse";
import { Store, select } from "@ngrx/store";
import * as fromAuth from '@app/auth/reducers';
import { ActivatedRoute, Router } from "@angular/router";
import { LoginPageActions } from "@app/auth/actions";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
    selector: 'vt-recovery-page',
    templateUrl: './recover-authentication-page.component.html',
    styleUrls: ['./recovery-code-page.component.scss']
})
export class RecoverPageComponent implements OnInit {
    actionsSubscription: Subscription;
    redirectURL: string | null;
    loginResponse$ = this.store.pipe(select(fromAuth.getLoginResponse));
    loginResponse: LoginResponse | null;
    error$ = this.store.pipe(select(fromAuth.getLoginPageError));
    error: string

    form: FormGroup = new FormGroup({
        recoveryCode: new FormControl('', [Validators.required]),
    });

    constructor(private readonly store: Store<fromAuth.State>, route: ActivatedRoute, private readonly router: Router) {
        this.loginResponse$.subscribe(loginResponse => {
            this.loginResponse = loginResponse
        });

        this.error$.subscribe(error => {
            this.error = error;
        });

        this.actionsSubscription = route.queryParams
        .pipe(
            map(params => {
                this.redirectURL = params.redirectURL;
            })
        )
        .subscribe();
    }

    ngOnInit(): void {
        if(this.loginResponse === null || this.loginResponse === undefined) {
            this.router.navigate(['/login']);
        }
    }

    submit() {
        if (this.form.valid) {
            this.store.dispatch(new LoginPageActions.RecoverTwoFactorAuthentication(
                {
                    code: this.form.controls["recoveryCode"].value,
                    redirectURL: this.redirectURL
                }));
        }
    }
}