import { Component, Input } from '@angular/core';

@Component({
  selector: 'vt-header-actions',
  templateUrl: './header-actions.component.html',
})
export class HeaderActionsComponent {
  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() secondSubTitle: string;
}
