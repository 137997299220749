var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, exhaustMap, map, tap, mergeMap, switchMap } from 'rxjs/operators';
import { LoginPageActions, AuthenticationActions, AuthenticationApiActions, RegisterUserPageActions, ForgotPasswordPageActions, } from '@app/auth/actions';
import { AuthService } from '@app/auth/services/auth.service';
import { LogoutConfirmationDialogComponent } from '@app/auth/components/logout-confirmation-dialog.component';
import { getWelcomePage } from '@app/auth/guards/welcome-guard.service';
import { LayoutActions } from '@app/core/actions';
var AuthenticationEffects = /** @class */ (function () {
    function AuthenticationEffects(actions$, authService, router, dialog) {
        var _this = this;
        this.actions$ = actions$;
        this.authService = authService;
        this.router = router;
        this.dialog = dialog;
        this.twoFactorUrl = "/twofactor";
        this.recoveryCodeUrl = "recoveryCode";
        this.forgotPasswordRequest$ = this.actions$.pipe(ofType(ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitForgotPassword), map(function (action) { return action.payload; }), mergeMap(function (forgotPasswordRequest) {
            return _this.authService.forgotPasswordRequest(forgotPasswordRequest).pipe(map(function (response) { return new AuthenticationApiActions.ForgotPasswordRequestSuccess(response); }), catchError(function (response) { return of(new AuthenticationApiActions.ForgotPasswordRequestFailure(response.error.Errors)); }));
        }));
        this.resetPasswordRequest$ = this.actions$.pipe(ofType(ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitResetPassword), map(function (action) { return action.payload; }), mergeMap(function (resetPasswordRequest) {
            return _this.authService.resetPasswordRequest(resetPasswordRequest).pipe(switchMap(function (response) { return [
                new AuthenticationApiActions.ResetPasswordRequestSuccess(response),
                new LayoutActions.OpenSuccessSnackBar({
                    messages: ['Wachtwoord gewijzigd'],
                }),
            ]; }), tap(function () { return _this.router.navigate(['/login']); }), catchError(function (response) { return of(new AuthenticationApiActions.ResetPasswordRequestFailure(response.error.Errors)); }));
        }));
        this.error$ = this.actions$.pipe(ofType(AuthenticationApiActions.AuthenticationApiActionTypes.ResetPasswordRequestFailure), map(function (action) { return action.payload; }), mergeMap(function (errorMessages) {
            return of(new LayoutActions.OpenErrorSnackBar({ messages: errorMessages.map(function (message) { return message.Value; }) }));
        }));
        this.register$ = this.actions$.pipe(ofType(RegisterUserPageActions.RegisterUserPageActionTypes.SubmitRegisterUser), map(function (action) { return action.payload; }), mergeMap(function (registerUser) {
            return _this.authService.register(registerUser).pipe(map(function (response) { return new AuthenticationApiActions.RegisterUserSuccess(response); }), tap(function () { return _this.router.navigate(['/information']); }), catchError(function (response) { return of(new AuthenticationApiActions.RegisterUserFailure(response.error.Errors)); }));
        }));
        this.login$ = this.actions$.pipe(ofType(LoginPageActions.LoginPageActionTypes.Login), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            return _this.authService.login(data.credentials).pipe(map(function (loginResponse) { return new AuthenticationApiActions.LoginSuccess({ loginResponse: loginResponse }); }), tap(function (loginSuccess) {
                if (loginSuccess.payload.loginResponse.isTwoFactorEnabled || loginSuccess.payload.loginResponse.twoFactorQrCode != null) {
                    _this.router.navigate([_this.twoFactorUrl]);
                }
                else if (data.redirectURL) {
                    _this.router.navigate([data.redirectURL]);
                }
                else {
                    _this.router.navigate([getWelcomePage(loginSuccess.payload.loginResponse.isCustomer)]);
                }
            }), catchError(function (error) { return of(new AuthenticationApiActions.LoginFailure({ error: error })); }));
        }));
        this.twoFactorLogin$ = this.actions$.pipe(ofType(LoginPageActions.LoginPageActionTypes.TwoFactorLogin), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            return _this.authService.twoFactorLogin(data.twoFactorRequest).pipe(map(function (loginResponse) { return new AuthenticationApiActions.LoginSuccess({ loginResponse: loginResponse }); }), tap(function (loginSuccess) {
                if (loginSuccess.payload.loginResponse.twoFactorRecoveryCode != null) {
                    _this.router.navigate([_this.recoveryCodeUrl]);
                }
                else if (data.redirectURL) {
                    _this.router.navigate([data.redirectURL]);
                }
                else {
                    _this.router.navigate([getWelcomePage(loginSuccess.payload.loginResponse.isCustomer)]);
                }
            }), catchError(function (error) { return of(new AuthenticationApiActions.LoginFailure({ error: error })); }));
        }));
        this.recoverTwoFactorAuthentication$ = this.actions$.pipe(ofType(LoginPageActions.LoginPageActionTypes.RecoverTwoFactorAuthentication), map(function (action) { return action.payload; }), exhaustMap(function (data) {
            return _this.authService.recoverTwoFactor(data.code).pipe(map(function (loginResponse) { return new LoginPageActions.RecoverTwoFactorAuthenticationSuccess(loginResponse); }), tap(function (loginSuccess) {
                _this.router.navigate([data.redirectURL != null ? data.redirectURL : getWelcomePage(loginSuccess.payload.isCustomer)]);
            }), catchError(function (error) { return of(new AuthenticationApiActions.LoginFailure({ error: error })); }));
        }));
        this.sendTwoFactorAuthenticationCode$ = this.actions$.pipe(ofType(LoginPageActions.LoginPageActionTypes.SendTwoFactorActivationCode), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            return _this.authService.sendTwoFactorActivationCode(payload).pipe(map(function () { return new LoginPageActions.SendTwoFactorActivationCodeSuccess(); }), catchError(function (error) { return of(new LoginPageActions.SendTwoFactorActivationCodeFailure({ error: error })); }));
        }));
        this.loginRedirect$ = this.actions$.pipe(ofType(AuthenticationApiActions.AuthenticationApiActionTypes.LoginRedirect), map(function (action) { return action.payload; }), mergeMap(function (redirectURL) { return _this.router.navigate(['/login'], { queryParams: { redirectURL: redirectURL } }); }));
        this.logoutRedirect$ = this.actions$.pipe(ofType(AuthenticationActions.AuthenticationActionTypes.Logout), tap(function () {
            _this.router.navigate(['/login']);
        }));
        this.logoutConfirmation$ = this.actions$.pipe(ofType(AuthenticationActions.AuthenticationActionTypes.LogoutConfirmation), exhaustMap(function () {
            var dialogRef = _this.dialog.open(LogoutConfirmationDialogComponent);
            return dialogRef.afterClosed();
        }), map(function (result) { return (result ? new AuthenticationActions.Logout() : new AuthenticationActions.LogoutConfirmationDismiss()); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthenticationEffects.prototype, "forgotPasswordRequest$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthenticationEffects.prototype, "resetPasswordRequest$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthenticationEffects.prototype, "error$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthenticationEffects.prototype, "register$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "login$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "twoFactorLogin$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "recoverTwoFactorAuthentication$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "sendTwoFactorAuthenticationCode$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "loginRedirect$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "logoutRedirect$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "logoutConfirmation$", void 0);
    return AuthenticationEffects;
}());
export { AuthenticationEffects };
