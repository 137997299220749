import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, exhaustMap, map, tap, mergeMap, switchMap } from 'rxjs/operators';
import {
  LoginPageActions,
  AuthenticationActions,
  AuthenticationApiActions,
  RegisterUserPageActions,
  ForgotPasswordPageActions,
} from '@app/auth/actions';
import { Credentials } from '@app/auth/models/credentials';
import { AuthService } from '@app/auth/services/auth.service';
import { LogoutConfirmationDialogComponent } from '@app/auth/components/logout-confirmation-dialog.component';
import { getWelcomePage } from '@app/auth/guards/welcome-guard.service';
import { Action } from '@ngrx/store';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';
import { LayoutActions } from '@app/core/actions';
import { Error } from '@app/core/models/Error';
import { TwoFactorRequest } from '../models/twoFactorRequest';

@Injectable()
export class AuthenticationEffects {
  private readonly twoFactorUrl: string = "/twofactor";
  private readonly recoveryCodeUrl: string = "recoveryCode";

  @Effect()
  forgotPasswordRequest$: Observable<Action> = this.actions$.pipe(
    ofType<ForgotPasswordPageActions.SubmitForgotPasswordRequest>(
      ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitForgotPassword
    ),
    map(action => action.payload),
    mergeMap((forgotPasswordRequest: ForgotPasswordRequest) =>
      this.authService.forgotPasswordRequest(forgotPasswordRequest).pipe(
        map(response => new AuthenticationApiActions.ForgotPasswordRequestSuccess(response)),
        catchError(response => of(new AuthenticationApiActions.ForgotPasswordRequestFailure(response.error.Errors)))
      )
    )
  );

  @Effect()
  resetPasswordRequest$: Observable<Action> = this.actions$.pipe(
    ofType<ForgotPasswordPageActions.SubmitResetPasswordRequest>(
      ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitResetPassword
    ),
    map(action => action.payload),
    mergeMap((resetPasswordRequest: ResetPasswordRequest) =>
      this.authService.resetPasswordRequest(resetPasswordRequest).pipe(
        switchMap(response => [
          new AuthenticationApiActions.ResetPasswordRequestSuccess(response),
          new LayoutActions.OpenSuccessSnackBar({
            messages: ['Wachtwoord gewijzigd'],
          }),
        ]),
        tap(() => this.router.navigate(['/login'])),
        catchError(response => of(new AuthenticationApiActions.ResetPasswordRequestFailure(response.error.Errors)))
      )
    )
  );

  @Effect()
  error$: Observable<Action> = this.actions$.pipe(
    ofType<AuthenticationApiActions.ResetPasswordRequestFailure>(AuthenticationApiActions.AuthenticationApiActionTypes.ResetPasswordRequestFailure),
    map(action => action.payload),
    mergeMap((errorMessages: Error[]) => {
      return of(new LayoutActions.OpenErrorSnackBar({ messages: errorMessages.map(message => message.Value) }));
    })
  );

  @Effect()
  register$: Observable<Action> = this.actions$.pipe(
    ofType<RegisterUserPageActions.SubmitRegisterUser>(
      RegisterUserPageActions.RegisterUserPageActionTypes.SubmitRegisterUser
    ),
    map(action => action.payload),
    mergeMap((registerUser: RegisterUser) =>
      this.authService.register(registerUser).pipe(
        map(response => new AuthenticationApiActions.RegisterUserSuccess(response)),
        tap(() => this.router.navigate(['/information'])),
        catchError(response => of(new AuthenticationApiActions.RegisterUserFailure(response.error.Errors)))
      )
    )
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType<LoginPageActions.Login>(LoginPageActions.LoginPageActionTypes.Login),
    map(action => action.payload),
    exhaustMap((data: { credentials: Credentials, redirectURL: string | null }) =>
      this.authService.login(data.credentials).pipe(
        map(loginResponse => new AuthenticationApiActions.LoginSuccess({ loginResponse })),
        tap(loginSuccess => {
          if(loginSuccess.payload.loginResponse.isTwoFactorEnabled || loginSuccess.payload.loginResponse.twoFactorQrCode != null) {
            this.router.navigate([this.twoFactorUrl]);
          }
          else if (data.redirectURL) {
            this.router.navigate([data.redirectURL]);
          } 
          else {
            this.router.navigate([getWelcomePage(loginSuccess.payload.loginResponse.isCustomer)]);
          }
        }),
        catchError(error => of(new AuthenticationApiActions.LoginFailure({ error })))
      )
    )
  );

  @Effect()
  twoFactorLogin$ = this.actions$.pipe(
    ofType<LoginPageActions.TwoFactorLogin>(LoginPageActions.LoginPageActionTypes.TwoFactorLogin),
    map(action => action.payload),
    exhaustMap((data: { twoFactorRequest: TwoFactorRequest, redirectURL: string | null }) =>
      this.authService.twoFactorLogin(data.twoFactorRequest).pipe(
        map(loginResponse => new AuthenticationApiActions.LoginSuccess({ loginResponse })),
        tap(loginSuccess => {
          if(loginSuccess.payload.loginResponse.twoFactorRecoveryCode != null) {
            this.router.navigate([this.recoveryCodeUrl]);
          }
          else if (data.redirectURL) {
            this.router.navigate([data.redirectURL]);
          } 
          else {
            this.router.navigate([getWelcomePage(loginSuccess.payload.loginResponse.isCustomer)]);
          }
        }),
        catchError(error => of(new AuthenticationApiActions.LoginFailure({ error })))
      )
    )
  );

  @Effect()
  recoverTwoFactorAuthentication$ = this.actions$.pipe(
    ofType<LoginPageActions.RecoverTwoFactorAuthentication>(LoginPageActions.LoginPageActionTypes.RecoverTwoFactorAuthentication),
    map(action => action.payload),
    exhaustMap((data: { code: string, redirectURL: string | null }) =>
      this.authService.recoverTwoFactor(data.code).pipe(
        map(loginResponse => new LoginPageActions.RecoverTwoFactorAuthenticationSuccess(loginResponse)),        
        tap(loginSuccess => {
          this.router.navigate([data.redirectURL != null ? data.redirectURL : getWelcomePage(loginSuccess.payload.isCustomer)]);
        }),
        catchError(error => of(new AuthenticationApiActions.LoginFailure({ error })))
      )
    )
  );

  @Effect()
  sendTwoFactorAuthenticationCode$ = this.actions$.pipe(
    ofType<LoginPageActions.SendTwoFactorActivationCode>(LoginPageActions.LoginPageActionTypes.SendTwoFactorActivationCode),
    map(action => action.payload),
    exhaustMap((payload: TwoFactorRequest) =>
      this.authService.sendTwoFactorActivationCode(payload).pipe(
        map(() => new LoginPageActions.SendTwoFactorActivationCodeSuccess()),
        catchError(error => of(new LoginPageActions.SendTwoFactorActivationCodeFailure({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType<AuthenticationApiActions.LoginRedirect>(AuthenticationApiActions.AuthenticationApiActionTypes.LoginRedirect),
    map(action => action.payload),
    mergeMap((redirectURL: string) => this.router.navigate(['/login'], { queryParams: { redirectURL } }))
  );

  @Effect({ dispatch: false })
  logoutRedirect$ = this.actions$.pipe(
    ofType(AuthenticationActions.AuthenticationActionTypes.Logout),
    tap(() => {
      this.router.navigate(['/login']);
    })
  );

  @Effect()
  logoutConfirmation$ = this.actions$.pipe(
    ofType(AuthenticationActions.AuthenticationActionTypes.LogoutConfirmation),
    exhaustMap(() => {
      const dialogRef = this.dialog.open<LogoutConfirmationDialogComponent, undefined, boolean>(
        LogoutConfirmationDialogComponent
      );

      return dialogRef.afterClosed();
    }),
    map(result => (result ? new AuthenticationActions.Logout() : new AuthenticationActions.LogoutConfirmationDismiss()))
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}
}
