import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { AppConfigService } from '@app/core/services/app-config-service';

@Injectable()
export class AppInsightService {
  private readonly config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: AppConfigService.config.ApplicationInsights.InstrumentationKey,
  };
  constructor() {
    if (!!this.config.instrumentationKey && AppInsights.downloadAndSetup !== undefined) {
      AppInsights.downloadAndSetup(this.config);
    }
  }
  logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }
  logEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }
  logException(exception: Error, handledAt?: string, properties?: any, measurements?: any) {
    AppInsights.trackException(exception, handledAt, properties, measurements);
  }
  logTrace(message: string, properties?: any, severityLevel?: any) {
    AppInsights.trackTrace(message, properties, severityLevel);
  }
}
