import { Role } from '@app/core/models/Role';

export interface User {
  id: string;
  email: string;
  password: string;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  phoneNumber: string;
  roles: Role[];
  customerId: string;  
  userAfas: string;
  active: boolean;
  requiresTwoFactorAuthentication: boolean;
}

export function getRoleString(user: User): string {
  return user.roles.length > 0 ? user.roles[0].description : "";
}

export function getFullName(user: User): string {
  return user.lastNamePrefix
    ? `${user.firstName} ${user.lastNamePrefix} ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;
}

