/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/loader/app-loader.component.ngfactory";
import * as i2 from "../components/loader/app-loader.component";
import * as i3 from "@angular/common";
import * as i4 from "../components/layout/layout.component.ngfactory";
import * as i5 from "../components/layout/layout.component";
import * as i6 from "../components/toolbar/toolbar.component.ngfactory";
import * as i7 from "../components/toolbar/toolbar.component";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/router";
import * as i10 from "../components/footer/footer.component.ngfactory";
import * as i11 from "../components/footer/footer.component";
import * as i12 from "./app.component";
import * as i13 from "@angular/material/snack-bar";
import * as i14 from "../services/application-insights";
import * as i15 from "../services/loader.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-loader", [], null, null, null, i1.View_AppLoaderComponent_0, i1.RenderType_AppLoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.AppLoaderComponent, [], { coverHeader: [0, "coverHeader"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.coverHeader)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "vt-layout", [], null, null, null, i4.View_LayoutComponent_0, i4.RenderType_LayoutComponent)), i0.ɵdid(1, 49152, null, 0, i5.LayoutComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "vt-toolbar", [], null, [[null, "openHelp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openHelp" === en)) {
        var pd_0 = (_co.onOpenHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolbarComponent_0, i6.RenderType_ToolbarComponent)), i0.ɵdid(3, 49152, null, 0, i7.ToolbarComponent, [i8.Store], null, { openHelp: "openHelp" }), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_AppComponent_1)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(8, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(9, 0, null, 0, 1, "vt-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i0.ɵdid(10, 49152, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.showLoader)); _ck(_v, 5, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i12.AppComponent, [i13.MatSnackBar, i8.Store, i14.AppInsightService, i15.LoaderService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("vt-app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
