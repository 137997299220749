import { Action } from '@ngrx/store';

export enum FinalizeOrderOperationsActionTypes {
  FinalizeFile = '[Finalize] Finalize File',
  FinalizeFileQR = '[Finalize] Finalize File QR',
  FinalizeMilling = '[Finalize] Finalize Milling',
  FinalizeAluminumMilling = '[Finalize] Finalize Aluminum Milling',
  FinalizeAluminumMillingCaps = '[Finalize] Finalize Aluminum Head Milling',
  FinalizeAluminumClosingPlate = '[Finalize] Finalize Aluminum Closing Plate',
  FinalizeStegherrClosingPlate = '[Finalize] Finalize Stegherr Closing Plate',
  FinalizeAluminumHeadworking = '[Finalize] Finalize Aluminum Headworking',
  FinalizeClosingPlate = '[Finalize] Finalize Closing Plate',
  FinalizeHeadworking = '[Finalize] Finalize Headworking',
  FinalizeAssembly = '[Finalize] Finalize Assembly',
  PrintAssembly = '[Finalize] Print Assembly',
  ApproveAssembly = '[Finalize] Approve Assembly',
  DisapproveAssembly = '[Finalize] Disapprove Assembly',
  FinalizeSlantedCutting = '[Finalize] Finalize Slanted Cutting',
  FinalizeExtraKeys = '[Finalize] Finalize Extra Keys',
  FinalizeCylinderAssembly = '[Finalize] Finalize Cylinder Assembly',
  FinalizeWaterChannel = '[Finalize] Finalize Water Channel',
  FinalizeTryma = '[Finalize] Finalize Tryma',
  FinalizeClusteredOrderOperation = '[Finalize] Finalize Clustered Order Operation',
}

export class FinalizeFile implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeFile;

  constructor(public payload: [string, boolean]) {}
}

export class FinalizeFileQR implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeFileQR;

  constructor(public payload: string) {}
}

export class FinalizeMilling implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeMilling;

  constructor(public payload: string) {}
}

export class FinalizeAluminumMilling implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeAluminumMilling;

  constructor(public payload: string) {}
}

export class FinalizeAluminumMillingCaps implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeAluminumMillingCaps;

  constructor(public payload: string) {}
}

export class FinalizeAluminumClosingPlate implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeAluminumClosingPlate;

  constructor(public payload: string) {}
}

export class FinalizeStegherrClosingPlate implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeStegherrClosingPlate;

  constructor(public payload: string) {}
}

export class FinalizeAluminumHeadworking implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeAluminumHeadworking;

  constructor(public payload: string) {}
}

export class FinalizeClosingPlate implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeClosingPlate;

  constructor(public payload: string) {}
}

export class FinalizeHeadworking implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeHeadworking;

  constructor(public payload: string) {}
}

export class FinalizeAssembly implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeAssembly;

  constructor(public id: string, public headerId: string, public index: string, public amount: number) {}
}

export class PrintAssembly implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.PrintAssembly;

  constructor(public id: string, public headerId: string, public index: string, public amount: number) {}
}

export class ApproveAssembly implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.ApproveAssembly;

  constructor(public id: string, public headerId: string) {}
}

export class DisapproveAssembly implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.DisapproveAssembly;

  constructor(public id: string, public headerId: string, public reason: string) {}
}

export class FinalizeSlantedCutting implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeSlantedCutting;

  constructor(public payload: string) {}
}

export class FinalizeExtraKeys implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeExtraKeys;

  constructor(public payload: string) {}
}

export class FinalizeCylinderAssembly implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeCylinderAssembly;

  constructor(public payload: string) {}
}

export class FinalizeWaterChannel implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeWaterChannel;
  
  constructor(public payload: string) {}
}

export class FinalizeTryma implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeTryma;

  constructor(public payload: string) {}
}

export class FinalizeClusteredOrderOperation implements Action {
  readonly type = FinalizeOrderOperationsActionTypes.FinalizeClusteredOrderOperation;

  constructor(public payload: string) {}
}


export type FinalizeOrderOperationsActionsUnion =
  | FinalizeFile
  | FinalizeFileQR
  | FinalizeMilling
  | FinalizeAluminumMilling
  | FinalizeAluminumMillingCaps
  | FinalizeAluminumClosingPlate
  | FinalizeStegherrClosingPlate
  | FinalizeAluminumHeadworking
  | FinalizeClosingPlate
  | FinalizeHeadworking
  | FinalizeSlantedCutting
  | ApproveAssembly
  | DisapproveAssembly
  | FinalizeExtraKeys
  | FinalizeCylinderAssembly
  | FinalizeWaterChannel
  | FinalizeTryma
  | FinalizeClusteredOrderOperation
  ;
