import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'vt-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
})

export class InformationComponent implements OnInit {
    ngOnInit() {}
}
