<form [formGroup]="form" (ngSubmit)="submit()">
    <h3>Two Factor Authenticatie</h3>

    <div *ngIf="loginResponse != null && loginResponse?.twoFactorQrCode != null" class="setup-block">
        <div>
            <p>
                <span>Om uw account extra te beveiligen is het nodig om two factor authenticatie in te stellen.</span>
                <span>Kies hieronder de methode waarmee uw two factor authenticatie kan worden ingesteld.</span>
            </p>

            <mat-form-field>
                <mat-select placeholder="Kies methode" formControlName="twoFactorMethod">
                    <mat-option *ngFor="let twoFactorMethodOption of twoFactorMethodOptions"
                        [value]="twoFactorMethodOption.key">
                        {{ twoFactorMethodOption.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="twoFactorMethod != null && twoFactorMethod == twoFactormethods.App">
            <p>
                <span>Download de Google- of Microsoft authenticator app met uw telefoon.</span>
                <span>Scan vervolgens de QR-code met de app of vul de onderstaande code in.</span>
                <span>Vul vervolgens de code die u in de app ziet in, in het code veld en druk op bevestigen.</span>
            </p>

            <img alt="image" [src]="loginResponse.twoFactorQrCode" />

            <p>{{ loginResponse.twoFactorManualCode }}</p>
        </div>

        <div *ngIf="twoFactorMethod != null && twoFactorMethod == twoFactormethods.Mail" class="mail-button">
            <p>
                <span>Druk op de versturen knop om een mail te ontvangen met de activatie code.</span>
                <span>Vul vervolgens de ontvangen code in, in het code veld en druk op bevestigen.</span>
            </p>

            <button class="login-button" mat-stroked-button [disabled]="twoFactorMailSent"
                (click)="sendActivationMail()" mat-button> 
                <p *ngIf="!twoFactorMailSent">Verstuur activatie mail </p>
                <p *ngIf="twoFactorMailSent">Activatie mail verzonden</p>
            </button>
        </div>
    </div>

    <div *ngIf="twoFactorMethod != null || loginResponse?.isTwoFactorEnabled">
        <p *ngIf="loginResponse?.isTwoFactorEnabled">
            <span>Open de gekoppelde authenticator app of email en vul de code in:</span>
        </p>

        <mat-form-field>
            <input type="text" matInput [placeholder]="errorMessage ? errorMessage : placeholder" formControlName="code" />
        </mat-form-field>

        <a *ngIf="loginResponse?.isTwoFactorEnabled" [routerLink]="recoverUrl">Herstel code gebruiken</a>
        <div class="login-checkbox" *ngIf="loginResponse?.isTwoFactorEnabled">
            <label class="login-label">Onthoudt me</label>
            <mat-checkbox formControlName="rememberMe"></mat-checkbox>
        </div>
    </div>

    <div class="login-buttons">
        <a class="login-button" mat-flat-button color="primary" [routerLink]="['/login']">Annuleren</a>
        <button class="login-button" mat-stroked-button color="accent" type="submit" mat-button>
            Bevestigen
        </button>
    </div>
</form>