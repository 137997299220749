import { TemplateRef, ViewContainerRef } from '@angular/core';
import { hasClaims, getClaims } from '@app/core/models/Claims/ClaimFunctions';
import { Claim } from '@app/core/models/Claims/Claim';
var HasClaimsDirective = /** @class */ (function () {
    function HasClaimsDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(HasClaimsDirective.prototype, "hasClaims", {
        set: function (claim) {
            var condition = hasClaims(getClaims(claim.claimType, claim.claimActions));
            if (!condition) {
                this.viewContainer.clear();
            }
            else if (condition) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    return HasClaimsDirective;
}());
export { HasClaimsDirective };
