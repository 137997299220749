import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, flatMap, mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { TokenService } from '@app/auth/services/token.service';
import { environment } from '@src/environments/environment';
import { VTRequestOptions } from '@app/core/services/http/vt.request.options';
import { RequestOptions } from '@app/core/models/RequestOptions';
import { AppConfigService } from '@app/core/services/app-config-service';

@Injectable()
export class HttpService extends HttpClient {
  /**
   * The amount of XHR requests created.
   */
  private xhrCreations = 0;
  /**
   * The amount of XHR requests resolved.
   */
  private xhrResolutions = 0;

  /**
   * The token refresh promise.
   */
  private tokenRefreshPromise: null | Promise<Object>;

  /**
   * Initializes a new instance of the HttpService class.
   * @param {handler} HttpHandler The HttpHandler.
   * @param {VTRequestOptions} defaultOptions The default request options.
   * @param {TokenService} tokenService The token service.
   */
  constructor(
    handler: HttpHandler,
    private readonly defaultOptions: VTRequestOptions,
    private readonly tokenService: TokenService
  ) {
    super(handler);
  }

  /**
   * Performs a request with `get` http method.
   * @param {string} url The url.
   * @param {RequestOptions|undefined} options The request options.
   */
  get<T>(url: string, options?: RequestOptions): Observable<T> {
    return this.executeRequest<T>(() => {
      return this.pipeResponse<T>(
        super.get<T>(this.getFullUrl(url), this.getRequestOptions(options))
      );
    }, options);
  }

  /**
   * Performs a request with `put` http method.
   * @param {string} url The url.
   * @param {RequestOptions|undefined} options The request options.
   */
  put<T>(url: string, body: any, options?: RequestOptions): Observable<T> {
    return this.executeRequest<T>(() => {
      return this.pipeResponse<T>(
        super.put<T>(
          this.getFullUrl(url),
          this.serialize(body),
          this.getRequestOptions(options)
        )
      );
    }, options);
  }

  /**
   * Performs a request with `post` http method.
   * @param {string} url The url.
   * @param {RequestOptions|undefined} options The request options.
   */
  post<T>(
    url: string,
    body: any = {},
    options?: RequestOptions
  ): Observable<T> {
    return this.executeRequest<T>(() => {
      return this.pipeResponse<T>(
        super.post<T>(
          (AppConfigService.config.App.Environment.toLowerCase() === 'lokaal' && options !== undefined && options.withCredentials) ? url : this.getFullUrl(url),
          this.serialize(body),
          this.getRequestOptions(options)
        )
      );
    }, options);
  }

  /**
   * Performs a request with `delete` http method.
   * @param {string} url The url.
   * @param {RequestOptions|undefined} options The request options.
   */
  delete<T>(url: string, options?: RequestOptions): Observable<T> {
    return this.executeRequest<T>(() => {
      return this.pipeResponse<T>(
        super.delete<T>(this.getFullUrl(url), this.getRequestOptions(options))
      );
    }, options);
  }

  /**
   * Applies the onSuccess, onError and onComplete methods to the response.
   * @param {Observable<T>} response The response.
   */
  private pipeResponse<T>(response: Observable<T>): Observable<T> {
    this.xhrCreations++;
    return response.pipe(
      catchError(this.onCatch),
      tap((res: T) => this.onSuccess(res), (error: any) => this.onError(error)),
      finalize(() => this.onComplete())
    );
  }

  /**
   * Executes the request.
   * @param {Observable<Response>} request The request.
   * @param {RequestOptions} options The request options.
   */
  private executeRequest<T>(
    request: () => Observable<T>,
    options?: RequestOptions
  ): Observable<T> {
    if (
      this.tokenService.hasToken() &&
      this.tokenService.shouldRefreshToken() &&
      AppConfigService.config
    ) {
      return this.requestRefreshToken(options).pipe(
        tap((response: any) => {
          this.tokenRefreshPromise = null;
          this.tokenService.setToken(
            response.accessToken,
            response.refreshToken,
            response.claims,
            response.features,
            response.isCustomer,
            response.versionNumber
          );
        }),
        flatMap(() => request()),
        catchError(this.onCatch)
      );
    }

    return request().pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Request the refresh token.
   * @param {RequestOptions|undefined} options The request options.
   */
  private requestRefreshToken(options?: RequestOptions): Observable<Object> {
    if (this.tokenRefreshPromise == null) {
      this.tokenRefreshPromise = this.pipeResponse(
        super.post(
          this.getFullUrl(AppConfigService.config.Api.RefreshTokenUrl),
          this.tokenService.getTokenObject(),
          this.getRequestOptions(options)
        )
      ).toPromise();
    }

    return of(this.tokenRefreshPromise).pipe(
      mergeMap(promise => {
        return promise.then(value => value);
      })
    );
  }

  /**
   * Gets the request options.
   * @param {RequestOptions|undefined} options The request options.
   */
  private getRequestOptions(options?: RequestOptions): RequestOptions {
    return this.setAuthorizationHeader({ ...this.defaultOptions, ...options });
  }

  /**
   * Sets the authorization header.
   * @param {RequestOptions} options The request options.
   */
  private setAuthorizationHeader(options: RequestOptions): RequestOptions {
    return {
      ...options,
      ...{
        headers: {
          ...options.headers,
          ...{
            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
          },
        },
      },
    };
  }

  /**
   * Perform actions when the request is successful.
   * @param {Response} response The response.
   */
  private onSuccess(response: any): any {}

  /**
   * Perform actions when the request has errored.
   * @param {Response} response The error response.
   */
  private onError(response: Response): void {
    console.error('Request error => ', response);
  }

  /**
   * Perform actions when the request has errored.
   * @param {any} error The error.
   * @param {Observable<any>} caught The error observable.
   */
  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return throwError(error);
  }

  /**
   * Perform actions when the request is completed.
   */
  private onComplete(): void {
    this.xhrResolutions++;
  }

  /**
   * Serialize the provided data.
   * @param {any} data The provided data.
   */
  private serialize(data: any): string {
    return JSON.stringify(data);
  }

  /**
   * Get the full url.
   * @param {string} url The request url.
   */
  private getFullUrl(url: string): string {
    return this.isExternalEndpoint(url)
      ? url
      : `${AppConfigService.config.Api.BaseUrl}/${url}`;
  }

  /**
   * Whether or not the provided url is an external endpoint.
   * @param {string} url The request url
   */
  private isExternalEndpoint(url: string): boolean {
    const settings = environment.externalEndpoints;
    return settings.fullMatch
      ? settings.urls.some((x: string) => x === url)
      : settings.urls.some((x: string) => url.includes(x));
  }
}
