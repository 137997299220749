import { TemplateRef, ViewContainerRef } from '@angular/core';
import { hasFeature } from '@app/core/functions/feature-flag.functions';
var HasNotFeatureDirective = /** @class */ (function () {
    function HasNotFeatureDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(HasNotFeatureDirective.prototype, "hasNotFeature", {
        /**
         * Validate if feature flag is disabled
         * @returns {string} The feature.
         */
        set: function (feature) {
            var condition = hasFeature(feature);
            if (condition) {
                this.viewContainer.clear();
            }
            else {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    return HasNotFeatureDirective;
}());
export { HasNotFeatureDirective };
