import { Component, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { checkBase64Tag } from '@app/core/components/base64-image-display/base64-image-display.component';
import { UploadXmlRequest } from '@app/orders/models/upload-xml-request';

@Component({
  selector: 'vt-base64-xml-upload',
  templateUrl: './base64-xml-upload.component.html',
  styleUrls: ['./base64-xml-upload.component.scss'],
})
export class Base64XmlUploadComponent {
  @Input() orderHeaderReferenceId: string;
  @Output() xmlChange = new EventEmitter<UploadXmlRequest>();

  @ViewChild('xmlFileInput')
  xmlFileInput: ElementRef;
  fileName = "";

  changeXml(fileInput: any) {
    if (fileInput.target.files.length === 1) {
      const file: File = fileInput.target.files[0];
      this.fileName = file.name;
      const fileReader: FileReader = new FileReader();

      fileReader.onloadend = (e: any) => {
        const xml = e.target.result;
        if (xml && checkBase64Tag(xml.toString())) {
          this.updateXml(xml.toString(), file.name);
        }
      };
      fileReader.readAsDataURL(file);
    }
  }

  updateXml(xml: string, fileName: string) {
    this.xmlChange.emit({fileName, content: xml, orderHeaderReferenceId: this.orderHeaderReferenceId});
  }
}
