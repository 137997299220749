export class MessageDialogParameters {
    header: string;
    message: string;
    buttonType: ButtonType;
}

export enum ButtonType {
    Ok,
    YesNo,
}
