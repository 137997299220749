import { AbstractControl, ValidationErrors, FormControl, FormGroupDirective, NgForm } from '@angular/forms';

/**
 * Validator to check if the controls are provided and equal.
 * @param {AbstractControl} control1 The first control.
 * @param {AbstractControl} control2 The second control.
 * @returns {ValidationErrors | null} Error object.
 */
export function equalControlsValidator(control1: AbstractControl | null, control2: AbstractControl | null): ValidationErrors | null {
  if (control1 && control2) {
    if (control1.value !== '' && control2.value !== '' && control1.value !== control2.value) {
      control1.setErrors({ mismatch: true });
      control2.setErrors({ mismatch: true });
    } else {
      if (control1.hasError('mismatch')) {
        control1.setErrors({ mismatch: null });
        control1.updateValueAndValidity();
      }

      if (control2.hasError('mismatch')) {
        control2.setErrors({ mismatch: null });
        control2.updateValueAndValidity();
      }
    }
  }

  return null;
}
