import { Action } from '@ngrx/store';
import { Error } from '@app/core/models/Error';
import { ProductWizardOutputData } from '@app/product-wizard/models/product-wizard-output-data';
import { OrderDetailFeedback } from '@app/orders/models/order-feedback';

export enum SelectedOrderActionTypes {
  LoadOrderDetailFeedback = '[Wizard / Selected Order] Load Order Detail Feedback',
  LoadOrderDetailFeedbackSuccess = '[Wizard / Selected Order] Load Order Detail Feedback Success',
  LoadOrderDetailFeedbackFailure = '[Wizard / Selected Order] Load Order Detail Feedback Failure',
  LoadOrderDetailWizardOutput = '[Wizard / Selected Order] Load Load Order Detail Wizard Output',
  LoadOrderDetailWizardOutputFailure = '[Wizard / Selected Order] Load Order Detail Wizard Output Failure',
  LoadOrderDetailWizardOutputSuccess = '[Wizard / Selected Order] Load Order Detail Wizard Output Success',
}

export class LoadOrderDetailFeedback implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailFeedback;
}

export class LoadOrderDetailFeedbackSuccess implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailFeedbackSuccess;

  constructor(public payload: OrderDetailFeedback) {}
}

export class LoadOrderDetailFeedbackFailure implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailFeedbackFailure;
  constructor(public payload: Error[]) {}
}

export class LoadOrderDetailWizardOutput implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailWizardOutput;
  constructor(public orderDetailId: string) {}
}

export class LoadOrderDetailWizardOutputSuccess implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailWizardOutputSuccess;

  constructor(public payload: ProductWizardOutputData) {}
}

export class LoadOrderDetailWizardOutputFailure implements Action {
  readonly type = SelectedOrderActionTypes.LoadOrderDetailWizardOutputFailure;

  constructor(public payload: Error[]) {}
}

export type SelectedOrderActionsUnion =
  | LoadOrderDetailFeedback
  | LoadOrderDetailFeedbackSuccess
  | LoadOrderDetailFeedbackFailure
  | LoadOrderDetailWizardOutput
  | LoadOrderDetailWizardOutputSuccess
  | LoadOrderDetailWizardOutputFailure;
