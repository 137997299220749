import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { RegisterUserPageActions } from '@app/auth/actions';
import { RegisterUser } from '@app/auth/models/registerUser';
import * as fromAuth from '@app/auth/reducers';

@Component({
    selector: 'vt-register-user-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './register-user-page.component.html',
})
export class RegisterUserPageComponent {
    errors$ = this.store.pipe(select(fromAuth.getRegisterUserPageErrors));

    constructor(private readonly store: Store<fromAuth.State>) {}

    onSubmit(registerUser: RegisterUser) {
        this.store.dispatch(new RegisterUserPageActions.SubmitRegisterUser(registerUser));
    }
}
