import { of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '@app/auth/services/token.service';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, tokenService) {
        this.http = http;
        this.tokenService = tokenService;
        this.endpoints = {
            register: 'user/register',
            forgotPassword: 'account/forgotpassword',
            loginCheck: 'account/authenticate',
            twoFactorLogin: 'account/twofactor',
            getUser: 'user/getcurrentuser',
            resetPassword: 'account/resetpassword',
            sendTwoFactorActivationCode: 'account/activationCode',
            recoverTwoFactor: 'account/recover'
        };
    }
    AuthService.prototype.register = function (registerUser) {
        return this.http.post("" + this.endpoints.register, registerUser);
    };
    AuthService.prototype.forgotPasswordRequest = function (forgotPasswordRequest) {
        return this.http.post("" + this.endpoints.forgotPassword, forgotPasswordRequest);
    };
    AuthService.prototype.resetPasswordRequest = function (resetPasswordRequest) {
        return this.http.post("" + this.endpoints.resetPassword, resetPasswordRequest);
    };
    AuthService.prototype.login = function (credentials) {
        var _this = this;
        //Always clear the old token before requesting a new one
        if (this.tokenService.hasToken()) {
            this.tokenService.removeToken();
        }
        return this.http.post(this.endpoints.loginCheck, credentials, { withCredentials: true }).pipe(map(function (response) {
            if (!response.isTwoFactorEnabled && response.twoFactorQrCode == null) {
                _this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims, response.features, response.isCustomer, response.versionNumber);
            }
            return response;
        }), catchError(function () {
            return throwError('Ongeldig e-mailadres en/of wachtwoord.');
        }));
    };
    AuthService.prototype.twoFactorLogin = function (twoFactorRequest) {
        var _this = this;
        return this.http.post(this.endpoints.twoFactorLogin, twoFactorRequest, { withCredentials: true }).pipe(map(function (response) {
            _this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims, response.features, response.isCustomer, response.versionNumber);
            return response;
        }), catchError(function () {
            return throwError('De authenticatie code is ongeldig.');
        }));
    };
    AuthService.prototype.sendTwoFactorActivationCode = function (twoFactorRequest) {
        return this.http.post(this.endpoints.sendTwoFactorActivationCode, twoFactorRequest);
    };
    AuthService.prototype.retrieveCurrentUser = function () {
        return this.http.get(this.endpoints.getUser).pipe(map(function (user) { return user; }));
    };
    AuthService.prototype.recoverTwoFactor = function (code) {
        var _this = this;
        return this.http.post(this.endpoints.recoverTwoFactor, code, { withCredentials: true }).pipe(map(function (response) {
            _this.tokenService.setToken(response.accessToken, response.refreshToken, response.claims, response.features, response.isCustomer, response.versionNumber);
            return response;
        }), catchError(function () {
            return throwError('De herstel code is ongeldig.');
        }));
    };
    AuthService.prototype.logout = function () {
        this.tokenService.removeToken();
        return of(true);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.TokenService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
