/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/common";
import * as i9 from "./message-dialog.component";
var styles_MessageDialogComponent = [i0.styles];
var RenderType_MessageDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageDialogComponent, data: {} });
export { RenderType_MessageDialogComponent as RenderType_MessageDialogComponent };
function View_MessageDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { var currVal_3 = true; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 4).ariaLabel || null); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MessageDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ja"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Nee"]))], function (_ck, _v) { var currVal_3 = true; _ck(_v, 4, 0, currVal_3); var currVal_7 = false; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 4).ariaLabel || null); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 7).disabled || null); var currVal_5 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 8).ariaLabel || null); _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_MessageDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageDialogComponent_2)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _co.isOk(); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.isYesNo(); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.header; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_MessageDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MessageDialogComponent_0, RenderType_MessageDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.MessageDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], null, null); }
var MessageDialogComponentNgFactory = i1.ɵccf("ng-component", i9.MessageDialogComponent, View_MessageDialogComponent_Host_0, {}, {}, []);
export { MessageDialogComponentNgFactory as MessageDialogComponentNgFactory };
