import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'vt-styled-authorization-page',
  templateUrl: './styled-authorization-page.component.html',
  styleUrls: ['./styled-authorization-page.component.scss']
})
export class StyledAuthorizationPageComponent implements OnInit {

  @Input() content: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }
}
