import { SafeResourceUrl } from '@angular/platform-browser';
import { Filter } from '@app/core/models/Filters/Filter';
import { OverviewAssemblyQr } from '@app/order-operations/models/overviewAssemblyQr';
import { Action } from '@ngrx/store';
import { OrderOperationRequest } from '../models/order-operation/order-operation-request';
import { OrderType } from '@app/core/enums/order-type.enum';

export enum FindOrderOperationsActionTypes {
  SetOrderOperationFilter = '[Find Order Operations] Set Order Operation Filter',
  SetOrderOperationRequest = '[Find Order Operations] Set Order Operation Request',
  SetOrderOperationAssemblyFilter = '[Find Order Operations] Set Order Operation Assembly Filter',
  LoadOrderOperationFiles = '[Find Order Operations] Load Order Operation Files',
  CountOrderOperationFiles = '[Find Order Operations] Count Order Operation Files',
  LoadOrderOperationMillings = '[Find Order Operations] Load Order Operation Millings',
  CountOrderOperationMillings = '[Find Order Operations] Count Order Operation Millings',
  LoadOrderOperationAluminumMillings = '[Find Order Operations] Load Order Operation Aluminum Millings',
  CountOrderOperationAluminumMillings = '[Find Order Operations] Count Order Operation Aluminum Millings',
  LoadOrderOperationAluminumMillingCaps = '[Find Order Operations] Load Order Operation Aluminum Milling Caps',
  CountOrderOperationAluminumMillingCaps = '[Find Order Operations] Count Order Operation Aluminum Milling Caps',
  LoadOrderOperationAluminumClosingPlates = '[Find Order Operations] Load Order Operation Aluminum Closing Plates',
  CountOrderOperationAluminumClosingPlates = '[Find Order Operations] Count Order Operation Aluminum Closing Plates',
  LoadOrderOperationStegherrClosingPlates = '[Find Order Operations] Load Order Operation Stegherr Closing Plates',
  CountOrderOperationStegherrClosingPlates = '[Find Order Operations] Count Order Operation Stegherr Closing Plates',
  LoadOrderOperationAluminumHeadworkings = '[Find Order Operations] Load Order Operation Aluminum Headworkings',
  CountOrderOperationAluminumHeadworkings = '[Find Order Operations] Count Order Operation Aluminum Headworkings',
  LoadOrderOperationClosingPlates = '[Find Order Operations] Load Order Operation Closing Plates',
  CountOrderOperationClosingPlates = '[Find Order Operations] Count Order Operation Closing Plates',
  LoadOrderOperationHeadworkings = '[Find Order Operations] Load Order Operation Headworkings',
  CountOrderOperationHeadworkings = '[Find Order Operations] Count Order Operation Headworkings',
  LoadOrderOperationAssemblies = '[Find Order Operations] Load Order Operation Assemblies',
  LoadOrderOperationCylinderAssemblies = '[Find Order Operations] Load Order Operation Cylinder Assemblies',
  LoadOrderOperationAssemblyOverview = '[Find Order Operations] Load Order Operation Assembly Overview',
  LoadOrderOperationApproveAssemblies = '[Find Order Operations] Load Order Operation Approve Assemblies',
  LoadOrderOperationApproveAssemblyOverview = '[Find Order Operations] Load Order Operation Approve Assembly Overview',
  CountOrderOperationApproveAssemblyOverview = '[Find Order Operations] Count Order Operation Approve Assembly Overview',
  CountOrderOperationAssemblies = '[Find Order Operations] Count Order Operation Assemblies',
  LoadOrderOperationSlantedCuttings = '[Find Order Operations] Load Order Operation Slanted Cuttings',
  CountOrderOperationSlantedCuttings = '[Find Order Operations] Count Order Operation Slanted Cuttings',
  OrderOperationAssembliesExist = '[Find Order Operations] Order Operation Assemblies Exist',
  OrderOperationApproveAssembliesExist = '[Find Order Operations] Order Operation Approve Assemblies Exist',
  ExportOrderOperation = '[Find Order Operations] Export Order Operation',
  OpenMillingPreviewDialog = '[Find Order Operations] Open Milling Preview Dialog',
  CloseMillingPreviewDialog = '[Find Order Operations] Close Milling Preview Dialog',
  OpenScannedOrder = '[Find Order Operations] Open Scanned Order',
  CheckAssemblyStatus = '[Find Order Operations] Check Assembly Status',
  CheckAssemblyStatusSuccess = '[Find Order Operations] Check Assembly Status Success',
  CheckAssemblyStatusFailure = '[Find Order Operations] Check Assembly Status Failure',
  CheckAssemblyReadyForAssemblyFailed = '[Find Order Operations] Check Assembly Ready For Assembly Failed',
  ClearQrInfo = '[Find Order Operations] Clear Qr Info',
  LoadOrderOperationExtraKeys = '[Find Order Operations] Load Order Operation Extra Keys',
  CountOrderOperationExtraKeys = '[Find Order Operations] Count Order Operation Extra Keys',
  LoadOrderOperationWaterChannel = '[Find Order Operations] Load Order Operation Water Channel',
  CountOrderOperationWaterChannel = '[Find Order Operations] Count Order Operation Water Channel',
  LoadOrderOperationTrymaOverview = '[Find Order Operations] Load Order Operation Tryma Overview',
  CountOrderOperationTrymaOverview = '[Find Order Operations] Count Order Operation Tryma Overview'
}

export class SetOrderOperationFilter implements Action {
  readonly type = FindOrderOperationsActionTypes.SetOrderOperationFilter;

  constructor(public payload: Filter) {}
}

export class SetOrderOperationRequest implements Action {
  readonly type = FindOrderOperationsActionTypes.SetOrderOperationRequest;

  constructor(public payload: OrderOperationRequest) {}
}

export class SetOrderOperationAssemblyFilter implements Action {
  readonly type = FindOrderOperationsActionTypes.SetOrderOperationAssemblyFilter;

  constructor(public payload: { orderType: OrderType, page: number, pageSize: number }) {}
}

export class LoadOrderOperationFiles implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationFiles;
}

export class CountOrderOperationFiles implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationFiles;
}

export class LoadOrderOperationMillings implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationMillings;
}

export class CountOrderOperationMillings implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationMillings;
}

export class LoadOrderOperationAluminumMillings implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAluminumMillings;
}

export class CountOrderOperationAluminumMillings implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationAluminumMillings;
}

export class LoadOrderOperationAluminumMillingCaps implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAluminumMillingCaps;
}

export class CountOrderOperationAluminumMillingCaps implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationAluminumMillingCaps;
}

export class LoadOrderOperationAluminumClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAluminumClosingPlates;
}

export class CountOrderOperationAluminumClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationAluminumClosingPlates;
}

export class LoadOrderOperationStegherrClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationStegherrClosingPlates;
}

export class CountOrderOperationStegherrClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationStegherrClosingPlates;
}

export class LoadOrderOperationAluminumHeadworkings implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAluminumHeadworkings;
}

export class CountOrderOperationAluminumHeadworkings implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationAluminumHeadworkings;
}

export class LoadOrderOperationClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationClosingPlates;
}

export class CountOrderOperationClosingPlates implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationClosingPlates;
}

export class LoadOrderOperationHeadworkings implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationHeadworkings;
}

export class CountOrderOperationHeadworkings implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationHeadworkings;
}

export class OrderOperationAssembliesExist implements Action {
  readonly type = FindOrderOperationsActionTypes.OrderOperationAssembliesExist;

  constructor(public payload: string) {}
}

export class LoadOrderOperationAssemblies implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAssemblies;

  constructor(public payload: string) {}
}

export class LoadOrderOperationCylinderAssemblies implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationCylinderAssemblies;

  constructor(public payload: string) {}
}

export class LoadOrderOperationAssemblyOverview implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationAssemblyOverview;
}

export class CountOrderOperationAssemblies implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationAssemblies;

  constructor(public payload: string) {}
}

export class OrderOperationApproveAssembliesExist implements Action {
  readonly type = FindOrderOperationsActionTypes.OrderOperationApproveAssembliesExist;

  constructor(public payload: string) {}
}

export class LoadOrderOperationApproveAssemblies implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationApproveAssemblies;

  constructor(public payload: string) {}
}

export class LoadOrderOperationApproveAssemblyOverview implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationApproveAssemblyOverview;
}

export class CountOrderOperationApproveAssemblyOverview implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationApproveAssemblyOverview;
}

export class LoadOrderOperationSlantedCuttings implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationSlantedCuttings;
}

export class CountOrderOperationSlantedCuttings implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationSlantedCuttings;
}

export class ExportOrderOperation implements Action {
  readonly type = FindOrderOperationsActionTypes.ExportOrderOperation;

  constructor(public payload: number) {}
}

export class OpenMillingPreviewDialog implements Action {
  readonly type = FindOrderOperationsActionTypes.OpenMillingPreviewDialog;

  constructor(public payload: SafeResourceUrl) {}
}

export class CloseMillingPreviewDialog implements Action {
  readonly type = FindOrderOperationsActionTypes.CloseMillingPreviewDialog;
}

export class OpenScannedOrder implements Action {
  readonly type = FindOrderOperationsActionTypes.OpenScannedOrder;

  constructor(public payload: OverviewAssemblyQr) {}
}

export class CheckAssemblyStatus implements Action {
  readonly type = FindOrderOperationsActionTypes.CheckAssemblyStatus;

  constructor(public payload: string) {}
}

export class CheckAssemblyStatusSuccess implements Action {
  readonly type = FindOrderOperationsActionTypes.CheckAssemblyStatusSuccess;

  constructor(public payload: OverviewAssemblyQr) {}
}

export class CheckAssemblyStatusFailure implements Action {
  readonly type = FindOrderOperationsActionTypes.CheckAssemblyStatusFailure;
}

export class ClearQrInfo implements Action {
  readonly type = FindOrderOperationsActionTypes.ClearQrInfo;
}

export class CheckAssemblyReadyForAssemblyFailed implements Action {
  readonly type = FindOrderOperationsActionTypes.CheckAssemblyReadyForAssemblyFailed;
}

export class LoadOrderOperationExtraKeys implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationExtraKeys;
}

export class CountOrderOperationExtraKeys implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationExtraKeys;
}

export class LoadOrderOperationWaterChannel implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationWaterChannel;
}

export class CountOrderOperationWaterChannel implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationWaterChannel;
}

export class LoadOrderOperationTrymaOverview implements Action {
  readonly type = FindOrderOperationsActionTypes.LoadOrderOperationTrymaOverview;
}

export class CountOrderOperationTrymaOverview implements Action {
  readonly type = FindOrderOperationsActionTypes.CountOrderOperationTrymaOverview;
}

export type FindOrderOperationsActionsUnion =
  | SetOrderOperationFilter
  | SetOrderOperationRequest
  | SetOrderOperationAssemblyFilter
  | LoadOrderOperationFiles
  | CountOrderOperationFiles
  | LoadOrderOperationMillings
  | CountOrderOperationMillings
  | LoadOrderOperationAluminumMillings
  | CountOrderOperationAluminumMillings
  | LoadOrderOperationAluminumMillingCaps
  | CountOrderOperationAluminumMillingCaps
  | LoadOrderOperationAluminumClosingPlates
  | CountOrderOperationAluminumClosingPlates
  | LoadOrderOperationStegherrClosingPlates
  | CountOrderOperationStegherrClosingPlates
  | LoadOrderOperationAluminumHeadworkings
  | CountOrderOperationAluminumHeadworkings
  | LoadOrderOperationClosingPlates
  | CountOrderOperationClosingPlates
  | LoadOrderOperationHeadworkings
  | CountOrderOperationHeadworkings
  | LoadOrderOperationAssemblies
  | LoadOrderOperationCylinderAssemblies
  | CountOrderOperationAssemblies
  | LoadOrderOperationSlantedCuttings
  | CountOrderOperationSlantedCuttings
  | ExportOrderOperation
  | LoadOrderOperationAssemblyOverview
  | OrderOperationAssembliesExist
  | OrderOperationApproveAssembliesExist
  | LoadOrderOperationApproveAssemblies
  | LoadOrderOperationApproveAssemblyOverview
  | CountOrderOperationApproveAssemblyOverview
  | OpenMillingPreviewDialog
  | CloseMillingPreviewDialog
  | OpenScannedOrder
  | CheckAssemblyStatus
  | CheckAssemblyStatusFailure
  | CheckAssemblyStatusSuccess
  | ClearQrInfo
  | CheckAssemblyReadyForAssemblyFailed
  | LoadOrderOperationExtraKeys
  | CountOrderOperationExtraKeys
  | LoadOrderOperationWaterChannel
  | CountOrderOperationWaterChannel
  | LoadOrderOperationTrymaOverview
  | CountOrderOperationTrymaOverview;
