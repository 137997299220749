import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthenticationModule } from '@app/auth/auth.module';
import { CoreModule } from '@app/core/core.module';

import { metaReducers, reducers } from '@app/reducers';

import { AppComponent } from '@app/core/containers/app.component';

import { AppRoutingModule } from '@app/app-routing.module';

import { getRouteConfig, ROUTE_CONFIG } from '@app/core/models/routeConfig';
import { HttpService } from '@app/core/services/http/http.service';
import { VTRequestOptions } from '@app/core/services/http/vt.request.options';

import { MatNativeDateModule } from '@angular/material';
import { ClaimGuard } from '@app/auth/guards/claim-guard.service';
import { ResetPasswordGuard } from '@app/auth/guards/reset-password-guard.service';
import { WelcomeGuard } from '@app/auth/guards/welcome-guard.service';
import { AppInsightService } from '@app/core/services/application-insights';

import localeNL from '@angular/common/locales/nl';
import { AppConfigService } from '@app/core/services/app-config-service';
import { PrintService } from '@app/core/services/print-service';
import { RouterExtService } from '@app/core/services/router-ex-service';
import { environment } from '../environments/environment';
import { LoaderService } from './core/services/loader.service';

registerLocaleData(localeNL);

export const initializeApp = (appConfig: AppConfigService) => async () => appConfig.loadSettings();

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthenticationModule,
    MatNativeDateModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),

    StoreRouterConnectingModule.forRoot(),

    StoreDevtoolsModule.instrument({
      name: 'Venster Techniek App',
      logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    CoreModule,
  ],
  providers: [
    AppConfigService,
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: HttpClient, useClass: HttpService },
    { provide: ROUTE_CONFIG, useValue: getRouteConfig() },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
    VTRequestOptions,
    AppInsightService,
    ClaimGuard,
    WelcomeGuard,
    ResetPasswordGuard,
    PrintService,
    RouterExtService,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
