import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TokenService } from '@app/auth/services/token.service';

@Directive({ selector: '[isCustomer]' })
export class IsCustomerDirective {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly tokenService: TokenService
  ) {}

  @Input()
  set isCustomer(setter: boolean) {
    if (setter === null) {
      // Default check on true
      setter = true;
    }

    const condition = setter ? this.tokenService.getIsCustomer() : !this.tokenService.getIsCustomer();

    if (!condition) {
      this.viewContainer.clear();
    } else if (condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
