import { Action } from '@ngrx/store';
import { Credentials } from '@app/auth/models/credentials';
import { TwoFactorRequest } from '../models/twoFactorRequest';
import { LoginResponse } from '../models/loginResponse';

export enum LoginPageActionTypes {
  Login = '[Login Page] Login',
  TwoFactorLogin = '[Login Page] Two Factor Login',
  SendTwoFactorActivationCode = '[Login Page] Send Two Factor Activation Code',
  SendTwoFactorActivationCodeSuccess = '[Login Page] Send Two Factor Activation Code Success',
  SendTwoFactorActivationCodeFailure = '[Login Page] Send Two Factor Activation Code Failure',
  RecoverTwoFactorAuthentication = '[Login Page] Recover Two Factor Authentication',
  RecoverTwoFactorAuthenticationSuccess = '[Login Page] Recover Two Factor Authentication Success',
  RecoverTwoFactorAuthenticationFailure = '[Login Page] Recover Two Factor Authentication Failure',  
}

export class Login implements Action {
  readonly type = LoginPageActionTypes.Login;

  constructor(public payload: { credentials: Credentials,  redirectURL: string | null }) {}
}

export class TwoFactorLogin implements Action {
  readonly type = LoginPageActionTypes.TwoFactorLogin;

  constructor(public payload: { twoFactorRequest: TwoFactorRequest,  redirectURL: string | null }) {}
}

export class SendTwoFactorActivationCode implements Action {
  readonly type = LoginPageActionTypes.SendTwoFactorActivationCode;

  constructor(public payload: TwoFactorRequest) {}
}

export class  SendTwoFactorActivationCodeSuccess implements Action {
  readonly type = LoginPageActionTypes.SendTwoFactorActivationCodeSuccess;
}

export class  SendTwoFactorActivationCodeFailure implements Action {
  readonly type = LoginPageActionTypes.SendTwoFactorActivationCodeFailure;

  constructor(error: any) {}
}

export class RecoverTwoFactorAuthentication implements Action {
  readonly type = LoginPageActionTypes.RecoverTwoFactorAuthentication;

  constructor(public payload: { code: string,  redirectURL: string | null }) {}
}

export class RecoverTwoFactorAuthenticationSuccess implements Action {
  readonly type = LoginPageActionTypes.RecoverTwoFactorAuthenticationSuccess;

  constructor(public payload: LoginResponse) {}
}

export class RecoverTwoFactorAuthenticationFailure implements Action {
  readonly type = LoginPageActionTypes.RecoverTwoFactorAuthenticationFailure;

  constructor(error: any) {}
}

export type LoginPageActionsUnion = Login | TwoFactorLogin | SendTwoFactorActivationCode;
