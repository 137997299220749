<vt-styled-authorization-page [content]="recoveryCode"></vt-styled-authorization-page>

<ng-template #recoveryCode>
    <h3>Herstel Code</h3>
    <p>
        <span>Uw two factor authenticatie is succesvol ingesteld.</span>
        <span>Sla onderstaande herstel code op om uw two factor authenticate te kunnen herstellen.</span>
    </p>
    <div class="recovery">
        <h4 *ngIf="loginResponse != null">{{loginResponse.twoFactorRecoveryCode}}</h4>
    </div>

    <div class="login-buttons">
        <button class="login-button" mat-stroked-button color="accent" (click)="submit()" mat-button>
            Doorgaan
        </button>
    </div>
</ng-template>