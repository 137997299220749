import { Action } from '@ngrx/store';

export enum FinalizeProductWizardActionTypes {
  CreateOrderDetail = '[Finalize Product Wizard] Create Order Detail',
  UpdateOrderDetail = '[Finalize Product Wizard] Update Order Detail',
  CalculateOrderDetail = '[Finalize Product Wizard] Calculate Order Detail',
  GenerateOrderSLN = '[Finalize Product Wizard] Generate Order SLN',
  WizardFinished = '[Finalize Product Wizard] Clear Wizard State Success',
}

export class CreateOrderDetail implements Action {
  readonly type = FinalizeProductWizardActionTypes.CreateOrderDetail;

  constructor(public orderHeaderId: string, public navigate: boolean) {}
}

export class UpdateOrderDetail implements Action {
  readonly type = FinalizeProductWizardActionTypes.UpdateOrderDetail;

  constructor(public orderHeaderId: string, public orderDetailId: string) {}
}

export class CalculateOrderDetail implements Action {
  readonly type = FinalizeProductWizardActionTypes.CalculateOrderDetail;
}

export class GenerateOrderSLN implements Action {
  readonly type = FinalizeProductWizardActionTypes.GenerateOrderSLN;
}

export class ClearWizardStateOnSuccess implements Action {
  readonly type = FinalizeProductWizardActionTypes.WizardFinished;
}

export type ProductOrderActionsUnion =
  | CreateOrderDetail
  | UpdateOrderDetail
  | CalculateOrderDetail
  | GenerateOrderSLN
  | ClearWizardStateOnSuccess;
