import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AuthenticationApiActions } from '@app/auth/actions';
import { TokenService } from '@app/auth/services/token.service';
import * as fromAuth from '@app/auth/reducers';
import { AuthService } from '@app/auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/auth.service";
import * as i3 from "../services/token.service";
var AuthenticationGuard = /** @class */ (function () {
    /**
     * Initializes a new instance of the AuthGuard class.
     * @param {Store<fromAuth.store>} store The redux store.
     * @param {AuthService} authenticationService The authentication service.
     * @param {TokenService} tokenService The token service.
     */
    function AuthenticationGuard(store, authenticationService, tokenService) {
        this.store = store;
        this.authenticationService = authenticationService;
        this.tokenService = tokenService;
    }
    /**
     * Whether or not the current user is available in the api.
     */
    AuthenticationGuard.prototype.hasUserInApi = function (next, state) {
        var _this = this;
        return this.authenticationService.retrieveCurrentUser().pipe(map(function (user) { return new AuthenticationApiActions.RetrieveUserSuccess({ user: user }); }), tap(function (action) { return _this.store.dispatch(action); }), map(function (user) { return !!user.payload; }), catchError(function () {
            _this.tokenService.removeToken();
            _this.store.dispatch(new AuthenticationApiActions.LoginRedirect(state.url));
            return of(false);
        }));
    };
    /**
     * Wether or not the current user is authenticated.
     */
    AuthenticationGuard.prototype.isAuthenticated = function () {
        return this.store.pipe(select(fromAuth.getLoggedIn), take(1));
    };
    /**
     * Whether or not the current user is available in local storage.
     */
    AuthenticationGuard.prototype.hasUserInLocalStorage = function () {
        var currentUser = this.tokenService.getTokenObject();
        return !!currentUser && !!currentUser.accessToken && !!currentUser.refreshToken && !!currentUser.claims;
    };
    /**
     * Whether or not the current route can be activated.
     */
    AuthenticationGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.isAuthenticated().pipe(function (isAuthenticated) {
            if (isAuthenticated && _this.hasUserInLocalStorage()) {
                return _this.hasUserInApi(next, state);
            }
            _this.store.dispatch(new AuthenticationApiActions.LoginRedirect(state.url));
            return of(false);
        });
    };
    AuthenticationGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.inject(i1.Store), i0.inject(i2.AuthService), i0.inject(i3.TokenService)); }, token: AuthenticationGuard, providedIn: "root" });
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
