import { Action } from '@ngrx/store';
import { RegisterUser } from '@app/auth/models/registerUser';

export enum RegisterUserPageActionTypes {
  SubmitRegisterUser = '[Submit RegisterUser Page] Submit RegisterUser',
}

export class SubmitRegisterUser implements Action {
  readonly type = RegisterUserPageActionTypes.SubmitRegisterUser;

  constructor(public payload: RegisterUser ) {}
}

export type RegisterUserPageActionsUnion = SubmitRegisterUser;

