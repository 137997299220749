import { LayoutActions } from '@app/core/actions';
import { SnackBar } from '@app/core/models/SnackBar';

export interface State {
  showErrorSnackBar: boolean;
  showSuccessSnackBar: boolean;
  showInfoSnackBar: boolean;
  successSnackBar: SnackBar;
  errorSnackBar: SnackBar;
  infoSnackBar: SnackBar;
}

const initialState: State = {
  showErrorSnackBar: false,
  showSuccessSnackBar: false,
  showInfoSnackBar: false,
  errorSnackBar: { messages: [] },
  successSnackBar: { messages: [] },
  infoSnackBar: { messages: [] },
};

export function reducer(state: State = initialState, action: LayoutActions.LayoutActionsUnion): State {
  switch (action.type) {
    case LayoutActions.LayoutActionTypes.OpenErrorSnackBar:
      return {
        ...state,
        showErrorSnackBar: true,
        errorSnackBar: {
          ...action.payload,
          config: { panelClass: 'error-snack-bar' },
        },
      };
    case LayoutActions.LayoutActionTypes.CloseErrorSnackBar:
      return { ...state, showErrorSnackBar: false };
    case LayoutActions.LayoutActionTypes.OpenSuccessSnackBar:
      return {
        ...state,
        showSuccessSnackBar: true,
        successSnackBar: {
          ...action.payload,
          config: { panelClass: 'success-snack-bar' },
        },
      };
    case LayoutActions.LayoutActionTypes.CloseSuccessSnackBar:
      return { ...state, showSuccessSnackBar: false };
    case LayoutActions.LayoutActionTypes.OpenInfoSnackBar:
      return {
        ...state,
        showInfoSnackBar: true,
        infoSnackBar: {
          ...action.payload,
          config: { panelClass: 'info-snack-bar' },
        }
      };
    case LayoutActions.LayoutActionTypes.CloseInfoSnackBar:
      return { ...state, showInfoSnackBar: false };
    default:
      return state;
  }
}

export const getShowErrorSnackBar = (state: State) => state.showErrorSnackBar;
export const getErrorSnackBar = (state: State) => state.errorSnackBar;

export const getShowSuccessSnackBar = (state: State) => state.showSuccessSnackBar;
export const getSuccessSnackBar = (state: State) => state.successSnackBar;

export const getShowInfoSnackBar = (state: State) => state.showInfoSnackBar;
export const getInfoSnackBar = (state: State) => state.infoSnackBar;
