import { KeyValuePair } from '@app/core/models/keyValuePair';

export enum TrymaMachines {
    NoTryma = null,
    TrymaOne = 1,
    TrymaTwo = 2
}

export const trymaMachines: { [index: number]: string} = {
    [TrymaMachines.NoTryma]: 'Gaat niet over Tryma',
    [TrymaMachines.TrymaOne]: 'Tryma machine 1',
    [TrymaMachines.TrymaTwo]: 'Tryma machine 2'
};

export const trymaMachinesShort: { [index: number]: string} = {
    [TrymaMachines.NoTryma]: 'Geen Tryma',
    [TrymaMachines.TrymaOne]: 'Machine 1',
    [TrymaMachines.TrymaTwo]: 'Machine 2'
};

export const trymaMachineOptions: Array<KeyValuePair> = [
    { key: TrymaMachines.NoTryma, value: trymaMachines[TrymaMachines.NoTryma] },
    { key: TrymaMachines.TrymaOne, value: trymaMachines[TrymaMachines.TrymaOne] },
    { key: TrymaMachines.TrymaTwo, value: trymaMachines[TrymaMachines.TrymaTwo] }
];
