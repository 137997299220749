import { Action } from '@ngrx/store';
import { OrderSection } from '@app/product-wizard/models/order-section';
import { SafeResourceUrl } from '@angular/platform-browser';

export enum WizardPageActionTypes {
  AddSection = '[Wizards Page] Add Section',
  ResetToSection = '[Wizards Page] Reset To Section ',
  ResetToHeader = '[Wizards Page] Reset To Header',
  DeleteWizardFeedbackSuccess = '[Wizards Page] Delete Wizard Feedback Success',
  DeleteWizardFeedbackFailure = '[Wizards Page] Delete Wizard Feedback Failure',
  ResetToDetail = '[Wizards Page] Reset To Detail',
  OpenProductOrderSillPreviewDialog = '[Wizards Page] Open Sill Preview',
  CloseProductOrderSillPreviewDialog = '[Wizards Page] Close Sill Preview',
  OpenProductSectionLengthConfirmation = '[Wizards Page] Open Product Section Length Confirmation',
  ApproveProductSectionLengthConfirmation = '[Wizards Page] Approve Product Section Length Confirmation',
  DisapproveProductSectionLengthConfirmation = '[Wizards Page] Disapprove Product Section Length Confirmation',
  DisableMaxSectionLengthEnabled = '[Wizards Page] Disable Max Section Length Enabled',
  NavigatedToWizard = '[Wizards Page] Navigated To Wizard',
}

export class AddSection implements Action {
  readonly type = WizardPageActionTypes.AddSection;

  constructor(public payload: OrderSection | null) {}
}

export class ResetToSection implements Action {
  readonly type = WizardPageActionTypes.ResetToSection;

  constructor(public payload: number) {}
}

export class ResetToHeader implements Action {
  readonly type = WizardPageActionTypes.ResetToHeader;
}

export class DeleteWizardFeedbackSuccess implements Action {
  readonly type = WizardPageActionTypes.DeleteWizardFeedbackSuccess;
}

export class DeleteWizardFeedbackFailure implements Action {
  readonly type = WizardPageActionTypes.DeleteWizardFeedbackFailure;
  constructor(public payload: Error[]) {}
}

export class ResetToDetail implements Action {
  readonly type = WizardPageActionTypes.ResetToDetail;
}

export class OpenProductOrderSillPreviewDialog implements Action {
  readonly type = WizardPageActionTypes.OpenProductOrderSillPreviewDialog;

  constructor(public payload: SafeResourceUrl) {}
}

export class CloseProductOrderSillPreviewDialog implements Action {
  readonly type = WizardPageActionTypes.CloseProductOrderSillPreviewDialog;
}

export class OpenProductSectionLengthConfirmation implements Action {
  readonly type = WizardPageActionTypes.OpenProductSectionLengthConfirmation;
  constructor(public payload: number) {}
}

export class ApproveProductSectionLengthConfirmation implements Action {
  readonly type = WizardPageActionTypes.ApproveProductSectionLengthConfirmation;
}

export class DisapproveProductSectionLengthConfirmation implements Action {
  readonly type = WizardPageActionTypes.DisapproveProductSectionLengthConfirmation;
}

export class DisableMaxSectionLengthEnabled implements Action {
  readonly type = WizardPageActionTypes.DisableMaxSectionLengthEnabled;
}

export class NavigatedToWizard implements Action {
  readonly type = WizardPageActionTypes.NavigatedToWizard;
}

export type WizardPageActionsUnion =
AddSection |
ResetToSection |
ResetToHeader |
DeleteWizardFeedbackSuccess |
DeleteWizardFeedbackFailure |
ResetToDetail |
OpenProductOrderSillPreviewDialog |
CloseProductOrderSillPreviewDialog |
OpenProductSectionLengthConfirmation |
ApproveProductSectionLengthConfirmation |
DisapproveProductSectionLengthConfirmation |
DisableMaxSectionLengthEnabled |
NavigatedToWizard;
