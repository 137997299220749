/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../core/components/header-actions/header-actions.component.ngfactory";
import * as i2 from "../../core/components/header-actions/header-actions.component";
import * as i3 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "../components/register-user-form.component.ngfactory";
import * as i6 from "../components/register-user-form.component";
import * as i7 from "@angular/common";
import * as i8 from "./register-user-page.component";
import * as i9 from "@ngrx/store";
var styles_RegisterUserPageComponent = [];
var RenderType_RegisterUserPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterUserPageComponent, data: {} });
export { RenderType_RegisterUserPageComponent as RenderType_RegisterUserPageComponent };
function View_RegisterUserPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Registreren"]))], null, null); }
export function View_RegisterUserPageComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "vt-header-actions", [["mainTitle", "Registreren"]], null, null, null, i1.View_HeaderActionsComponent_0, i1.RenderType_HeaderActionsComponent)), i0.ɵdid(1, 49152, null, 0, i2.HeaderActionsComponent, [], { mainTitle: [0, "mainTitle"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Let op: Het registratie formulier is ter registratie van nieuwe klanten."])), (_l()(), i0.ɵeld(4, 0, null, null, 11, "mat-tab-group", [["class", "vt-tab-group mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i0.ɵdid(5, 3325952, null, 1, i4.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG]], { selectedIndex: [0, "selectedIndex"] }, null), i0.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i0.ɵeld(7, 16777216, null, null, 8, "mat-tab", [], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i0.ɵdid(8, 770048, [[1, 4]], 2, i4.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(335544320, 2, { templateLabel: 0 }), i0.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RegisterUserPageComponent_1)), i0.ɵdid(12, 16384, [[2, 4]], 0, i4.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵeld(13, 0, null, 0, 2, "vt-register-user-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RegisterUserFormComponent_0, i5.RenderType_RegisterUserFormComponent)), i0.ɵdid(14, 114688, null, 0, i6.RegisterUserFormComponent, [], { errors: [0, "errors"] }, { submitted: "submitted" }), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Registreren"; _ck(_v, 1, 0, currVal_0); var currVal_3 = 1; _ck(_v, 5, 0, currVal_3); _ck(_v, 8, 0); var currVal_4 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.errors$)); _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 5).dynamicHeight; var currVal_2 = (i0.ɵnov(_v, 5).headerPosition === "below"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_RegisterUserPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-register-user-page", [], null, null, null, View_RegisterUserPageComponent_0, RenderType_RegisterUserPageComponent)), i0.ɵdid(1, 49152, null, 0, i8.RegisterUserPageComponent, [i9.Store], null, null)], null, null); }
var RegisterUserPageComponentNgFactory = i0.ɵccf("vt-register-user-page", i8.RegisterUserPageComponent, View_RegisterUserPageComponent_Host_0, {}, {}, []);
export { RegisterUserPageComponentNgFactory as RegisterUserPageComponentNgFactory };
