import { TemplateRef, ViewContainerRef } from '@angular/core';
import { TokenService } from '@app/auth/services/token.service';
var IsCustomerDirective = /** @class */ (function () {
    function IsCustomerDirective(templateRef, viewContainer, tokenService) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.tokenService = tokenService;
    }
    Object.defineProperty(IsCustomerDirective.prototype, "isCustomer", {
        set: function (setter) {
            if (setter === null) {
                // Default check on true
                setter = true;
            }
            var condition = setter ? this.tokenService.getIsCustomer() : !this.tokenService.getIsCustomer();
            if (!condition) {
                this.viewContainer.clear();
            }
            else if (condition) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    return IsCustomerDirective;
}());
export { IsCustomerDirective };
