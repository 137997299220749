import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthenticationApiActions } from '@app/auth/actions';
import * as fromAuth from '@app/auth/reducers';
import { map } from 'rxjs/operators';
import { AuthenticationGuard } from '@app/auth/guards/auth-guard.service';
import { hasClaims } from '@app/core/models/Claims/ClaimFunctions';

@Injectable()
export class ClaimGuard implements CanActivate {
  constructor(
    private readonly store: Store<fromAuth.State>,
    private readonly authGuard: AuthenticationGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(route, state).pipe(
      map(authenticated => {
        if (authenticated && hasClaims(route.data.requiredClaims)) {
          return true;
        }

        this.store.dispatch(new AuthenticationApiActions.LoginRedirect(state.url));
        return false;
    }));
  }
}
