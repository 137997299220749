<h3>Inloggen</h3>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div>
    <mat-form-field> 
      <input type="text" matInput placeholder="Email" formControlName="username" autocomplete="username"/> 
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input type="password" matInput placeholder="Wachtwoord" formControlName="password" autocomplete="current-password"/>
    </mat-form-field>
  </div>

  <p *ngIf="errorMessage" class="login-error">{{ errorMessage }}</p>
  <div class="links">
    <a [routerLink]="['/auth/forgotpassword']">Wachtwoord vergeten?</a>
    <a href="javascript: void(0);" (click)="onDownloadHelpPressed()">Hulp nodig?</a>
  </div>
  <div class="login-buttons">
    <a class="login-button" mat-flat-button color="primary" [routerLink]="['/auth/register']">Nieuwe klant</a>
    <button class="login-button" mat-stroked-button color="accent" type="submit" mat-button>Inloggen</button>
  </div>
</form>
