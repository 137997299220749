import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConfigService } from '@app/core/services/app-config-service';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    isLoaderShown = new BehaviorSubject<[boolean, boolean]>([false, false]);

    public showLoader(coverHeader: boolean = false) {
        if (!AppConfigService.config.App.ShowLoader) {
            return;
        }
        
        this.isLoaderShown.next([true, coverHeader]);
    }

    public hideLoader() {
        this.isLoaderShown.next([false, false]);
    }
}