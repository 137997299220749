import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordPageComponent } from '@app/auth/containers/forgot-password-request-page.component';
import { InformationPageComponent } from '@app/auth/containers/information-page.component';
import { LoginPageComponent } from '@app/auth/containers/login-page.component';
import { RegisterUserPageComponent } from '@app/auth/containers/register-user-page.component';
import { ResetPasswordPageComponent } from '@app/auth/containers/reset-password-page.component';
import { WelcomePageComponent } from '@app/auth/containers/welcome-page.component';
import { AuthenticationGuard } from '@app/auth/guards/auth-guard.service';
import { ResetPasswordGuard } from '@app/auth/guards/reset-password-guard.service';
import { WelcomeGuard } from '@app/auth/guards/welcome-guard.service';
import { TwoFactorPageComponent } from './containers/two-factor-authentication/two-factor-page.component';
import { RecoveryCodePageComponent } from './containers/two-factor-authentication/recovery-code-page.component';
import { RecoverPageComponent } from './containers/two-factor-authentication/recover-authentication-page.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent, canActivate: [WelcomeGuard] },
  { path: 'twofactor', component: TwoFactorPageComponent, canActivate: [WelcomeGuard] },
  { path: 'recover', component: RecoverPageComponent, canActivate: [WelcomeGuard] },
  { path: 'recoveryCode', component: RecoveryCodePageComponent, canActivate: [AuthenticationGuard] },
  { path: 'register', component: RegisterUserPageComponent },
  { path: 'forgotpassword', component: ForgotPasswordPageComponent },
  { path: 'resetpassword', component: ResetPasswordPageComponent, canActivate: [ResetPasswordGuard] },
  { path: 'information', component: InformationPageComponent },
  { path: 'welcome', component: WelcomePageComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
