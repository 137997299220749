import { Action } from '@ngrx/store';

export enum PrintOrderOperationsActionTypes {
  PrintFile = '[Print] Print File',

}

export class PrintFile implements Action {
  readonly type = PrintOrderOperationsActionTypes.PrintFile;

  constructor(public payload: string) {}
}

export type PrintOrderOperationsActionsUnion =
  | PrintFile
