/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/welcome.component.ngfactory";
import * as i2 from "../components/welcome.component";
import * as i3 from "./styled-authorization-page.component.ngfactory";
import * as i4 from "./styled-authorization-page.component";
import * as i5 from "./welcome-page.component";
var styles_WelcomePageComponent = [];
var RenderType_WelcomePageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WelcomePageComponent, data: {} });
export { RenderType_WelcomePageComponent as RenderType_WelcomePageComponent };
function View_WelcomePageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-welcome", [], null, null, null, i1.View_WelcomeComponent_0, i1.RenderType_WelcomeComponent)), i0.ɵdid(1, 49152, null, 0, i2.WelcomeComponent, [], null, null)], null, null); }
export function View_WelcomePageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-styled-authorization-page", [], null, null, null, i3.View_StyledAuthorizationPageComponent_0, i3.RenderType_StyledAuthorizationPageComponent)), i0.ɵdid(1, 114688, null, 0, i4.StyledAuthorizationPageComponent, [], { content: [0, "content"] }, null), (_l()(), i0.ɵand(0, [["welcome", 2]], null, 0, null, View_WelcomePageComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WelcomePageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-welcome-page", [], null, null, null, View_WelcomePageComponent_0, RenderType_WelcomePageComponent)), i0.ɵdid(1, 49152, null, 0, i5.WelcomePageComponent, [], null, null)], null, null); }
var WelcomePageComponentNgFactory = i0.ɵccf("vt-welcome-page", i5.WelcomePageComponent, View_WelcomePageComponent_Host_0, {}, {}, []);
export { WelcomePageComponentNgFactory as WelcomePageComponentNgFactory };
