import { Action } from '@ngrx/store';
import { LoginResponse } from '@app/auth/models/loginResponse';
import { User } from '@app/users/models/user';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { Error } from '@app/core/models/Error';

export enum AuthenticationApiActionTypes {
  LoginSuccess = '[Authentication/API] Login Success',
  LoginFailure = '[Authentication/API] Login Failure',
  LoginRedirect = '[Authentication/API] Login Redirect',
  RetrieveUserSuccess = '[Authentication/API] Retrieve User Success',
  RegisterUserSuccess = '[Authentication/API] Register User Success',
  RegisterUserFailure = '[Authentication/API] Register User Failure',
  ForgotPasswordRequestSuccess = '[Authentication/API] Forgot Password Request Success',
  ForgotPasswordRequestFailure = '[Authentication/API] Forgot Password Request Failure',
  ResetPasswordRequestSuccess = '[Authentication/API] Reset Password Request Success',
  ResetPasswordRequestFailure = '[Authentication/API] Reset Password Request Failure'
}

export class LoginSuccess implements Action {
  readonly type = AuthenticationApiActionTypes.LoginSuccess;

  constructor(public payload: { loginResponse: LoginResponse }) {}
}

export class LoginFailure implements Action {
  readonly type = AuthenticationApiActionTypes.LoginFailure;

  constructor(public payload: { error: any }) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthenticationApiActionTypes.LoginRedirect;

  constructor(public payload: string ) {}
}

export class RetrieveUserSuccess implements Action {
  readonly type = AuthenticationApiActionTypes.RetrieveUserSuccess;

  constructor(public payload: { user: User }) {}
}

/**
 * Register User Actions
 */
export class RegisterUserSuccess implements Action {
  readonly type = AuthenticationApiActionTypes.RegisterUserSuccess;

  constructor(public payload: RegisterUser) {}
}

export class RegisterUserFailure implements Action {
  readonly type = AuthenticationApiActionTypes.RegisterUserFailure;

  constructor(public payload: Error[]) {}
}

/**
 * Forgot Password Actions
 */
export class ForgotPasswordRequestSuccess implements Action {
  readonly type = AuthenticationApiActionTypes.ForgotPasswordRequestSuccess;

  constructor(public payload: ForgotPasswordRequest) {}
}

export class ForgotPasswordRequestFailure implements Action {
  readonly type = AuthenticationApiActionTypes.ForgotPasswordRequestFailure;

  constructor(public payload: { error: Error[] }) {}
}

/**
 * Reset Password Actions
 */
export class ResetPasswordRequestSuccess implements Action {
  readonly type = AuthenticationApiActionTypes.ResetPasswordRequestSuccess;

  constructor(public payload: boolean) {}
}

export class ResetPasswordRequestFailure implements Action {
  readonly type = AuthenticationApiActionTypes.ResetPasswordRequestFailure;

  constructor(public payload: Error[]) {}
}

export type AuthenticationApiActionsUnion =
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | RetrieveUserSuccess
  | RegisterUserSuccess
  | RegisterUserFailure
  | ForgotPasswordRequestSuccess
  | ForgotPasswordRequestFailure
  | ResetPasswordRequestSuccess
  | ResetPasswordRequestFailure;
