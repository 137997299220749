var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthenticationApiActions, RegisterUserPageActions } from '@app/auth/actions';
export var initialState = {
    errors: [],
    pending: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RegisterUserPageActions.RegisterUserPageActionTypes.SubmitRegisterUser: {
            return __assign({}, state, { errors: [], pending: true });
        }
        case AuthenticationApiActions.AuthenticationApiActionTypes.RegisterUserSuccess: {
            return __assign({}, state, { errors: [], pending: false });
        }
        case AuthenticationApiActions.AuthenticationApiActionTypes.RegisterUserFailure: {
            return __assign({}, state, { errors: action.payload, pending: false });
        }
        default: {
            return state;
        }
    }
}
export var getErrors = function (state) { return state.errors; };
export var getPending = function (state) { return state.pending; };
