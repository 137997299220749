/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i3 from "@angular/material/sidenav";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-sidenav-container", [["class", "mat-drawer-container mat-sidenav-container"], ["fullscreen", ""]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i2.View_MatSidenavContainer_0, i2.RenderType_MatSidenavContainer)), i1.ɵdid(1, 1490944, null, 2, i3.MatSidenavContainer, [[2, i4.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i3.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i5.ANIMATION_MODULE_TYPE], [2, i6.ViewportRuler]], null, null), i1.ɵqud(603979776, 1, { _drawers: 1 }), i1.ɵqud(335544320, 2, { _content: 0 }), i1.ɵncd(2, 0)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._backdropOverride; _ck(_v, 0, 0, currVal_0); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vt-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 49152, null, 0, i7.LayoutComponent, [], null, null)], null, null); }
var LayoutComponentNgFactory = i1.ɵccf("vt-layout", i7.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, ["*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
