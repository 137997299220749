import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MoveToMachineData } from '@app/core/models/move-to-machine/move-to-machine-data.model';

@Component({
  selector: 'vt-move-to-machine-modal',
  templateUrl: './move-to-machine-modal.component.html',
  styleUrls: ['./move-to-machine-modal.component.scss']
})
export class MoveToMachineModalComponent {
  dialogData: MoveToMachineData;

  selectedMachine: number;

  constructor(@Inject(MAT_DIALOG_DATA) data: MoveToMachineData) {
    this.dialogData = data;
    this.selectedMachine = this.dialogData.machines[0];
  }

  onSelectChange(machineNumber: number) {
    this.selectedMachine = machineNumber;
  }
}
