import { Error } from '@app/core/models/Error';
import { OverviewModel } from '@app/core/models/overview.model';
import { OrderOperationAluminumClosingPlate } from '@app/order-operations/models/order-operation/aluminum/order-operation-aluminum-closing-plate';
import { OrderOperationStegherrClosingPlate } from '@app/order-operations/models/order-operation/stegherr/order-operation-stegherr-closing-plate';
import { OrderOperationAssemblyFilter } from '@app/order-operations/models/order-operation/assembly/order-operation-assembly-filter.model';
import { OrderOperationClosingPlate } from '@app/order-operations/models/order-operation/order-operation-closing-plate';
import { OrderPlinthBlocksByStyleNumber } from '@app/order-operations/models/order-plinthblocks-by-style-number';
import { Action } from '@ngrx/store';
import { OrderOperationAluminumHeadworking } from '../models/order-operation/aluminum/order-operation-aluminum-headworking';
import { OrderOperationAluminumMilling } from '../models/order-operation/aluminum/order-operation-aluminum-milling';
import { OrderOperationAluminumMillingCaps } from '../models/order-operation/aluminum/order-operation-aluminum-milling-caps';
import { OrderOperationAssembly } from '../models/order-operation/assembly/order-operation-assembly';
import { OrderOperationAssemblyOverview } from '../models/order-operation/assembly/order-operation-assembly-overview';
import { OrderOperationFile } from '../models/order-operation/order-operation-file';
import { OrderOperationCylinderAssembly } from '../models/order-operation/order-operation-cylinder-assembly';
import { OrderOperationCylindersExtraKeys } from '../models/order-operation/order-operation-cylinders-extra-keys';
import { OrderOperationHeadworking } from '../models/order-operation/order-operation-headworking';
import { OrderOperationMilling } from '../models/order-operation/order-operation-milling';
import { OrderOperationSlantedCutting } from '../models/order-operation/order-operation-slanted-cutting';
import { OrderOperationTrymaOverview } from '../models/order-operation/order-operation-tryma-overview';
import { OrderOperationWaterChannel } from '../models/order-operation/order-operation-waterchannel';

export enum OrderOperationsApiActionTypes {
  LoadFileSuccess = '[Order Operations/API] Load File Success',
  LoadFileFailure = '[Order Operations/API] Load File Failure',
  CountFileSuccess = '[Order Operations/API] Count File Success',
  CountFileFailure = '[Order Operations/API] Count File Failure',
  FinalizeSuccess = '[Order Operations/API] Finalize Success',
  FinalizeFileFailure = '[Order Operations/API] Finalize File Failure',
  LoadMillingSuccess = '[Order Operations/API] Load Milling Success',
  LoadMillingFailure = '[Order Operations/API] Load Milling Failure',
  CountMillingSuccess = '[Order Operations/API] Count Milling Success',
  CountMillingFailure = '[Order Operations/API] Count Milling Failure',
  LoadAluminumMillingSuccess = '[Order Operations/API] Load Aluminum Milling Success',
  LoadAluminumMillingFailure = '[Order Operations/API] Load Aluminum Milling Failure',
  CountAluminumMillingSuccess = '[Order Operations/API] Count Aluminum Milling Success',
  CountAluminumMillingFailure = '[Order Operations/API] Count Aluminum Milling Failure',
  FinalizeAluminumMillingSuccess = '[Order Operations/API] Finalize Aluminum Milling Success',
  FinalizeAluminumMillingFailure = '[Order Operations/API] Finalize Aluminum Milling Failure',
  LoadAluminumMillingCapsSuccess = '[Order Operations/API] Load Aluminum Head Milling Success',
  LoadAluminumMillingCapsFailure = '[Order Operations/API] Load Aluminum Head Milling Failure',
  CountAluminumMillingCapsSuccess = '[Order Operations/API] Count Aluminum Head Milling Success',
  CountAluminumMillingCapsFailure = '[Order Operations/API] Count Aluminum Head Milling Failure',
  FinalizeAluminumMillingCapsSuccess = '[Order Operations/API] Finalize Aluminum Head Milling Success',
  FinalizeAluminumMillingCapsFailure = '[Order Operations/API] Finalize Aluminum Head Milling Failure',
  LoadAluminumClosingPlateSuccess = '[Order Operations/API] Load Aluminum Closing Plate Success',
  LoadAluminumClosingPlateFailure = '[Order Operations/API] Load Aluminum Closing Plate Failure',
  CountAluminumClosingPlateSuccess = '[Order Operations/API] Count Aluminum Closing Plate Success',
  CountAluminumClosingPlateFailure = '[Order Operations/API] Count Aluminum Closing Plate Failure',
  FinalizeAluminumClosingPlateSuccess = '[Order Operations/API] Finalize Aluminum Closing Plate Success',
  FinalizeAluminumClosingPlateFailure = '[Order Operations/API] Finalize Aluminum Closing Plate Failure',
  LoadStegherrClosingPlateSuccess = '[Order Operations/API] Load Stegherr Closing Plate Success',
  LoadStegherrClosingPlateFailure = '[Order Operations/API] Load Stegherr Closing Plate Failure',
  CountStegherrClosingPlateSuccess = '[Order Operations/API] Count Stegherr Closing Plate Success',
  CountStegherrClosingPlateFailure = '[Order Operations/API] Count Stegherr Closing Plate Failure',
  FinalizeStegherrClosingPlateSuccess = '[Order Operations/API] Finalize Stegherr Closing Plate Success',
  FinalizeStegherrClosingPlateFailure = '[Order Operations/API] Finalize Stegherr Closing Plate Failure',
  LoadAluminumHeadworkingSuccess = '[Order Operations/API] Load Aluminum Headworking Success',
  LoadAluminumHeadworkingFailure = '[Order Operations/API] Load Aluminum Headworking Failure',
  CountAluminumHeadworkingSuccess = '[Order Operations/API] Count Aluminum Headworking Success',
  CountAluminumHeadworkingFailure = '[Order Operations/API] Count Aluminum Headworking Failure',
  FinalizeAluminumHeadworkingSuccess = '[Order Operations/API] Finalize Aluminum Headworking Success',
  FinalizeAluminumHeadworkingFailure = '[Order Operations/API] Finalize Aluminum Headworking Failure',
  LoadClosingPlateSuccess = '[Order Operations/API] Load Closing Plate Success',
  LoadClosingPlateFailure = '[Order Operations/API] Load Closing Plate Failure',
  CountClosingPlateSuccess = '[Order Operations/API] Count Closing Plate Success',
  CountClosingPlateFailure = '[Order Operations/API] Count Closing Plate Failure',
  FinalizeClosingPlateSuccess = '[Order Operations/API] Finalize Closing Plate Success',
  FinalizeClosingPlateFailure = '[Order Operations/API] Finalize Closing Plate Failure',
  LoadOrderPlinthBlocksSuccess = '[Order Operations/API] Load Order Plinth Blocks Success',
  LoadOrderPlinthBlocksFailure = '[Order Operations/API] Load Order Plinth Blocks Failure',
  CountOrderPlinthBlocksSuccess = '[Order Operations/API] Count Order Plinth Blocks Success',
  CountOrderPlinthBlocksFailure = '[Order Operations/API] Count Order Plinth Blocks Failure',
  FinalizeMillingSuccess = '[Order Operations/API] Finalize Milling Success',
  FinalizeMillingFailure = '[Order Operations/API] Finalize Milling Failure',
  LoadHeadworkingSuccess = '[Order Operations/API] Load Headworking Success',
  LoadHeadworkingFailure = '[Order Operations/API] Load Headworking Failure',
  CountHeadworkingSuccess = '[Order Operations/API] Count Headworking Success',
  CountHeadworkingFailure = '[Order Operations/API] Count Headworking Failure',
  FinalizeHeadworkingSuccess = '[Order Operations/API] Finalize Headworking Success',
  FinalizeHeadworkingFailure = '[Order Operations/API] Finalize Headworking Failure',
  LoadAssemblySuccess = '[Order Operations/API] Load Assembly Success',
  LoadAssemblyFailure = '[Order Operations/API] Load Assembly Failure',
  LoadCylinderAssemblySuccess = '[Order Operations/API] Load Cylinder Assembly Success',
  LoadCylinderAssemblyFailure = '[Order Operations/API] Load Cylinder Assembly Failure',
  LoadAssemblyOverviewSuccess = '[Order Operations/API] Load Assembly Overview Success',
  LoadAssemblyOverviewFailure = '[Order Operations/API] Load Assembly Overview Failure',
  FinalizeAssemblyOverviewSuccess = '[Order Operations/API] Finalize Assembly Overview Success',
  FinalizeAssemblyOverviewFailure = '[Order Operations/API] Finalize Assembly Overview Failure',
  FinalizeAssemblySuccess = '[Order Operations/API] Finalize Assembly Success',
  FinalizeAssemblyFailure = '[Order Operations/API] Finalize Assembly Failure',
  PrintAssemblySuccess = '[Order Operations/API] Print Assembly Success',
  PrintAssemblyFailure = '[Order Operations/API] Print Assembly Failure',
  LoadApproveAssemblySuccess = '[Order Operations/API] Load Approve Assembly Success',
  LoadApproveAssemblyFailure = '[Order Operations/API] Load Approve Assembly Failure',
  LoadApproveAssemblyOverviewSuccess = '[Order Operations/API] Load Approve Assembly Overview Success',
  LoadApproveAssemblyOverviewFailure = '[Order Operations/API] Load Approve Assembly Overview Failure',
  CountApproveAssemblyOverviewSuccess = '[Order Operations/API] Count Approve Assembly Overview Success',
  CountApproveAssemblyOverviewFailure = '[Order Operations/API] Count Approve Assembly Overview Failure',
  ApproveAssemblySuccess = '[Order Operations/API] Approve Assembly Success',
  ApproveAssemblyFailure = '[Order Operations/API] Approve Assembly Failure',
  DisapproveAssemblySuccess = '[Order Operations/API] Disapprove Assembly Success',
  DisapproveAssemblyFailure = '[Order Operations/API] Disapprove Assembly Failure',
  LoadSlantedCuttingSuccess = '[Order Operations/API] Load Slanted Cutting Success',
  LoadSlantedCuttingFailure = '[Order Operations/API] Load Slanted Cutting Failure',
  CountSlantedCuttingSuccess = '[Order Operations/API] Count Slanted Cutting Success',
  CountSlantedCuttingFailure = '[Order Operations/API] Count Slanted Cutting Failure',
  FinalizeSlantedCuttingSuccess = '[Order Operations/API] Finalize Slanted Cutting Success',
  FinalizeSlantedCuttingFailure = '[Order Operations/API] Finalize Slanted Cutting Failure',
  ExportOrderOperationSuccess = '[Order Operations/API] Export Order Operation Success',
  ExportOrderOperationFailure = '[Order Operations/API] Export Order Operation Failure',
  ExportOrderPlinthBlocksSuccess = '[Order Operations/API] Export Order Plinth Blocks Success',
  ExportOrderPlinthBlocksFailure = '[Order Operations/API] Export Order Plinth Blocks Failure',
  LoadOrderOperationExtraKeysSuccess = '[Order Operations/API] Load Order Operation Extra Keys Success',
  LoadOrderOperationExtraKeysFailure = '[Order Operations/API] Load Order Operation Extra Keys Failure',
  CountOrderOperationExtraKeysSuccess = '[Order Operations/API] Count Order Operation Extra Keys Success',
  CountOrderOperationExtraKeysFailure = '[Order Operations/API] Count Order Operation Extra Keys Failure',
  FinalizeExtraKeysSuccess = '[Order Operations/API] Finalize Extra Keys Success',
  FinalizeExtraKeysFailure = '[Order Operations/API] Finalize Extra Keys Failure',
  FinalizeCylinderAssemblySuccess = '[Order Operations/API] Finalize Cylinder Assembly Success',
  FinalizeCylinderAssemblyFailure = '[Order Operations/API] Finalize Cylinder Assembly Failure',
  LoadWaterChannelSuccess = '[Order Operations/API] Load Water Channel Success',
  LoadWaterChannelFailure = '[Order Operations/API] Load Water Channel Failure',
  CountWaterChannelSuccess = '[Order Operations/API] Count Water Channel Success',
  CountWaterChannelFailure = '[Order Operations/API] Count Water Channel Failure',
  FinalizeWaterChannelSuccess = '[Order Operations/API] Finalize Water Channel Success',
  FinalizeWaterChannelFailure = '[Order Operations/API] Finalize Water Channel Failure',
  LoadOrderOperationFetchMaterialSuccess = '[Order Operations/API] Load Order Operation Fetch Material Success',
  LoadOrderOperationFetchMaterialFailure = '[Order Operations/API] Load Order Operation Fetch Material Failure',
  CountOrderOperationFetchMaterialSuccess = '[Order Operations/API] Count Order Operation Fetch Material Success',
  CountOrderOperationFetchMaterialFailure = '[Order Operations/API] Count Order Operation Fetch Material Failure',
  FinalizeFetchMaterialSuccess = '[Order Operations/API] Finalize Fetch Material Success',
  FinalizeFetchMaterialFailure = '[Order Operations/API] Finalize Fetch Material Failure',
  LoadOrderOperationTrymaSuccess = '[Order Operations/API] Load Order Operation Tryma Success',
  LoadOrderOperationTrymaFailure = '[Order Operations/API] Load Order Operation Tryma Failure',
  CountOrderOperationTrymaSuccess = '[Order Operations/API] Count Order Operation Tryma Success',
  CountOrderOperationTrymaFailure = '[Order Operations/API] Count Order Operation Tryma Failure',
  FinalizeTrymaSuccess = '[Order Operations/API] Finalize Tryma Success',
  FinalizeTrymaFailure = '[Order Operations/API] Finalize Tryma Failure',
  PrintFileSuccess = '[Order Operations/API] Print File Success',
  FinalizeClusteredOrderOperationSuccess = '[Order Operations/API] Finalize Clustered Order Operation',
  FinalizeClusteredOrderOperationFailure = '[Order Operations/API] Finalize Clustered Order Operation Failure',
}

/**
 * Load Order Operation Files Actions
 */
export class LoadFileSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadFileSuccess;

  constructor(public payload: Array<OrderOperationFile>) {}
}

export class LoadFileFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadFileFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Files Actions
 */
export class CountFileSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountFileSuccess;

  constructor(public payload: number) {}
}

export class CountFileFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountFileFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Actions
 */
export class FinalizeSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeSuccess;

  constructor(public payload: Array<string>) {}
}

export class FinalizeFileFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeFileFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Milling Actions
 */
export class LoadMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadMillingSuccess;

  constructor(public payload: Array<OrderOperationMilling>) {}
}

export class LoadMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Milling Actions
 */
export class CountMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountMillingSuccess;

  constructor(public payload: number) {}
}

export class CountMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Milling Actions
 */
export class FinalizeMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeMillingSuccess;

  constructor(public payload: string) {}
}

export class FinalizeMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Aluminum Milling Actions
 */
export class LoadAluminumMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumMillingSuccess;

  constructor(public payload: Array<OrderOperationAluminumMilling>) {}
}

export class LoadAluminumMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Aluminum Milling Actions
 */
export class CountAluminumMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumMillingSuccess;

  constructor(public payload: number) {}
}

export class CountAluminumMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Aluminum Milling Actions
 */
export class FinalizeAluminumMillingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumMillingSuccess;

  constructor(public payload: string) {}
}

export class FinalizeAluminumMillingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumMillingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Aluminum Head Milling Actions
 */
export class LoadAluminumMillingCapsSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumMillingCapsSuccess;

  constructor(public payload: Array<OrderOperationAluminumMillingCaps>) {}
}

export class LoadAluminumMillingCapsFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumMillingCapsFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Aluminum Head Milling Actions
 */
export class CountAluminumMillingCapsSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumMillingCapsSuccess;

  constructor(public payload: number) {}
}

export class CountAluminumMillingCapsFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumMillingCapsFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Aluminum Head Milling Actions
 */
export class FinalizeAluminumMillingCapsSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumMillingCapsSuccess;

  constructor(public payload: string) {}
}

export class FinalizeAluminumMillingCapsFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumMillingCapsFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Aluminum Closing Plate Actions
 */
export class LoadAluminumClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumClosingPlateSuccess;

  constructor(public payload: Array<OrderOperationAluminumClosingPlate>) {}
}

export class LoadAluminumClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Aluminum Closing Plate Actions
 */
export class CountAluminumClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumClosingPlateSuccess;

  constructor(public payload: number) {}
}

export class CountAluminumClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Aluminum Closing Plate Actions
 */
export class FinalizeAluminumClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumClosingPlateSuccess;

  constructor(public payload: string) {}
}

export class FinalizeAluminumClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Stegherr Closing Plate Actions
 */
export class LoadStegherrClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadStegherrClosingPlateSuccess;

  constructor(public payload: Array<OrderOperationStegherrClosingPlate>) {}
}

export class LoadStegherrClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadStegherrClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Stegherr Closing Plate Actions
 */
export class CountStegherrClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountStegherrClosingPlateSuccess;

  constructor(public payload: number) {}
}

export class CountStegherrClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountStegherrClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Stegherr Closing Plate Actions
 */
export class FinalizeStegherrClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeStegherrClosingPlateSuccess;

  constructor(public payload: string) {}
}

export class FinalizeStegherrClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeStegherrClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Aluminum Headworking Actions
 */
export class LoadAluminumHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumHeadworkingSuccess;

  constructor(public payload: Array<OrderOperationAluminumHeadworking>) {}
}

export class LoadAluminumHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAluminumHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Aluminum Headworking Actions
 */
export class CountAluminumHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumHeadworkingSuccess;

  constructor(public payload: number) {}
}

export class CountAluminumHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountAluminumHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Aluminum Headworking Actions
 */
export class FinalizeAluminumHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumHeadworkingSuccess;

  constructor(public payload: string) {}
}

export class FinalizeAluminumHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAluminumHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Closing Plate Actions
 */
export class LoadClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadClosingPlateSuccess;

  constructor(public payload: Array<OrderOperationClosingPlate>) {}
}

export class LoadClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Closing Plate Actions
 */
export class CountClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountClosingPlateSuccess;

  constructor(public payload: number) {}
}

export class CountClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Closing Plate Actions
 */
export class FinalizeClosingPlateSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeClosingPlateSuccess;

  constructor(public payload: string) {}
}

export class FinalizeClosingPlateFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeClosingPlateFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Headworking Actions
 */
export class LoadHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadHeadworkingSuccess;

  constructor(public payload: Array<OrderOperationHeadworking>) {}
}

export class LoadHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Headworking Actions
 */
export class CountHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountHeadworkingSuccess;

  constructor(public payload: number) {}
}

export class CountHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Headworking Actions
 */
export class FinalizeHeadworkingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeHeadworkingSuccess;

  constructor(public payload: string) {}
}

export class FinalizeHeadworkingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeHeadworkingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Assembly Actions
 */
export class LoadAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAssemblySuccess;

  constructor(public payload: OrderOperationAssembly) {}
}

export class LoadAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Cylinder Assembly Actions
 */
export class LoadCylinderAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadCylinderAssemblySuccess;

  constructor(public payload: OrderOperationCylinderAssembly) {}
}

export class LoadCylinderAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadCylinderAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Assembly Overview Actions
 */
export class LoadAssemblyOverviewSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAssemblyOverviewSuccess;

  constructor(public payload: OverviewModel<OrderOperationAssemblyOverview, OrderOperationAssemblyFilter>) {}
}

export class LoadAssemblyOverviewFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadAssemblyOverviewFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Approve Assembly Actions
 */
export class LoadApproveAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadApproveAssemblySuccess;

  constructor(public payload: OrderOperationAssembly) {}
}

export class LoadApproveAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadApproveAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Approve Assembly Overview Actions
 */
export class LoadApproveAssemblyOverviewSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadApproveAssemblyOverviewSuccess;

  constructor(public payload: Array<OrderOperationAssemblyOverview>) {}
}

export class LoadApproveAssemblyOverviewFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadApproveAssemblyOverviewFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Approve Assembly Overview Actions
 */
export class CountApproveAssemblyOverviewSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountApproveAssemblyOverviewSuccess;

  constructor(public payload: number) {}
}

export class CountApproveAssemblyOverviewFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountApproveAssemblyOverviewFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Assembly Overview Actions
 */
export class FinalizeAssemblyOverviewSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAssemblyOverviewSuccess;

  constructor(public payload: string) {}
}

export class FinalizeAssemblyOverviewFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAssemblyOverviewFailure;

  constructor(public payload: Array<Error>) {}
}

 /*
 * Load Order Operation Slanted Cutting Actions
 */
export class LoadSlantedCuttingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadSlantedCuttingSuccess;

  constructor(public payload: Array<OrderOperationSlantedCutting>) {}
}

export class LoadSlantedCuttingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadSlantedCuttingFailure;

  constructor(public payload: Array<Error>) {}
}

 /*
 * Count Order Operation Slanted Cutting Actions
 */
export class CountSlantedCuttingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountSlantedCuttingSuccess;

  constructor(public payload: number) {}
}

export class CountSlantedCuttingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountSlantedCuttingFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Assembly Actions
 */
export class FinalizeAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAssemblySuccess;
}

export class FinalizeAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Print Order Operation Assembly Actions
 */
export class PrintAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.PrintAssemblySuccess;
  
  constructor(public payload: Array<string>) {}
}

export class PrintAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.PrintAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Approve Order Operation Assembly Actions
 */
export class ApproveAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.ApproveAssemblySuccess;

  constructor(public payload: string) {}
}

export class ApproveAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.ApproveAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Disapprove Order Operation Assembly Actions
 */
export class DisapproveAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.DisapproveAssemblySuccess;

  constructor(public payload: string) {}
}

export class DisapproveAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.DisapproveAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

 /*
 * Finalize Order Operation Slanted Cutting Actions
 */
export class FinalizeSlantedCuttingSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeSlantedCuttingSuccess;

  constructor(public payload: string) {}
}

export class FinalizeSlantedCuttingFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeSlantedCuttingFailure;

  constructor(public payload: Array<Error>) {}
}

 /*
 * Load Order Plinth Block Actions
 */
export class LoadOrderPlinthBlocksSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderPlinthBlocksSuccess;

  constructor(public payload: Array<OrderPlinthBlocksByStyleNumber>) {}
}

export class LoadOrderPlinthBlocksFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderPlinthBlocksFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Plinth Block Actions
 */
export class CountOrderPlinthBlocksSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderPlinthBlocksSuccess;

  constructor(public payload: number) {}
}

export class CountOrderPlinthBlocksFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderPlinthBlocksFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Export Order Operation
 */
export class ExportOrderOperationSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.ExportOrderOperationSuccess;
}

export class ExportOrderOperationFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.ExportOrderOperationFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Export Order Plinth Blocks
 */
export class ExportOrderPlinthBlocksSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.ExportOrderPlinthBlocksSuccess;
}

export class ExportOrderPlinthBlocksFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.ExportOrderPlinthBlocksFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation extra keys Actions
 */
export class LoadOrderOperationExtraKeysSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderOperationExtraKeysSuccess;

  constructor(public payload: Array<OrderOperationCylindersExtraKeys>) {}
}

export class LoadOrderOperationExtraKeysFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderOperationExtraKeysFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation extra keys Actions
 */
export class CountOrderOperationExtraKeysSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderOperationExtraKeysSuccess;

  constructor(public payload: number) {}
}

export class CountOrderOperationExtraKeysFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderOperationExtraKeysFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Extra Keys Actions
 */
export class FinalizeExtraKeysSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeExtraKeysSuccess;

  constructor(public payload: string) {}
}

export class FinalizeExtraKeysFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeExtraKeysFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Cylinder Assembly Actions
 */
export class FinalizeCylinderAssemblySuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeCylinderAssemblySuccess;

  constructor(public payload: string) {}
}

export class FinalizeCylinderAssemblyFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeCylinderAssemblyFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Water Channel Actions
 */
 export class LoadWaterChannelSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadWaterChannelSuccess;

  constructor(public payload: Array<OrderOperationWaterChannel>) {}
}

export class LoadWaterChannelFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadWaterChannelFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Water Channel Actions
 */
export class CountWaterChannelSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountWaterChannelSuccess;

  constructor(public payload: number) {}
}

export class CountWaterChannelFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountWaterChannelFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Water Channel Actions
 */
export class FinalizeWaterChannelSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeWaterChannelSuccess;

  constructor(public payload: string) {}
}

export class FinalizeWaterChannelFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeWaterChannelFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Fetch Material Actions
 */
export class FinalizeFetchMaterialSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeFetchMaterialSuccess;

  constructor(public payload: Array<string>) {}
}

export class FinalizeFetchMaterialFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeFetchMaterialFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Load Order Operation Tryma Actions
 */

export class LoadOrderOperationTrymaSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderOperationTrymaSuccess;

  constructor(public payload: Array<OrderOperationTrymaOverview>) {}
}

export class LoadOrderOperationTrymaFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.LoadOrderOperationTrymaFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Count Order Operation Tryma Actions
 */

export class CountOrderOperationTrymaSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderOperationTrymaSuccess;

  constructor(public payload: number) {}
}

export class CountOrderOperationTrymaFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.CountOrderOperationTrymaFailure;

  constructor(public payload: Array<Error>) {}
}

/**
 * Finalize Order Operation Tryma Actions
 */
export class FinalizeTrymaSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeTrymaSuccess;

  constructor(public payload: string) {}
}

export class FinalizeTrymaFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeTrymaSuccess;

  constructor(public payload: Array<Error>) {}
}

export class PrintFileSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.PrintFileSuccess;

  constructor(public payload: Array<string>) {}
}

export class FinalizeClusteredOrderOperationSuccess implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeClusteredOrderOperationSuccess;

  constructor(public payload: string) {}
}

export class FinalizeClusteredOrderOperationFailure implements Action {
  readonly type = OrderOperationsApiActionTypes.FinalizeClusteredOrderOperationFailure;

  constructor(public payload: Array<Error>) {}
}

export type OrderOperationsApiActionsUnion =
  | LoadFileSuccess
  | LoadFileFailure
  | CountFileSuccess
  | CountFileFailure
  | FinalizeSuccess
  | FinalizeFileFailure
  | LoadMillingSuccess
  | LoadMillingFailure
  | CountMillingSuccess
  | CountMillingFailure
  | LoadAluminumMillingSuccess
  | LoadAluminumMillingFailure
  | CountAluminumMillingSuccess
  | CountAluminumMillingFailure
  | FinalizeAluminumMillingSuccess
  | FinalizeAluminumMillingFailure
  | LoadAluminumMillingCapsSuccess
  | LoadAluminumMillingCapsFailure
  | CountAluminumMillingCapsSuccess
  | CountAluminumMillingCapsFailure
  | FinalizeAluminumMillingCapsSuccess
  | FinalizeAluminumMillingCapsFailure
  | LoadAluminumClosingPlateSuccess
  | LoadAluminumClosingPlateFailure
  | CountAluminumClosingPlateSuccess
  | CountAluminumClosingPlateFailure
  | FinalizeAluminumClosingPlateSuccess
  | FinalizeAluminumClosingPlateFailure
  | LoadStegherrClosingPlateSuccess
  | LoadStegherrClosingPlateFailure
  | CountStegherrClosingPlateSuccess
  | CountStegherrClosingPlateFailure
  | FinalizeStegherrClosingPlateSuccess
  | FinalizeStegherrClosingPlateFailure
  | LoadAluminumHeadworkingSuccess
  | LoadAluminumHeadworkingFailure
  | CountAluminumHeadworkingSuccess
  | CountAluminumHeadworkingFailure
  | FinalizeAluminumHeadworkingSuccess
  | FinalizeAluminumHeadworkingFailure
  | LoadClosingPlateSuccess
  | LoadClosingPlateFailure
  | CountClosingPlateSuccess
  | CountClosingPlateFailure
  | FinalizeClosingPlateSuccess
  | FinalizeClosingPlateFailure
  | LoadOrderPlinthBlocksSuccess
  | LoadOrderPlinthBlocksFailure
  | CountOrderPlinthBlocksSuccess
  | CountOrderPlinthBlocksFailure
  | FinalizeMillingSuccess
  | FinalizeMillingFailure
  | LoadHeadworkingSuccess
  | LoadHeadworkingFailure
  | CountHeadworkingSuccess
  | CountHeadworkingFailure
  | FinalizeHeadworkingSuccess
  | FinalizeHeadworkingFailure
  | LoadAssemblySuccess
  | LoadAssemblyFailure
  | LoadCylinderAssemblySuccess
  | LoadCylinderAssemblyFailure
  | LoadAssemblyOverviewSuccess
  | LoadAssemblyOverviewFailure
  | FinalizeAssemblyOverviewSuccess
  | FinalizeAssemblyOverviewFailure
  | FinalizeAssemblySuccess
  | FinalizeAssemblyFailure
  | LoadSlantedCuttingSuccess
  | LoadSlantedCuttingFailure
  | CountSlantedCuttingSuccess
  | CountSlantedCuttingFailure
  | FinalizeSlantedCuttingSuccess
  | FinalizeSlantedCuttingFailure
  | ExportOrderOperationSuccess
  | ExportOrderOperationFailure
  | ExportOrderPlinthBlocksSuccess
  | ExportOrderPlinthBlocksFailure
  | LoadApproveAssemblySuccess
  | LoadApproveAssemblyFailure
  | LoadApproveAssemblyOverviewSuccess
  | LoadApproveAssemblyOverviewFailure
  | CountApproveAssemblyOverviewSuccess
  | CountApproveAssemblyOverviewFailure
  | ApproveAssemblySuccess
  | ApproveAssemblyFailure
  | DisapproveAssemblySuccess
  | DisapproveAssemblyFailure
  | LoadOrderOperationExtraKeysSuccess
  | LoadOrderOperationExtraKeysFailure
  | CountOrderOperationExtraKeysSuccess
  | CountOrderOperationExtraKeysFailure
  | FinalizeExtraKeysSuccess
  | FinalizeExtraKeysFailure
  | LoadWaterChannelSuccess
  | LoadWaterChannelFailure
  | CountWaterChannelSuccess
  | CountWaterChannelFailure
  | FinalizeWaterChannelSuccess
  | FinalizeWaterChannelFailure
  | FinalizeCylinderAssemblySuccess
  | FinalizeCylinderAssemblyFailure
  | FinalizeFetchMaterialSuccess
  | FinalizeFetchMaterialFailure
  | LoadOrderOperationTrymaSuccess
  | LoadOrderOperationTrymaFailure
  | CountOrderOperationTrymaSuccess
  | CountOrderOperationTrymaFailure
  | FinalizeTrymaSuccess
  | FinalizeTrymaFailure
  | PrintFileSuccess
  | FinalizeClusteredOrderOperationSuccess
  | FinalizeClusteredOrderOperationFailure;

