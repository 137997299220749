import { AuthenticationApiActions, AuthenticationActions } from '@app/auth/actions';
import { LoginResponse } from '@app/auth/models/loginResponse';
import { User } from '@app/users/models/user';

export interface State {
  user: User | null;
  loginResponse: LoginResponse | null;
}

export const initialState: State = {
  user: null,
  loginResponse: null
};

export function reducer(
  state = initialState,
  action: AuthenticationApiActions.AuthenticationApiActionsUnion | AuthenticationActions.AuthenticationActionsUnion
): State {
  switch (action.type) {
    case AuthenticationApiActions.AuthenticationApiActionTypes.LoginSuccess: {
      return { ...state, loginResponse: action.payload.loginResponse };
    }
    case AuthenticationActions.AuthenticationActionTypes.Logout: {
      localStorage.clear();
      return initialState;
    }
    case AuthenticationApiActions.AuthenticationApiActionTypes.RetrieveUserSuccess: {
      return { ...state, user: action.payload.user };
    }
    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;

export const getLoginResponse = (state: State) => state.loginResponse;
