<mat-toolbar color="primary" class="hidden-print" [class.not-production-env]="showEnvironmentWarning()">
  <mat-toolbar-row>
    <img alt="Logo" class="logo" src="/assets/images/logo-venstertechniek.png" title="Venster Techniek" />

    <!-- Klanten -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasAnyClaims([['Customer.Delete'], ['UserManagement.Read']])"
      [matMenuTriggerFor]="Customers">
      Klanten
    </vt-nav-item>

    <mat-menu #Customers="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Customer.Delete'])" [routerLink]="['/customers']" mat-menu-item>
        Klanten
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['UserManagement.Update'])" [routerLink]="['/users']" mat-menu-item>
        Gebruikers
      </button>
    </mat-menu>

    <!-- Orders -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasClaims(['Order.Read'])" [routerLink]="['/orders']">
      Orders
    </vt-nav-item>

    <!-- Planning -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasClaims(['Planning.Read'])" [matMenuTriggerFor]="Planning">
      Planning
    </vt-nav-item>

    <mat-menu #Planning="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['ResidualLength.Read'])" [routerLink]="['/residuallengths']"
        mat-menu-item>
        Restlengtes
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Planning.Read'])" [routerLink]="['/planning']" mat-menu-item>
        Planning Orders
      </button>
    </mat-menu>

    <!-- Management -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasAnyClaims([['Reports.Read'], ['Batch.Read']])"
      [matMenuTriggerFor]="Management">
      Management
    </vt-nav-item>

    <mat-menu #Management="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Reports.Read'])" [matMenuTriggerFor]="Reports" mat-menu-item>
        Rapportage
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Batch.Read'])" [matMenuTriggerFor]="ManagementSysteem"
        mat-menu-item>
        Systeem
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Afas.Read'])" [matMenuTriggerFor]="AFAS" mat-menu-item>
        AFAS
      </button>
    </mat-menu>

    <mat-menu #AFAS="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Afas.Read'])" [routerLink]="['/afas/list']" mat-menu-item>
        Exporteren
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Afas.Read'])" [routerLink]="['/afas/exports']" mat-menu-item>
        Overzicht Exports
      </button>
    </mat-menu>

    <mat-menu #Reports="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Reports.Read'])" [routerLink]="['/reports/batch']"
        mat-menu-item>
        Productie
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Reports.Read'])" [routerLink]="['/reports/sillsPerWeek']"
        mat-menu-item>
        Orders
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Connector.Read'])" [routerLink]="['/reports/connectors']"
        mat-menu-item>
        Connectoren
      </button>
    </mat-menu>

    <mat-menu #ManagementSysteem="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Batch.Read'])" [routerLink]="['/batches']" mat-menu-item>
        Batches terugzetten
      </button>
    </mat-menu>

    <!-- Productie -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])" [matMenuTriggerFor]="Productie">
      Productie
    </vt-nav-item>

    <mat-menu #Productie="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])"
        [routerLink]="['/orderoperations/plinthblocks']" mat-menu-item>Neuten overzicht</button>
        
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])"
      [routerLink]="['/orderoperations/clustered-order-operations']" mat-menu-item>Neutopdrachten</button>

      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderCalculations.Read'])" [routerLink]="['/ordercalculations']"
        mat-menu-item>Ordergegevens</button>

      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button [matMenuTriggerFor]="cylinders" mat-menu-item>
          Cilinders
        </button>
        <button [matMenuTriggerFor]="ProductieDorpels" mat-menu-item>
          Glasvezel profiel
        </button>
        <button [matMenuTriggerFor]="ProductieGlaslijst" mat-menu-item>
          Glaslijst profiel
        </button>
        <button [matMenuTriggerFor]="ProductieAluminium" mat-menu-item>
          Aluminium profiel
        </button>
        <button [matMenuTriggerFor]="ProductieKunststof" mat-menu-item>
          Kunststof profiel
        </button>
        <button [matMenuTriggerFor]="ProductiePVC" mat-menu-item>
          PVC
        </button>
        <button [matMenuTriggerFor]="ProductieRVS" mat-menu-item>
          RVS
        </button>
        <button [routerLink]="['/orderoperations/assemblyoverview']" mat-menu-item>
          Afmontage
        </button>
        <button [routerLink]="['/orderoperations/approveassemblyoverview']" mat-menu-item>
          Goedkeuren afmontage
        </button>
        <button [routerLink]="['/orderheaderoperations/fetchmaterial']" mat-menu-item>
          Materialen verzamelen
        </button>
      </div>
    </mat-menu>

    <mat-menu #ProductieDorpels="matMenu">
      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button [routerLink]="['/orderoperations/file/' + operationPriorityTypes.SillCutting]" mat-menu-item>
          Afkorten
        </button>
        <button [routerLink]="['/orderoperations/milling']" mat-menu-item>
          Frezen
        </button>
        <button [routerLink]="['/orderoperations/closingplate']" mat-menu-item>
          Sluitplaat
        </button>
        <button [matMenuTriggerFor]="TrymaMachines" mat-menu-item>
          Tryma
        </button>
        <button [routerLink]="['/orderoperations/headworking']" mat-menu-item>
          Kopbewerking
        </button>
      </div>
    </mat-menu>

    <mat-menu #cylinders="matMenu">
      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button [routerLink]="['/orderoperations/cylinderKeys']" mat-menu-item>
          Sleutels
        </button>
        <button [routerLink]="['/orderoperations/cylinderassemblyoverview']" mat-menu-item>
          Cilinders
        </button>
      </div>
    </mat-menu>

    <mat-menu #TrymaMachines="matMenu">
      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button *ngFor="let machine of trymaMachines" [routerLink]="['/orderoperations/tryma/' + machine]" mat-menu-item>
          {{ trymaMachineNames[machine] }}
        </button>
      </div>
    </mat-menu>

    <mat-menu #ProductieGlaslijst="matMenu">
      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button [routerLink]="['/orderoperations/file/' + operationPriorityTypes.GlassCutting]" mat-menu-item>
          Afkorten
        </button>
        <button [routerLink]="['/orderoperations/slantedcutting']" mat-menu-item>
          Schuinzagen
        </button>
        <button [routerLink]="['/orderoperations/file/' + operationPriorityTypes.Stegherr]" mat-menu-item>
          Stegherr
        </button>
        <button [routerLink]="['/orderoperations/stegherrclosingplate']" mat-menu-item>
          Stegherr sluitplaat
        </button>
      </div>
    </mat-menu>

    <mat-menu #ProductieAluminium="matMenu">
      <div *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])">
        <button [routerLink]="['/orderoperations/file/' + operationPriorityTypes.AluminumCutting]" mat-menu-item>
          Afkorten
        </button>
        <button [routerLink]="['/orderoperations/aluminummilling']" mat-menu-item>
          Frezen
        </button>
        <button [routerLink]="['/orderoperations/aluminummillingcaps']" mat-menu-item>
          Kappen frezen
        </button>
        <button [routerLink]="['/orderoperations/waterchannel']" mat-menu-item>
          Watersleuven
        </button>
        <button [routerLink]="['/orderoperations/aluminumclosingplate']" mat-menu-item>
          Sluitplaat
        </button>
        <button [routerLink]="['/orderoperations/aluminumheadworking']" mat-menu-item>
          Kopbewerking
        </button>
      </div>
    </mat-menu>

    <mat-menu #ProductieKunststof="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])"
        [routerLink]="['/orderoperations/file/' + operationPriorityTypes.PlasticCutting]" mat-menu-item>
        Afkorten
      </button>
    </mat-menu>

    <mat-menu #ProductiePVC="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])"
        [routerLink]="['/orderoperations/file/' + operationPriorityTypes.PVCCutting]" mat-menu-item>
        Afkorten
      </button>
    </mat-menu>

    <mat-menu #ProductieRVS="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OrderOperation.Read'])"
        [routerLink]="['/orderoperations/file/' + operationPriorityTypes.RVSCutting]" mat-menu-item>
        Afkorten
      </button>
    </mat-menu>

    <!-- Systeem -->
    <vt-nav-item *ngIf="
        (loggedIn$ | async) &&
        hasAnyClaims([
          ['ProfileType.Update'],
          ['Profile.Update'],
          ['Connector.Update'],
          ['PlinthBlock.Update'],
          ['Rabbet.Update'],
          ['RabbetDetail.Update'],
          ['DrillPattern.Update'],
          ['Product.Update'],
          ['SectionType.Update'],
          ['ProductType.Update'],
          ['XmlDetail.Update']
        ])
      " [matMenuTriggerFor]="Systeem">
      Systeem
    </vt-nav-item>

    <mat-menu #Systeem="matMenu">
      <button *ngIf="
          (loggedIn$ | async) &&
          hasAnyClaims([['Connector.Update'], ['PlinthBlock.Update'], ['Rabbet.Update'], ['DrillPattern.Update']])
        " [matMenuTriggerFor]="Stambestanden" mat-menu-item>
        Stambestanden
      </button>
      <button *ngIf="
          (loggedIn$ | async) &&
          hasAnyClaims([
            ['ProfileType.Update'],
            ['Profile.Update'],
            ['Product.Update'],
            ['SectionType.Update'],
            ['ProductType.Read']
          ])
        " [matMenuTriggerFor]="Producten" mat-menu-item>
        Producten
      </button>
      <button *ngIf="
          (loggedIn$ | async) &&
          hasAnyClaims([['XmlDetail.Update']])
        " [matMenuTriggerFor]="Configuratie" mat-menu-item>
        Configuratie
      </button>
    </mat-menu>

    <mat-menu #Stambestanden="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Connector.Update'])" [routerLink]="['/connectors']"
        mat-menu-item>
        Connectoren
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['PlinthBlock.Update'])" [routerLink]="['/plinthblocks']"
        mat-menu-item>
        Neuten
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['StockPlinthBlock.Update'])"
        [routerLink]="['/stockplinthblocks']" mat-menu-item>
        Voorraadneuten
      </button>
      <ng-container *hasFeature="'EEFD'">
        <button *ngIf="(loggedIn$ | async) && hasClaims(['Rabbet.Update'])" [routerLink]="['/rabbets']" mat-menu-item>
          Sponningen
        </button>
        <button *ngIf="(loggedIn$ | async) && hasClaims(['RabbetDetail.Update'])" [routerLink]="['/rabbetdetails']"
          mat-menu-item>
          Sponning details
        </button>
        <button *ngIf="(loggedIn$ | async) && hasClaims(['DrillPattern.Update'])" [routerLink]="['/drillpatterns']"
          mat-menu-item>
          Boorpatronen
        </button>
      </ng-container>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Article.Update'])" [routerLink]="['/articles']" mat-menu-item>
        Artikelen
      </button>
    </mat-menu>

    <mat-menu #Producten="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['ProfileType.Update'])" [routerLink]="['/profiletypes']"
        mat-menu-item>
        Profieltype
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Profile.Update'])" [routerLink]="['/profiles']" mat-menu-item>
        Profielen
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Product.Update'])" [routerLink]="['/products']" mat-menu-item>
        Producten
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['ProductType.Update'])" [routerLink]="['/producttypes']"
        mat-menu-item>
        Producttype
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['SectionType.Update'])" [routerLink]="['/sectiontypes']"
        mat-menu-item>
        Vaktypes
      </button>
    </mat-menu>

    <mat-menu #Configuratie="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['XmlDetail.Update'])" [routerLink]="['/xmlcouplings']"
        mat-menu-item>
        XML koppelingen
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['OperationMachines.Read'])" [routerLink]="['/operationmachines']" mat-menu-item>Machines</button>
    </mat-menu>

    <span class="spacer"></span>

    <ng-container *ngIf="showEnvironmentWarning()">
      {{environmentWarning()}}
    </ng-container>

    <span class="spacer"></span>

    <button *ngIf="(loggedIn$ | async)" mat-button [matMenuTriggerFor]="user">
      <i class="material-icons black" aria-hidden="true">account_circle</i> {{ userName }}
    </button>
    <mat-menu #user="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['UserProfile.Read'])" [routerLink]="['/userprofile']"
        mat-menu-item>
        Mijn profiel
      </button>
      <button *ngIf="(loggedIn$ | async)" (click)="logout()" mat-menu-item>Uitloggen</button>
    </mat-menu>
    <button *ngIf="(loggedIn$ | async)" mat-button class="help-button" (click)="showHelp()">
      <i class="material-icons grey" aria-hidden="true">help</i>
    </button>
  </mat-toolbar-row>
</mat-toolbar>