import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AuthenticationApiActions } from '@app/auth/actions';
import { TokenService } from '@app/auth/services/token.service';
import * as fromAuth from '@app/auth/reducers';

import { AuthService } from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  /**
   * Initializes a new instance of the AuthGuard class.
   * @param {Store<fromAuth.store>} store The redux store.
   * @param {AuthService} authenticationService The authentication service.
   * @param {TokenService} tokenService The token service.
   */
  constructor(
    private readonly store: Store<fromAuth.State>,
    private readonly authenticationService: AuthService,
    private readonly tokenService: TokenService
  ) {}

  /**
   * Whether or not the current user is available in the api.
   */
  hasUserInApi(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.retrieveCurrentUser().pipe(
      map(user => new AuthenticationApiActions.RetrieveUserSuccess({ user })),
      tap((action: AuthenticationApiActions.RetrieveUserSuccess) => this.store.dispatch(action)),
      map(user => !!user.payload),
      catchError(() => {
        this.tokenService.removeToken();
        this.store.dispatch(new AuthenticationApiActions.LoginRedirect(state.url));
        return of(false);
      })
    );
  }

  /**
   * Wether or not the current user is authenticated.
   */
  isAuthenticated(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getLoggedIn),
      take(1)
    );
  }

  /**
   * Whether or not the current user is available in local storage.
   */
  hasUserInLocalStorage(): boolean {
    const currentUser = this.tokenService.getTokenObject();
    return !!currentUser && !!currentUser.accessToken && !!currentUser.refreshToken && !!currentUser.claims;
  }

  /**
   * Whether or not the current route can be activated.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated().pipe(isAuthenticated => {
      if (isAuthenticated && this.hasUserInLocalStorage()) {
        return this.hasUserInApi(next, state);
      }

      this.store.dispatch(new AuthenticationApiActions.LoginRedirect(state.url));
      return of(false);
    });
  }
}
