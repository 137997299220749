/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i2 from "@angular/material/card";
import * as i3 from "./header-actions.component";
var styles_HeaderActionsComponent = [];
var RenderType_HeaderActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderActionsComponent, data: {} });
export { RenderType_HeaderActionsComponent as RenderType_HeaderActionsComponent };
export function View_HeaderActionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "mat-card", [["class", "block-header mat-card"]], null, null, null, i1.View_MatCard_0, i1.RenderType_MatCard)), i0.ɵdid(1, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 8, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-card-title", [["class", "block-header__subtitle mat-card-title"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "mat-card-title", [["class", "block-header__subtitle mat-card-title"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i0.ɵted(10, null, [" ", " "])), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "div", [["class", "block-header__actions"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainTitle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.subTitle; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.secondSubTitle; _ck(_v, 10, 0, currVal_2); }); }
export function View_HeaderActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vt-header-actions", [], null, null, null, View_HeaderActionsComponent_0, RenderType_HeaderActionsComponent)), i0.ɵdid(1, 49152, null, 0, i3.HeaderActionsComponent, [], null, null)], null, null); }
var HeaderActionsComponentNgFactory = i0.ɵccf("vt-header-actions", i3.HeaderActionsComponent, View_HeaderActionsComponent_Host_0, { mainTitle: "mainTitle", subTitle: "subTitle", secondSubTitle: "secondSubTitle" }, {}, ["*"]);
export { HeaderActionsComponentNgFactory as HeaderActionsComponentNgFactory };
