import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { equalControlsValidator } from '@app/core/validators/equalControls.validator';
var RegisterUserFormComponent = /** @class */ (function () {
    function RegisterUserFormComponent() {
        this.submitted = new EventEmitter();
    }
    Object.defineProperty(RegisterUserFormComponent.prototype, "pending", {
        set: function (isPending) {
            if (isPending) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    RegisterUserFormComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    RegisterUserFormComponent.prototype.createForm = function () {
        this.form = new FormGroup({
            Customer: new FormGroup({
                Name: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                Street: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                StreetNumber: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                StreetNumberAddition: new FormControl(''),
                PostalCode: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                City: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                IsApproved: new FormControl(false, { validators: [Validators.required], updateOn: 'blur' }),
            }),
            User: new FormGroup({
                FirstName: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                LastNamePrefix: new FormControl(''),
                LastName: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                PhoneNumber: new FormControl(''),
                Email: new FormControl('', { validators: [Validators.email, Validators.required], updateOn: 'blur' }),
                EmailRepeat: new FormControl('', { validators: [Validators.email, Validators.required], updateOn: 'blur' }),
                Password: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
                PasswordRepeat: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
            }, [this.validatePasswords, this.validateEmails]),
        });
    };
    /**
     * Validator to check if password and password repeat are provided and equal.
     * @param {AbstractControl} form The form.
     * @returns {ValidationErrors | null} Error object.
     * @memberof RegisterUserFormComponent
     */
    RegisterUserFormComponent.prototype.validatePasswords = function (form) {
        return equalControlsValidator(form.get('Password'), form.get('PasswordRepeat'));
    };
    /**
     * Validator to check if email and email repeat are provided, equal and valid emails.
     * @param {AbstractControl} form The form.
     * @returns {ValidationErrors | null} Error object.
     * @memberof RegisterUserFormComponent
     */
    RegisterUserFormComponent.prototype.validateEmails = function (form) {
        return equalControlsValidator(form.get('Email'), form.get('EmailRepeat'));
    };
    RegisterUserFormComponent.prototype.submit = function () {
        if (this.form.valid) {
            this.submitted.emit(this.form.value);
        }
    };
    return RegisterUserFormComponent;
}());
export { RegisterUserFormComponent };
